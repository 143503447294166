import React, { useState } from 'react';
import { graphql, Link } from 'gatsby';
import format from 'date-fns/format';
import isEmpty from 'lodash/isEmpty';

import { SEO, Headline, SitewideHeader, SitewideFooter } from '../components';
import HeadRssLink from '../components/article/HeadRssLink';
import { GatsbyImage, getImage, getSrc } from 'gatsby-plugin-image';
import { SubscribeToNewsletterSuccessModal } from '../components/CallToAction/SubscribeToNewsletter';

import Tags from '../components/Tags';
import mapWordpressBlogPostToMarkdownRemarkBlogPost from '../mapWordpressBlogPostToMarkdownRemarkBlogPost';
import { FooterCTA } from '../components/landing';

const BlogPostTemplate = ({ data }) => {
  const { node: post }: any = mapWordpressBlogPostToMarkdownRemarkBlogPost({
    node: data.markdownRemark,
  });
  // const post = data.markdownRemark.nodes[0]
  // console.log(post)
  const { title: siteTitle } = data.site.siteMetadata;

  const [email, setEmail] = useState('');
  const [modalOpen, setModalOpen] = useState(false);

  const handleCloseModal = () => {
    setModalOpen(false);
    setEmail('');
  };

  const coverImage = getImage(post.coverImage);
  const { author, title, date, tags, lastValidated } = post.frontmatter;

  const FORMAT_TOKEN = 'MMMM do, yyyy';
  const dateTimestamp = Date.parse(date);

  let formattedDate = format(dateTimestamp, FORMAT_TOKEN);
  const lastValidatedTimestamp = Date.parse(lastValidated);
  if (lastValidated && lastValidatedTimestamp) {
    const formattedLastvalidated = format(lastValidatedTimestamp, FORMAT_TOKEN);
    formattedDate = `${formattedDate} — last validated on ${formattedLastvalidated}`;
  }

  return (
    <>
      <SEO
        title={`${post.frontmatter.title} | ${siteTitle}`}
        description={post.frontmatter.description || post.excerpt}
        headerImage={getSrc(post.coverImage)}
      />

      <HeadRssLink />

      <SitewideHeader borderBottom={false} />

      <SubscribeToNewsletterSuccessModal
        modalOpen={modalOpen}
        handleCloseModal={handleCloseModal}
        siteMetadata={data.site.siteMetadata}
        email={email}
      />

      <article className="relative">
        <header className="bg-white mx-auto max-w-7xl mb-5 px-4 py-10 xl:rounded-lg lg:flex lg:px-0 lg:mb-10 items-center">
          <div className="lg:w-1/2 px-4 lg:px-10">
            <Link
              to="/blog"
              className="block uppercase mb-8 text-xl font-highlight text-orange-600 font-bold"
            >
              Evalubox’s Blog
            </Link>
            <Headline size="medium" className="mb-10">
              {title}
            </Headline>
            <strong>{author && author.name && <>By {author.name}</>}</strong>
            {` • `}
            {formattedDate}
            {!isEmpty(tags) && (
              <div className="mt-4">
                <Tags tags={tags} />
              </div>
            )}
          </div>
          <div className="lg:w-1/2 mt-5 lg:mt-0">
            {coverImage && (
              <GatsbyImage
                image={coverImage}
                alt={post.coverImage.title}
                className="rounded-lg lg:rounded-tr-none lg:rounded-br-none"
              />
            )}
          </div>
        </header>

        <section
          className="prose-xl prose-primary px-4 max-w-none max-w-lg mx-auto lg:max-w-3xl mb-24"
          dangerouslySetInnerHTML={{ __html: post.html }}
        />
      </article>

      {/* <div className="relative max-w-lg mx-auto lg:max-w-xl mb-10">
        <SubscribeToNewsletterCTA setModalOpen={setModalOpen} email={email} setEmail={setEmail} />
      </div> */}
      <FooterCTA />

      <SitewideFooter />
    </>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        # social {
        #   twitter
        # }
      }
    }
    markdownRemark: wpPost(slug: { eq: $slug }) {
      slug
      excerpt
      date
      author {
        node {
          name
          avatar {
            url
          }
        }
      }
      content
      tags {
        nodes {
          name
        }
      }
      title
      featuredImage {
        node {
          gatsbyImage(height: 500, width: 500)
          title
        }
      }
      # fields {
      #     readingTime {
      #         text
      #         minutes
      #         time
      #         words
      #     }
      # }
    }

    # markdownRemark: contentfulBlogPost(slug: { eq: $slug }) {
    #   title
    #   date
    #   author {
    #     name
    #   }
    #   slug
    #   tags
    #   title
    #   lastValidated
    #   body {
    #     childMarkdownRemark {
    #       html
    #     }
    #   }
    #   coverImage {
    #     gatsbyImageData(height: 500)
    #     title
    #   }
    # }
  }
`;
