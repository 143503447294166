import React, { useState } from 'react';
import { graphql } from 'gatsby';

import { Page, SEO, Headline, Input, Lead, TextLink as Link } from '../../../components';
import { TemplateListItem } from '../../../components/SmartTemplates';
import ToolsListItem from '../../../components/LanguageProficiency/ListItem';

const ToolsResources = ({ data }) => {
  const {
    site: {
      siteMetadata: { title },
    },
  } = data;

  const [query, setQuery] = useState('');
  const tools = {
    edges: [
      {
        node: {
          fields: { slug: '/resources/tools/chronological-age-calculator' },
          frontmatter: {
            logoImage: 'age-calculator',
            humanName: 'Chronological Age Calculator',
            attribution: null,
            heading: 'Chronological Age Calculator',
          },
        },
      },
      {
        node: {
          fields: { slug: '/resources/tools/language-use-exposure-calculator' },
          frontmatter: {
            logoImage: 'language-use',
            humanName: 'Language Exposure & Use Calculator',
            attribution: null,
            heading: 'Language Exposure & Use Calculator',
          },
        },
      },
    ],
  };
  let filteredPlugins = [];
  if (query === '') {
    filteredPlugins = tools.edges;
  } else {
    filteredPlugins = tools.edges.filter(({ node }) => {
      return node.frontmatter.humanName.toLowerCase().includes(query.toLowerCase());
    });
  }

  return (
    <>
      <Page titleDivide={true}>
        <div className="lg:flex justify-between items-center">
          <div className="lg:mr-8">
            <div className="mb-4">
              <Headline>Tools</Headline>
            </div>
            <div className="mb-10">
              <Lead>
                Descriptions, guides and usage instructions for popular Speech Therapy Evaluation
                Tools.
              </Lead>
              {
                <Lead>
                  Using Evalubox? Login{' '}
                  <Link to="http://app.evalubox.com/evaluations" color="primary">
                    here
                  </Link>
                  .{' '}
                </Lead>
              }
            </div>
          </div>

          <div className="mb-2">
            <form className="lg:w-96">
              <Input
                type="text"
                onChange={setQuery}
                value={query}
                aria-label="Search"
                placeholder="Search"
                fullWidth
              />
            </form>
          </div>
        </div>

        <div className="grid gap-16 pt-12 lg:grid-cols-3 lg:gap-x-5 lg:gap-y-12">
          {filteredPlugins.map(({ node: { fields, frontmatter } }) => (
            <ToolsListItem frontmatter={frontmatter} fields={fields} key={fields.slug} />
          ))}
        </div>
      </Page>
    </>
  );
};

export default ToolsResources;

export const Head = () => (
  <>
    <SEO
      title={`Speech Evaluation Report Tools Directory - All Tools`}
      description="A comprehensive list of speech therapy report tools that you can use to automate and streamline report writing. With screenshots, video instructions and usage guides."
    />
  </>
);

export const pageQuery = graphql`
  {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
