import React from 'react';
import { Link } from '../components';
import { EvaluboxIcon, EvaluboxBrandLogo } from '../components/icons';

const Logo = ({ to = '/' }) => (
  <Link to={to}>
    <span className="sr-only">Evalubox</span>
    <span className="md:block hidden">
      <EvaluboxBrandLogo />
    </span>
    <span className="md:hidden">
      <EvaluboxIcon />
    </span>
  </Link>
);

export default Logo;
