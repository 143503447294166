import React from 'react';
import EmailReportCTA from './EmailReportCTA';

const CombinedParentTeacherSummary = ({ parentResults, teacherResults, sampleReportUrl = '' }) => {
  // Calculate Teacher Input: Hours in English / Hours in home language
  // Teacher Hours in English
  const teacherHoursInEnglish = teacherResults.averageInputOutputEnglish * 40;
  // Teacher Hours in Home Language
  const teacherHoursInHomeLanguage = teacherResults.averageInputOutputHomeLanguage * 40;
  // Teacher Hours in English
  const parentHoursInEnglish = parentResults.averageInputOutputEnglish * 62;
  // Teacher Hours in Home Language
  const parentHoursInHomeLanguage = parentResults.averageInputOutputHomeLanguage * 62;
  // Now add total hours in English
  const totalHoursInEnglish = parentHoursInEnglish + teacherHoursInEnglish;
  // Now add total hours in home langauge
  const totalHoursInHomeLanguage = teacherHoursInHomeLanguage + parentHoursInHomeLanguage;
  // Calculate the total input/output in english and divide by 112 waking hours
  // const averageInputOutputEnglish = totalHoursInEnglish / 112

  // Calculate the total input/output in english and divide by 112 waking hours
  // const averageInputOutputInHomeLanguage = totalHoursInHomeLanguage / 112

  // console.log("averageInputOutputEnglish", averageInputOutputEnglish)
  // console.log("averageInputOutputInHomeLanguage", averageInputOutputInHomeLanguage)
  // Calculating averages
  const averageInputOutputEnglish = (totalHoursInEnglish / 102).toFixed(0);
  const averageInputOutputHomeLanguage = (100 - parseFloat(averageInputOutputEnglish)).toFixed(0);
  const results = {
    parentResults,
    teacherResults,
    averageInputOutputEnglish: averageInputOutputEnglish,
    averageInputOutputHomeLanguage: averageInputOutputHomeLanguage,
  };

  return (
    <>
      {/* Summary table */}
      <div className="mt-10">
        <h2 className="text-xl font-semibold mb-2">Summary</h2>
        <table className="w-full mb-4 border border-collapse border-gray-800">
          <caption className="caption-top ">
            Taking all reports of language exposure and use into account, current overall exposure
            and use of the home language is {results.averageInputOutputHomeLanguage}% and of English
            is {results.averageInputOutputEnglish}%.
          </caption>

          <thead>
            <tr className="bg-gray-200">
              <th className="border border-gray-800 px-4 py-2">Category</th>
              <th className="border border-gray-800 px-4 py-2">Average Percentage</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border border-gray-800 px-4 py-2">Overall Input/Output in English</td>
              <td className="border border-gray-800 px-4 py-2">
                {results.averageInputOutputEnglish}%
              </td>
            </tr>
            <tr>
              <td className="border border-gray-800 px-4 py-2">
                Overall Input/Output in Home Language
              </td>
              <td className="border border-gray-800 px-4 py-2">
                {results.averageInputOutputHomeLanguage}%
              </td>
            </tr>
            <tr>
              <td className="border border-gray-800 px-4 py-2" colSpan={2}>
                Taking all reports of language exposure and use into account, current overall
                exposure and use of the home language is {results.averageInputOutputHomeLanguage}%
                and of English is {results.averageInputOutputEnglish}%.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {/* Capture Email CTA */}
      <EmailReportCTA
        results={results}
        selectedOption={'teacherParentInput'}
        sampleUrl={sampleReportUrl}
      />
    </>
  );
};

export default CombinedParentTeacherSummary;
