import React from 'react';
import { SEO, SitewideFooter, SitewideHeader } from '../components';
import { FAQs } from '../components/landing';

const Faqs = () => (
  <>
    <SitewideHeader />
    <FAQs />
    <SitewideFooter />
  </>
);

export default Faqs;

export const Head = () => (
  <>
    <SEO
      title={`FAQs`}
      description="Frequently asked questions about how Evalubox will save you report writing time."
    />
  </>
);
