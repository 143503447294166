import React, { useState } from 'react';
import { graphql } from 'gatsby';

import { Page, SEO, Headline, Input, Lead, TextLink as Link } from '../../components';
import { TemplateListItem } from '../../components/SmartTemplates';

const TemplateResources = ({ data }) => {
  const {
    templates,
    site: {
      siteMetadata: { title },
    },
  } = data;

  const [query, setQuery] = useState('');

  let filteredPlugins = [];
  if (query === '') {
    filteredPlugins = templates.edges;
  } else {
    filteredPlugins = templates.edges.filter(({ node }) => {
      return node.frontmatter.humanName.toLowerCase().includes(query.toLowerCase());
    });
  }

  return (
    <>
      <Page titleDivide={true}>
        <div className="lg:flex justify-between items-center">
          <div className="lg:mr-8">
            <div className="mb-4">
              <Headline>Speech language report template library</Headline>
            </div>
            <div className="mb-10">
              <Lead>
                Descriptions, guides and usage instructions for popular Speech Therapy Report
                Templates.
              </Lead>
              {
                <Lead>
                  Using Evalubox? Login to use{' '}
                  <Link to="http://app.evalubox.com/evaluations" color="primary">
                    smart templates
                  </Link>{' '}
                  instead.
                </Lead>
              }
            </div>
            <div>
              <div className="flex justify-start">
                <h3 className="font-bold text-xl text-blueroadie py-4">Bookmark this page!</h3>
                {/* <button className='mx-2'>Add to favorites</button> */}
              </div>
              <p className="prose prose-primary ">
                We are constantly updated this library and adding new tests. Don’t forget to
                bookmark this page and email us at{' '}
                <Link
                  color="primary"
                  to={`mailto:admin@evalubox.com?subject=Requesting new template`}
                >
                  admin@evalubox.com
                </Link>{' '}
                if there is a template paragraph that you want that you don’t see here
              </p>
            </div>
          </div>
        </div>

        <div className="flex justify-end pt-2 mb-2">
          <form className="lg:w-96 align-bottom">
            <Input
              type="text"
              onChange={setQuery}
              value={query}
              aria-label="Search"
              placeholder="Search"
              fullWidth
            />
          </form>
        </div>
        <div className="grid gap-16 pt-12 lg:grid-cols-3 lg:gap-x-5 lg:gap-y-12">
          {filteredPlugins.map(({ node: { fields, frontmatter } }) => (
            <TemplateListItem frontmatter={frontmatter} fields={fields} key={fields.slug} />
          ))}
        </div>
      </Page>
    </>
  );
};

export default TemplateResources;

export const Head = () => (
  <>
    <SEO
      title={`Speech Language Evaluation Report Template Library - All Templates`}
      description="A comprehensive list of speech therapy report templates that you can use to automate and streamline report writing. With screenshots, installation instructions and usage guides."
    />
  </>
);

export const pageQuery = graphql`
  {
    site {
      siteMetadata {
        title
      }
    }
    templates: allMarkdownRemark(
      sort: { frontmatter: { humanName: ASC } }
      filter: {
        fileAbsolutePath: { regex: "/.+/content/resources/speech-therapy-report-templates/.+/" }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            humanName
            heading
            logoImage {
              childImageSharp {
                gatsbyImageData(layout: FIXED, width: 140)
              }
            }
            attribution {
              text
              href
            }
          }
        }
      }
    }
  }
`;
