import React from 'react';
import { Lead, Headline, Link, Chip } from '../';

import Logo from './Logo';
import Attribution from './Attribution';

const EvaluboxDocsChip = ({ availableOnEvalubox, evaluboxDocsPath }: any) => {
  if (!availableOnEvalubox) return null;

  const chip = <Chip label="Available on Evalubox" color="green" />;
  if (!availableOnEvalubox) return chip;
  return (
    <Link to={`/docs/integrations${evaluboxDocsPath}`} className="inline-block">
      {chip}
    </Link>
  );
};

const Header = ({
  template: {
    frontmatter: {
      humanName,
      logoImage,
      heading,
      lead,
      attribution,
      // availableOnEvalubox,
      // evaluboxDocsPath,
    },
  },
}: any) => (
  <>
    <div className="mx-auto max-w-7xl px-4 py-10">
      <Link to="/resources/speech-therapy-report-templates/" className="font-bold text-blueroadie">
        <span className="text-orange-500">←</span> Speech language report template library
      </Link>
    </div>
    <header className="bg-white mx-auto max-w-7xl px-4 py-5 text-center lg:text-left xl:rounded-lg lg:flex lg:px-0 lg:mb-10 items-center">
      <div className="lg:w-1/4 mt-5 lg:mt-0">
        <Logo sharpImage={logoImage.childImageSharp} alt={`${humanName} logo`} />
      </div>
      <div>
        <Headline className="mb-4">{heading}</Headline>
        <Lead className="mb-4 text-blueroadie">{lead}</Lead>
        <Attribution attribution={attribution} className="mb-4" />

        {/* <EvaluboxDocsChip availableOnEvalubox={availableOnEvalubox} evaluboxDocsPath={evaluboxDocsPath} /> */}
      </div>
    </header>
  </>
);

export default Header;
