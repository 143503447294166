// This env var is set in the gatsby-config.js file.
export const currentlyExecutingGitBranch = () => {
  return process.env.GATSBY_GIT_BRANCH_NAME || 'NOT_SUPPLIED';
};

export const isPreviewSite = () => {
  if (process.env.GATSBY_NETLIFY_SITE_NAME === 'evalubox-preview') return true;
  return false;
};

export const recaptchaEnabled = () => {
  if (process.env.GATSBY_RECAPTCHAS_ENABLED === 'false') return false;
  return true;
};

export const chargebeeSubdomain = () => {
  return process.env.GATSBY_CHARGEBEE_SUBDOMAIN || 'stai';
};

export const chargebeeTestSubdomain = () => {
  return process.env.GATSBY_CHARGEBEE_TEST_SUBDOMAIN || 'stai-test';
};

export const chargebeePublishing = () => {
  return process.env.GATSBY_CHARGEBEE_PUBLISHING_KEY || 'live_roPZaboFwYnJU4uCmBg4dQuaagxvKbl3';
};

export const checkoutUrl = () => {
  return (
    process.env.GATSBY_EVALUBOX_CHECKOUT_URL ||
    'https://nvlaj6ljjg.execute-api.us-east-1.amazonaws.com/prod/generate_checkout_new_url'
  );
};

export const freeTrialCheckoutUrl = () => {
  return (
    process.env.GATSBY_EVALUBOX_FREE_TRIAL_CHECKOUT_URL ||
    'https://nvlaj6ljjg.execute-api.us-east-1.amazonaws.com/prod/create_free_trial'
  );
};

export const basicPlanCheckoutUrl = () => {
  return (
    process.env.GATSBY_EVALUBOX_BASIC_PLAN_CHECKOUT_URL ||
    'https://nvlaj6ljjg.execute-api.us-east-1.amazonaws.com/prod/create_basic_plan'
  );
};

export const siteRecaptchaKey = () => process.env.GATSBY_SITE_RECAPTCHA_KEY;
