import React from 'react';
import { SitewideHeader, SitewideFooter } from '..';
import classnames from 'classnames';

const ResourcesPage = ({ titleDivide = false, children, headerBorderBottom }: any) => (
  <>
    <SitewideHeader borderBottom={headerBorderBottom} />

    {/* <div className="bg-white pb-20  pt-8 lg:pb-28">
      <div
        className={classnames('relative max-w-lg mx-auto lg:max-w-7xl px-2 sm:px-6', {
          'divide-y-2 divide-gray-200': titleDivide,
        })}
      > */}
    {children}
    {/* </div>
    </div> */}

    <SitewideFooter />
  </>
);

export default ResourcesPage;
