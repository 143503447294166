import * as React from 'react';
export default function SecurityHeader(props: any) {
  return (
    <section>
      <div className="px-4 py-12 mx-auto max-w-7xl sm:px-6 md:px-12 lg:px-24 lg:py-24">
        <div className="flex flex-col w-full mb-12 text-center">
          <h1 className="max-w-5xl text-2xl font-bold leading-none tracking-tighter text-stai-1 md:text-5xl lg:text-6xl lg:max-w-7xl">
            Security
          </h1>
          <p className="max-w-xl mx-auto mt-8 text-base tracking-wide leading-loose text-center text-gray-600">
            Your privacy and security is a top priority at Evalubox. Your evaluation reports contain
            information that only you and your clients need to see, and we inted to keep it that
            way. Every day we ensure that our security is parallel with indusrty standards and
            compliance.
          </p>
        </div>
      </div>
    </section>
  );
}
