import React from 'react';
import { graphql } from 'gatsby';

import ellenBlackSuit from '../../content/team/ellen-kester/black-suit.webp';
import { Headline, Lead, SEO, SitewideFooter, SitewideHeader } from '../components';
import mailMerge from '../../static/images/landing/mail-merge.jpg';

import {
  CircleThreeColTeam as Team,
  SimpleCenteredHeading,
  ExplainingTheVision,
  Values,
  FooterCTA,
} from '../components/landing';
import OurStory from '../components/OurStory';
import MLAReferences from '../components/landing/MLAReferences';
import Mission from '../components/landing/Mission';

const About = ({ data: { team } }: any) => (
  <>
    <SitewideHeader />
    <Mission />
    <ExplainingTheVision />
    <OurStory />

    <div className="mx-auto max-w-5xl px-4 py-8">
      <section className="rounded-lg bg-gray-100 p-8">
        <div className="mt-1 text-center">
          <Headline id="open-roles">Writing Evaluations.</Headline>
        </div>
        <div className="mt-2 mb-5 text-center">
          <Lead>A part of our jobs that requires a lot of time and organization.</Lead>
        </div>

        <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16 py-10">
          <div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
            <img
              alt="Mail Merge"
              src={mailMerge}
              className="absolute inset-0 h-full w-full object-cover"
            />
          </div>

          <div className="lg:pb-24">
            <h2 className="text-3xl font-bold sm:text-3xl mb-4">
              We’ve tried a lot of ways to shave time off of the process.
            </h2>
            <ol className="mt-2 space-y-1 list-decimal pl-5 text-lg font-normal ">
              <li>
                <p className="sm:text-2xl">We have created templates and report blurbs.</p>
              </li>
              <li>
                <p className="sm:text-2xl">
                  We’ve tried mail merge to pull information from a spreadsheet into a word
                  document.
                </p>
              </li>
              <li>
                <p className="sm:text-2xl">
                  We’ve standardized our evaluation process to streamline our work.
                </p>
              </li>
            </ol>
            <p className="mt-4 sm:text-xl text-gray-600">
              This helped a lot but when we tried to share the approach with others, it worked for
              some and not for others depending on whether you used a PC or Mac and on the version
              and type software you used. It wasn’t an approach that our whole team could use
              successfully.
            </p>
          </div>
        </div>
        <blockquote className="sm:col-span-2 my-4 py-4">
          <h2 className="text-3xl font-bold sm:text-4xl pb-4">There had to be a better way.</h2>
          <p className="text-xl font-medium sm:text-2xl">
            That's when we realized there had to be a better way. We met Mo and Alex, the founders
            of Speech Therapy A.I. and asked them,
            <strong>"Can you help us improve the evaluation process?"</strong> That's how Evalubox
            was born. With a combined mission to unlock potential for people with disabilities
            around the world, we are starting by giving time back to those who deliver these
            services. Together, we created Evalubox, an incredibly powerful and versatile tool for
            writing evaluation reports.
          </p>
        </blockquote>
        <div className="grid grid-cols-1 gap-12 sm:grid-cols-3 sm:items-center">
          <img
            alt="Dr. Ellen Kester"
            src={ellenBlackSuit}
            className="aspect-square w-full rounded-lg object-cover"
          />

          <blockquote className="sm:col-span-2">
            <h2 className="text-3xl font-bold sm:text-4xl py-8">
              Built to support bilingual and monolingual evaluators.{' '}
            </h2>
            <p className="text-xl font-medium sm:text-2xl">
              As a bilingual SLP and researcher in the city of Austin Texas, we see a lot of
              bilingual children. We built this tool to support bilingual evaluators, as well as
              monolingual evaluators. We also created an academy to show you step-by-step how to use
              Evalubox. You’ll find tools, tips and resources inside of the academy. And we’re here
              if you want to jump on a call with us too. We hope Evalubox makes your workload less
              of a burden and brings joy into your work.
            </p>

            <cite className="my-8 inline-flex items-center not-italic">
              <span className="hidden h-px w-6 bg-gray-400 sm:inline-block"></span>
              <p className="text-sm uppercase text-gray-500 sm:ml-3">
                <strong>Dr. Ellen Kester</strong>, Founder of Bilinguistics.
              </p>
            </cite>
          </blockquote>
        </div>
      </section>
    </div>
    <MLAReferences />
    {/* <RecruiteeJobsList /> */}

    {/* <CustomerLogoCloud /> */}

    <FooterCTA />
    <SitewideFooter />
  </>
);

export default About;

export const Head = () => (
  <>
    <SEO
      title={`Why Evalubox`}
      description="The combination of speech pathology and technology expertise to create the first comprehensive report writer."
    />
  </>
);

export const pageQuery = graphql`
  {
    team: allMarkdownRemark(
      sort: { frontmatter: { name: ASC } }
      filter: { fileAbsolutePath: { regex: "/.+/content/team/.+/" } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            name
            role
            bio
            location
            linkedinUrl
            twitterUrl
            githubUrl
            headshot {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
  }
`;
