import evaluboxLogo from '../../content/assets/logos/evalubox/evalubox-image.png';
import { chargebeePublishing, chargebeeSubdomain } from '../environment';

declare global {
  interface Window {
    Chargebee: any;
    analytics: any;
    rudderanalytics: any;
    grecaptcha: any;
    posthog: any;
  }
}

import React from 'react';
import { useSiteMetadata } from '../hooks/use-site-metadata';

const SEO = ({ title, description, pathname, children }: any) => {
  const {
    title: defaultTitle,
    description: defaultDescription,
    siteUrl,
    social,
  } = useSiteMetadata();

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${evaluboxLogo}`,
    url: `${siteUrl}${pathname || ``}`,
    twitterUsername: `${social.twitter}`,
  };

  React.useEffect(() => {
    window.Chargebee.init({
      site: chargebeeSubdomain(),
      publishableKey: chargebeePublishing(),
    });
  }, []);

  const ogImageUrl = `${seo.url}${evaluboxLogo}`;
  const ogImageAlt =
    'The Evalubox logo. An outlined box into another outlined box positioned in the center towards the right and lowered. The word Evalubox is next to the right.';

  return (
    <>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="og:title" content={seo.title} />
      <meta name="og:description" content={seo.description} />
      <meta name="og:type" content="website" />
      <meta name="og:image" content={ogImageUrl} />
      <meta name="image" content={seo.image} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:url" content={seo.url} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />
      <meta name="twitter:creator" content={seo.twitterUsername} />
      {children}
    </>
  );
};
export default SEO;

// const SEO = ({ title, description = '', lang = 'en', headerImage = '', meta = [] }) => {
//   const { site } = useStaticQuery(
//     graphql`
//       query {
//         site {
//           siteMetadata {
//             title
//             siteUrl
//             description
//             social {
//               twitter
//             }
//           }
//         }
//       }
//     `
//   );

//   React.useEffect(() => {
//     window.Chargebee.init({
//       site: chargebeeSubdomain(),
//       publishableKey: chargebeePublishing(),
//     });
//   }, []);

//   const metaDescription = description || site.siteMetadata.description;
//   const twitterHandle = get(site, 'siteMetadata.social.twitter', 'evalubox');
//   const ogImageUrl = `${site.siteMetadata.siteUrl}${evaluboxLogo}`;
//   const ogImageAlt =
//     'The Evalubox logo. An outlined box into another outlined box positioned in the center towards the right and lowered. The word Evalubox is next to the right.';

//   const defaultMeta = [{
//     name: `description`,
//     content: metaDescription,
//   }, {
//     property: `og:title`,
//     content: title,
//   }, {
//     property: `og:description`,
//     content: metaDescription,
//   }, {
//     property: `og:type`,
//     content: `website`,
//   }, {
//     property: `og:image`,
//     content: ogImageUrl,
//   }, {
//     name: `twitter:card`,
//     content: `summary`,
//   }, {
//     name: `twitter:site`,
//     content: twitterHandle,
//   }, {
//     name: `twitter:image`,
//     content: ogImageUrl,
//   }, {
//     name: `twitter:image:alt`,
//     content: ogImageAlt,
//   }, {
//     name: `twitter:creator`,
//     content: twitterHandle,
//   }, {
//     name: `twitter:title`,
//     content: title,
//   }, {
//     name: `twitter:description`,
//     content: metaDescription,
//   }];

//   return (
//     <Helmet
//       htmlAttributes={{
//         lang,
//       }}
//       title={title}
//       meta={defaultMeta.concat(meta)}
//     />
//   );
// };

// export default SEO;
