import React, { useEffect, useState } from 'react';
import Prism from 'prismjs';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import {
  Button,
  Title,
  CodeBlock,
  SEO,
  SitewideHeader,
  SitewideFooter,
  TextLink,
} from '../components';
import { SubscribeToNewsletterSuccessModal } from '../components/CallToAction/SubscribeToNewsletter';
import { ExternalLinkIcon } from '@heroicons/react/outline';
import { TemplateHeader } from '../components/SmartTemplates';
import { RequestTemplateToInboxCTA } from '../components/CallToAction/RequestTemplateToInbox';
import TextBlock from '../components/TextBlock';

const PluginCTA = ({ template, overrideTitle = null }) => (
  <div className="docs-cta my-6">
    <h3 className="docs-cta__title">
      {overrideTitle
        ? overrideTitle
        : template.frontmatter.availableOnEvalubox
        ? `See the ${template.frontmatter.humanName} Smart templates in action`
        : `Skip the manual copy & paste and find/replace for every single report with no-prep Smart Template`}
    </h3>
    <Button
      link={true}
      color="primary"
      size="small"
      to={`/request-demo/?utm_source=evalubox-marketplace&utm_campaign=${template.frontmatter.humanName}`}
      text={'Request a Evalubox demo'}
    />
  </div>
);

const HostTabs = ({ docsLink }) => (
  <nav className="invisible lg:visible mb-8 mx-[-1rem] px-[1rem] flex flex-wrap items-center text-center border-b-2 border-gray-200">
    <span className="inline-block p-4 text-blueroadie font-bold bg-gray-elusivegray border-2 border-gray-200 border-b-elusivegray rounded-t-lg mb-[-2px]">
      Smart Templates
    </span>
    {docsLink && !docsLink.includes('null') && (
      <a
        href={docsLink}
        target="_blank"
        rel="noreferrer"
        className="inline-block p-2 px-4 ml-4 bg-gray-100 text-blueroadie font-bold rounded-lg hover:bg-gray-100 hover:text-orange-600 flex align-center"
      >
        No-code via Evalubox <ExternalLinkIcon className="inline-block w-4 ml-2" />
      </a>
    )}
  </nav>
);

const TemplateTabs = ({ docsLink }) => (
  <nav className="invisible lg:visible my-10 mx-[-1rem] px-[1rem] flex flex-wrap items-center text-center border-b-2 border-gray-200">
    <span className="inline-block p-4 text-blueroadie font-bold bg-gray-elusivegray border-2 border-gray-200 border-b-elusivegray rounded-t-lg mb-[-2px]">
      Smart Templates
    </span>
    <p className="inline-block p-2 px-4 ml-4 bg-gray-100 text-blueroadie font-bold rounded-lg hover:bg-gray-100 hover:text-orange-600 flex align-center">
      Copy and paste the following template text below to use in your report
    </p>
    {/* {docsLink && !docsLink.includes('null') && (
      <a
        href={docsLink}
        target="_blank"
        rel="noreferrer"
        className="inline-block p-2 px-4 ml-4 bg-gray-100 text-blueroadie font-bold rounded-lg hover:bg-gray-100 hover:text-orange-600 flex align-center"
      >
        No-code via Evalubox <ExternalLinkIcon className="inline-block w-4 ml-2" />
      </a>
    )} */}
  </nav>
);
const SmartTemplate = ({ data }) => {
  const {
    template,
    site: { siteMetadata },
  } = data;

  const [email, setEmail] = useState('');
  const [modalOpen, setModalOpen] = useState(false);

  const handleCloseModal = () => {
    setModalOpen(false);
    setEmail('');
  };

  useEffect(() => {
    Prism.highlightAll();
  });

  return (
    <>
      <SEO
        title={template.frontmatter.seo.title}
        description={template.frontmatter.seo.description}
      />

      <SubscribeToNewsletterSuccessModal
        modalOpen={modalOpen}
        handleCloseModal={handleCloseModal}
        siteMetadata={data.site.siteMetadata}
        email={email}
      />

      <SitewideHeader />

      <TemplateHeader template={template} />
      <main className="pt-4 pb-8 px-4 lg:pb-28">
        <div className="relative max-w-lg mx-auto lg:max-w-4xl">
          {template.frontmatter.intro ? (
            <>
              <div
                className="mb-4 mt-0 text-lg template-intro"
                dangerouslySetInnerHTML={{ __html: template.frontmatter.intro }}
              />
              {/* <PluginCTA template={template} /> */}

              {template.frontmatter.coverImage && (
                <GatsbyImage
                  image={template.frontmatter.coverImage.childImageSharp.gatsbyImageData}
                  alt={template.frontmatter.coverImageAlt}
                  className="max-w-full max-h-full shadow-small mb-12"
                />
              )}
            </>
          ) : (
            <>
              {template.frontmatter.coverImage && (
                <GatsbyImage
                  image={template.frontmatter.coverImage.childImageSharp.gatsbyImageData}
                  alt={template.frontmatter.coverImageAlt}
                  className="max-w-full max-h-full shadow-small mb-4"
                />
              )}
              {/* <PluginCTA template={template} /> */}
            </>
          )}
          {/* <Title el="h2" className="xl:text-2xl xl:tracking-tight mb-6" id="installation-steps">
            {template.frontmatter.intro ? template.frontmatter.intro : "Report Template Pieces"}
          </Title> */}

          {/* <HostTabs docsLink={`/docs/integrations${template.frontmatter.evaluboxDocsPath}`} /> */}

          {template.frontmatter.gettingStarted && (
            <>
              {template.frontmatter.gettingStarted.map((section) => {
                const key = CodeBlock.generateKey(section) + Math.random();

                if (section.title && section.title !== '') {
                  return (
                    <div className="text-center pb-3" key={key}>
                      <Title text={section.title} />
                    </div>
                  );
                }

                return (
                  <CodeBlock
                    language={section.language}
                    code={section.code}
                    intro={section.intro}
                    sectionId={section.sectionId}
                    bolded={section.bolded}
                    personal={section.personal}
                    key={key}
                  />
                );
              })}
            </>
          )}

          <div className="relative max-w-lg mx-auto lg:max-w-xl mt-24">
            <RequestTemplateToInboxCTA
              template={template.frontmatter.humanName}
              setModalOpen={setModalOpen}
              email={email}
              setEmail={setEmail}
            />
          </div>
          <TemplateTabs docsLink={`/docs/integrations${template.frontmatter.evaluboxDocsPath}`} />

          {template.frontmatter.templateText && (
            <div className="my-4 border-b ">
              {template.frontmatter.templateText.map((section) => {
                const key = CodeBlock.generateKey(section) + Math.random();

                if (section.title && section.title !== '') {
                  return (
                    <div className="text-center pb-3" key={key}>
                      <Title el="h2" text={section.title} />
                      <p>{section.text}</p>
                    </div>
                  );
                }
                return (
                  <TextBlock
                    language={section.language}
                    code={section.code}
                    intro={section.intro}
                    sectionId={section.sectionId}
                    key={key}
                  />
                );
              })}
            </div>
          )}

          {/* <p className="prose prose-primary my-10">
            Found a mistake? <TemplateEditOnGitHubLink siteMetadata={siteMetadata} template={template} />.
          </p> */}

          {template.notes && template.notes !== '' && (
            <>
              {/* <Title el="h2" className="xl:text-2xl xl:tracking-tight mb-6" id="things-to-know">
                {template.frontmatter.thingsToKnowTitle
                  ? `${template.frontmatter.thingsToKnowTitle}`
                  : 'Things to know'}
              </Title> */}

              {/* {template.frontmatter.thingsToKnowHostDependant && (
                <HostTabs docsLink={`${template.frontmatter.thingsToKnowOnEvalubox}`} />
              )} */}

              <div
                className="prose-xl prose-primary max-w-none"
                dangerouslySetInnerHTML={{ __html: template.notes }}
              />
            </>
          )}
          {/* <PluginCTA template={template} overrideTitle="Prefer a no-prep Smart Template setup?" /> */}
        </div>

        <div className="relative max-w-lg mx-auto lg:max-w-xl mt-24">
          <RequestTemplateToInboxCTA
            template={template.frontmatter.humanName}
            setModalOpen={setModalOpen}
            email={email}
            setEmail={setEmail}
          />
        </div>
        <div className="relative max-w-lg mx-auto lg:max-w-4xl">
          <BackToLibraryCTA />
        </div>
      </main>

      <SitewideFooter />
    </>
  );
};

const BackToLibraryCTA = () => {
  return (
    <>
      <div className="mt-6 pt-4 border-t">
        <h3 className="font-bold text-3xl  text-blueroadie py-4">Looking for more templates</h3>
        <TextLink
          to="/resources/speech-therapy-report-templates/"
          className="font-bold text-xl text-blueroadie"
          color="primary"
        >
          <span className="text-orange-500">←</span> Visit our full speech language template
          library.
        </TextLink>
      </div>
    </>
  );
};

export default SmartTemplate;

export const pageQuery = graphql`
  query TemplateBySlug($slug: String!) {
    site {
      siteMetadata {
        sourceCodeUrl
        social {
          twitter
        }
      }
    }

    template: markdownRemark(fields: { slug: { eq: $slug } }) {
      notes: html
      fileAbsolutePath

      frontmatter {
        humanName
        lead
        heading
        intro
        attribution {
          href
          text
        }

        logoImage {
          childImageSharp {
            gatsbyImageData(layout: FIXED, width: 140)
          }
        }

        coverImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        coverImageAlt

        seo {
          title
          description
        }

        gettingStarted {
          intro
          code
          language
          bolded
          personal
          # sectionId
        }

        templateText {
          title
          text
          intro
          code
          language
          # sectionId
        }
      }
    }
  }
`;
