import React from 'react';

import Avatar from './Avatar';

import ronAvatar from '../../../../content/assets/home/testimonial/ron-yotpo/avatar.webp';
import ronAvatarJpeg from '../../../../content/assets/home/testimonial/ron-yotpo/avatar.jpeg';

import markAvatar from '../../../../content/assets/home/testimonial/mark-hopper/avatar.webp';
import markAvatarJpeg from '../../../../content/assets/home/testimonial/mark-hopper/avatar.jpeg';

import jonAvatar from '../../../../content/assets/home/testimonial/jon-ncsa/avatar.webp';
import jonAvatarJpeg from '../../../../content/assets/home/testimonial/jon-ncsa/avatar.jpeg';

import deaninneAvatar from '../../../../content/assets/home/testimonial/deaninne-sps/avatar.png';
import deaninneAvatarJpeg from '../../../../content/assets/home/testimonial/deaninne-sps/avatar.jpg';

import laurenAvatar from '../../../../content/assets/home/testimonial/lauren-bilinguistics/avatar.webp';
import laurenAvatarJpeg from '../../../../content/assets/home/testimonial/lauren-bilinguistics/avatar.jpg';

import laurenFcecAvatar from '../../../../content/assets/home/testimonial/lauren-fcec/avatar.webp';
import laurenFcecAvatarJpeg from '../../../../content/assets/home/testimonial/lauren-fcec/avatar.jpg';

const TESTIMONIALS = [
  {
    title: 'Organized',
    text: (
      <>
        <p className="prose mb-2">
          Evalubox has been an excellent guide for me as an SLP that is new to school-based
          evaluations. Coming from a clinic setting, I was a bit nervous about making sure all
          necessary information was included in an organized way in my reports.
        </p>
        <p className="prose">
          This tool has been very helpful in completing thorough, organized evaluations in a
          reasonable amount of time.
        </p>
      </>
    ),
    author: {
      name: 'Lauren Hudgins',
      role: 'M.S., CCC-SLP',
      company: 'Speech Language Pathologist',
      avatar: {
        webp: laurenAvatar,
        jpeg: laurenAvatarJpeg,
        alt: `A young woman  looking straight at the camera.`,
      },
    },
  },
  {
    title: 'I wish I had it 5 years ago',
    text: (
      <>
        <p className="prose mb-2">
          This tool is so helpful. Entering the information is simple with the easy-to-use platform,
          and it saves a lot of time and helps you create a beautiful report.
        </p>
        <p className="prose">I wish I had it 5 years ago. Would recommend to anyone!</p>
      </>
    ),
    author: {
      name: 'L.C ',
      role: 'M.S., CCC-SLP',
      company: 'Falls County Education Cooperative',
      avatar: {
        webp: laurenFcecAvatar,
        jpeg: laurenFcecAvatarJpeg,
        alt: `A young woman  looking straight at the camera.`,
      },
    },
  },
  // {
  //   title: 'Magic',
  //   text: (
  //     <>
  //       <p className="prose mb-2">
  //         I consider myself a low tech person and writing evaluations is the hardest thing I have to
  //         do. I test up to 13 pre-school age kids or younger every week and I desperately needed
  //         something to help me simplify the process, reduce the stress, and still feel confident in
  //         my results and presentations
  //       </p>
  //       <p className="prose">
  //         Thats when I met the Evalubox team and saw Ellen create a full evaluation report from just
  //         clicking a button. It was like magic! and that's when I realized I had to get my hands on
  //         this tool and do what Ellen just did.
  //       </p>
  //     </>
  //   ),
  //   author: {
  //     name: 'Melissa Shenian',
  //     role: 'Bilingual CCC-SLP',
  //     company: 'Worcester Schools',
  //     avatar: {
  //       webp: deaninneAvatar,
  //       jpeg: deaninneAvatarJpeg,
  //       alt: `A sketch of some characters playing an early form of football`,
  //     },
  //   },
  // },
];

const Testimonial = ({ author, text, title }: any) => (
  <blockquote className="flex flex-col rounded-lg shadow-lg overflow-hidden">
    <div className="flex-1 bg-white p-6 flex flex-col justify-between">
      <div className="flex-1 mt-4 mb-12">
        <p className="mb-4 text-base text-gray-900 font-bold">{title}</p>
        {text}
      </div>

      <div className="mt-6 flex items-start">
        {/* <Avatar avatar={author.avatar} borderColor="primary-600" /> */}
        <div className="ml-4">
          <div className="text-base text-gray-900">{author.name}</div>
          <div className="text-base text-gray-500">{author.role}</div>
          <div className="text-base text-gray-500">{author.company}</div>
        </div>
      </div>
    </div>
  </blockquote>
);

const Testimonials = ({ testimonials = TESTIMONIALS }) => (
  <div className="bg-gray-900">
    <div className="max-w-7xl mx-auto py-12 lg:py-32 px-4 sm:px-6 lg:px-32">
      <div className="text-center text-2xl mb-8 sm:mb-12 text-white">
        <h1>What Other Professionals Are Saying</h1>
      </div>

      <div className="grid gap-16 lg:grid-cols-2 lg:gap-x-5 lg:gap-y-12">
        {testimonials.map((props) => (
          <Testimonial {...props} key={props.author.name} />
        ))}
      </div>
    </div>
  </div>
);

export default Testimonials;
