import React, { Fragment } from 'react';
import { CheckIcon, MinusIcon } from '@heroicons/react/solid';
import { TextLink } from '../../components';

import TIERS from './tiers';
import { chargebeeSubdomain, chargebeeTestSubdomain } from '../../environment';

const sections = [
  {
    name: 'Evaluations',
    features: [
      {
        name: 'Evaluation List',
        tiers: { 'SLP Pro': true, 'Power Evaluator': true, Teams: true },
      },
      {
        name: 'Evaluation Checklist',
        tiers: { 'SLP Pro': true, 'Power Evaluator': true, Teams: true },
      },
      {
        name: 'Evaluation Reports',
        tiers: { 'SLP Pro': true, 'Power Evaluator': true, Teams: true },
      },
      {
        name: 'Status Tracking',
        tiers: { 'SLP Pro': true, 'Power Evaluator': true, Teams: true },
      },
      {
        name: 'Assign Examiner',
        tiers: { 'SLP Pro': false, 'Power Evaluator': false, Teams: true },
      },
      {
        name: 'Due Date Notifications',
        tiers: { 'SLP Pro': false, 'Power Evaluator': false, Teams: true },
      },
      {
        name: 'Peer Review',
        tiers: { 'SLP Pro': false, 'Power Evaluator': false, Teams: true },
      },
    ],
  },
  {
    name: 'Digital Forms',
    features: [
      {
        name: 'Sharable Intake Forms (Multi-Lingual)',
        tiers: { 'SLP Pro': true, 'Power Evaluator': true, Teams: true },
      },
      {
        name: 'Multilingual Support',
        tiers: { 'SLP Pro': true, 'Power Evaluator': true, Teams: true },
      },
      {
        name: 'Translate Billingual Responses',
        tiers: { 'SLP Pro': false, 'Power Evaluator': true, Teams: true },
      },
    ],
  },
  {
    name: 'Gathering Information',
    features: [
      {
        name: 'Client Intake',
        tiers: { 'SLP Pro': true, 'Power Evaluator': true, Teams: true },
      },
      {
        name: 'Teacher Intake',
        tiers: { 'SLP Pro': true, 'Power Evaluator': true, Teams: true },
      },
      {
        name: 'Health Intake',
        tiers: { 'SLP Pro': true, 'Power Evaluator': true, Teams: true },
      },
      {
        name: 'Language Proficiency',
        tiers: { 'SLP Pro': true, 'Power Evaluator': true, Teams: true },
      },
    ],
  },
  {
    name: 'Assessment Impressions',
    features: [
      {
        name: 'Behavior During Testing',
        tiers: { 'SLP Pro': true, 'Power Evaluator': true, Teams: true },
      },
      {
        name: 'Languages Tested (Billingual Evaluations)',
        tiers: { 'SLP Pro': true, 'Power Evaluator': true, Teams: true },
      },
      {
        name: 'Assessment Tools',
        tiers: { 'SLP Pro': true, 'Power Evaluator': true, Teams: true },
      },
      {
        name: 'Articulation',
        tiers: { 'SLP Pro': true, 'Power Evaluator': true, Teams: true },
      },
      {
        name: 'Receptive',
        tiers: { 'SLP Pro': true, 'Power Evaluator': true, Teams: true },
      },
      {
        name: 'Expressive',
        tiers: { 'SLP Pro': true, 'Power Evaluator': true, Teams: true },
      },
      {
        name: 'Fluency',
        tiers: { 'SLP Pro': true, 'Power Evaluator': true, Teams: true },
      },
      {
        name: 'Voice',
        tiers: { 'SLP Pro': true, 'Power Evaluator': true, Teams: true },
      },
    ],
  },
  {
    name: 'Recommendations',
    features: [
      {
        name: 'Determinations',
        tiers: { 'SLP Pro': true, 'Power Evaluator': true, Teams: true },
      },
      {
        name: 'Goals',
        tiers: { 'SLP Pro': true, 'Power Evaluator': true, Teams: true },
      },
      {
        name: 'Accomodations',
        tiers: { 'SLP Pro': true, 'Power Evaluator': true, Teams: true },
      },
    ],
  },
  {
    name: 'Reports',
    features: [
      {
        name: 'Evaluation Report',
        tiers: { 'SLP Pro': true, 'Power Evaluator': true, Teams: true },
      },
      {
        name: 'Insert Template/Blurb',
        tiers: { 'SLP Pro': true, 'Power Evaluator': true, Teams: true },
      },
      {
        name: 'Auto-save',
        tiers: { 'SLP Pro': true, 'Power Evaluator': true, Teams: true },
      },
    ],
  },
  {
    name: 'Templates',
    features: [
      {
        name: 'Template and Blurb Library',
        tiers: { 'SLP Pro': true, 'Power Evaluator': true, Teams: true },
      },
      {
        name: 'Smart Placeholders',
        tiers: { 'SLP Pro': true, 'Power Evaluator': true, Teams: true },
      },
      {
        name: 'Conditionals',
        tiers: { 'SLP Pro': true, 'Power Evaluator': true, Teams: true },
      },
      {
        name: 'Assessment Tool Blurbs and Descriptions',
        tiers: { 'SLP Pro': true, 'Power Evaluator': true, Teams: true },
      },
      {
        name: 'Contextual Information with Blurbs/Templates',
        tiers: { 'SLP Pro': true, 'Power Evaluator': true, Teams: true },
      },
    ],
  },
  {
    name: 'Teams',
    features: [
      {
        name: 'Invite Team',
        tiers: { Teams: true },
      },
      {
        name: 'Admin/User Management',
        tiers: { Teams: true },
      },
      {
        name: 'Reporting',
        tiers: { Teams: true },
      },
    ],
  },
  {
    name: 'Security',
    features: [
      {
        name: 'HIPAA (BAA upon request)',
        tiers: { 'SLP Pro': true, 'Power Evaluator': true, Teams: true },
      },
      {
        name: 'HIPAA & FERPA Complaince',
        tiers: { 'SLP Pro': true, 'Power Evaluator': true, Teams: true },
      },
      {
        name: 'Audit Log Trail',
        tiers: { Teams: true },
      },
    ],
  },
  {
    name: 'Support',
    features: [
      {
        name: 'Evalubox Academy',
        tiers: { 'SLP Pro': true, 'Power Evaluator': true, Teams: true },
      },
      {
        name: 'In-app Chat',
        tiers: { 'SLP Pro': true, 'Power Evaluator': true, Teams: true },
      },
      {
        name: 'Email Support',
        tiers: { 'SLP Pro': true, 'Power Evaluator': true, Teams: true },
      },
      {
        name: 'Dedicated Support',
        tiers: { 'Power Evaluator': true, Teams: true },
      },
      {
        name: '24/7 On-call',
        tiers: { 'Power Evaluator': true, Teams: true },
      },
    ],
  },
];

const FeatureNameHeaderCell = ({ feature }: any) => (
  <th className="py-5 px-4 lg:px-6 text-sm font-normal text-gray-500 text-left" scope="row">
    {feature.name}
  </th>
);

const FeatureInTierIndicatorText = ({ text }: any) => (
  <span className="block text-sm text-gray-700 text-right lg:text-left">{text}</span>
);

const FeatureInTierIndicatorIcon = ({ featureTier, tier }: any) => (
  <>
    {featureTier === true ? (
      <CheckIcon className="ml-auto lg:ml-0 h-5 w-5 text-green-500" aria-hidden="true" />
    ) : (
      <MinusIcon className="ml-auto lg:ml-0 h-5 w-5 text-gray-400" aria-hidden="true" />
    )}

    <span className="sr-only">
      {featureTier === true ? 'Included' : 'Not included'} in {tier.name}
    </span>
  </>
);

const FeatureTierIndicatorCell = ({ tier, featureTier }: any) => (
  <td className="py-5 pr-4 lg:px-6">
    {typeof featureTier === 'string' ? (
      <FeatureInTierIndicatorText text={featureTier} />
    ) : (
      <FeatureInTierIndicatorIcon featureTier={featureTier} tier={tier} />
    )}
  </td>
);

const FeatureRow = ({ feature, tier }: any) => (
  <tr key={feature.name} className="border-t border-gray-200">
    <FeatureNameHeaderCell feature={feature} />
    <FeatureTierIndicatorCell featureTier={feature.tiers[tier.name]} tier={tier} />
  </tr>
);

const SectionTable = ({ section, tier, tierIndex }: any) => (
  <table key={section.name} className="w-full">
    <caption className="bg-gray-50 border-t border-gray-200 py-3 px-4 text-sm font-medium text-gray-900 text-left">
      {section.name}
    </caption>

    <thead>
      <tr>
        <th className="sr-only" scope="col">
          Feature
        </th>
        <th className="sr-only" scope="col">
          Included
        </th>
      </tr>
    </thead>

    <tbody className="divide-y divide-gray-200">
      {section.features.map((feature) => (
        <FeatureRow feature={feature} key={`${tierIndex}-${feature.name}`} tier={tier} />
      ))}
    </tbody>
  </table>
);

const LargeFeatureRow = ({ feature }: any) => (
  <tr>
    <FeatureNameHeaderCell feature={feature} />

    <FeatureTierIndicatorCell featureTier={feature.tiers[TIERS.pro.name]} tier={TIERS.pro} />

    <FeatureTierIndicatorCell
      featureTier={feature.tiers[TIERS.premium.name]}
      tier={TIERS.premium}
    />

    <FeatureTierIndicatorCell featureTier={feature.tiers[TIERS.teams.name]} tier={TIERS.teams} />
  </tr>
);

const LargeTierHeaderCell = ({ tier }: any) => (
  <th
    key={tier.name}
    className="w-1/4 pb-4 px-6 text-lg leading-6 font-medium text-gray-900 text-left"
    scope="col"
  >
    {tier.name}
  </th>
);

const LargeTierCta = ({ tier, isSlpImpact, ctaLink = false, frequency }: any) => (
  <td className="h-full py-8 px-6 align-top">
    <div className="relative h-full table">
      <TextLink
        color="primary"
        to={ctaLink ? ctaLink.href : `${tier.ctaLinkTo}&frequency=${frequency}`}
      >
        {tier.ctaLabel}
      </TextLink>
    </div>
  </td>
);

const LargeSectionHeaderRow = ({ section }: any) => (
  <tr>
    <th
      className="bg-gray-50 py-3 pl-6 text-sm font-medium text-gray-900 text-left"
      colSpan={4}
      scope="colgroup"
    >
      {section.name}
    </th>
  </tr>
);

const GetBuyLink = (currentlySetFrequency, planName) => {
  const chargebeePlanName = `${planName}-${currentlySetFrequency}`.toLocaleLowerCase();
  const chargebeeUrl = new URL(
    `https://${chargebeeSubdomain()}.chargebee.com/hosted_pages/plans/${chargebeePlanName}`
  );
  return chargebeeUrl;
};
const FeatureComparisonTable = ({ isSlpImpact = false, currentlySetFrequency }) => {
  return (
    <>
      {/* xs to lg */}
      <div className="max-w-2xl mx-auto space-y-16 lg:hidden">
        <section>
          <div className="px-4 mb-8">
            <h2 className="text-lg leading-6 font-medium text-gray-900">{TIERS.pro.name}</h2>
          </div>

          {sections.map((section) => (
            <SectionTable section={section} key={section.name} tier={TIERS.pro} tierIndex={0} />
          ))}
        </section>

        <section>
          <div className="px-4 mb-8">
            <h2 className="text-lg leading-6 font-medium text-gray-900">{TIERS.teams.name}</h2>
          </div>

          {sections.map((section) => (
            <SectionTable section={section} key={section.name} tier={TIERS.teams} tierIndex={1} />
          ))}
        </section>
        {/* <section>
          <div className="px-4 mb-8">
            <h2 className="text-lg leading-6 font-medium text-gray-900">{TIERS.premium.name}</h2>
          </div>

          {sections.map((section) => (
            <SectionTable section={section} key={section.name} tier={TIERS.premium} tierIndex={1} />
          ))}
        </section> */}
      </div>

      {/* lg+ */}
      <div className="hidden lg:block">
        <table className="w-full h-px">
          <caption className="sr-only">Pricing plan comparison</caption>
          <thead>
            <tr>
              <th className="pb-4 px-6 text-sm font-medium text-gray-900 text-left" scope="col">
                <span className="sr-only">Feature by</span>
                <span>Plans</span>
              </th>
              <LargeTierHeaderCell tier={TIERS.pro} />
              <LargeTierHeaderCell tier={TIERS.premium} />
              <LargeTierHeaderCell tier={TIERS.teams} />
            </tr>
          </thead>

          <tbody className="border-y border-gray-200 divide-y divide-gray-200">
            <tr>
              <th
                className="py-8 px-6 text-sm font-medium text-gray-900 text-left align-top"
                scope="row"
              >
                Get started
              </th>

              <LargeTierCta
                tier={TIERS.pro}
                isSlpImpact={isSlpImpact}
                frequency={currentlySetFrequency}
              />
              <LargeTierCta tier={TIERS.premium} frequency={currentlySetFrequency} />
              <LargeTierCta tier={TIERS.teams} isSlpImpact={isSlpImpact} />
            </tr>

            {sections.map((section) => (
              <Fragment key={section.name}>
                <LargeSectionHeaderRow section={section} />
                {section.features.map((feature) => (
                  <LargeFeatureRow feature={feature} key={feature.name} />
                ))}
              </Fragment>
            ))}

            <tr>
              <th
                className="py-8 px-6 text-sm font-medium text-gray-900 text-left align-top"
                scope="row"
              >
                <span className="sr-only">Get started</span>
              </th>

              <LargeTierCta
                tier={TIERS.pro}
                isSlpImpact={isSlpImpact}
                frequency={currentlySetFrequency}
              />
              <LargeTierCta tier={TIERS.premium} frequency={currentlySetFrequency} />
              <LargeTierCta tier={TIERS.teams} isSlpImpact={isSlpImpact} />
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default FeatureComparisonTable;
