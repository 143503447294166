import React from 'react';
import { SCM_TOOLS } from '../../components/forms/ScmToolRadioGroup';

import FormSubmissionModal from './FormSubmissionModal';

const isScmToolSupported = (tool) =>
  SCM_TOOLS.filter(({ supported }) => supported)
    .map(({ value }) => value)
    .includes(tool);

const SubmissionSuccessModal = ({
  scmTool,
  positiveTitle = 'Positive title!',
  positiveBody = <p>This is the positive body</p>,
  negativeTitle = 'Negative title :-(',
  negativeBody = <p>This is the negative body</p>,
  ...rest
}: any) => {
  if (isScmToolSupported(scmTool)) {
    return (
      <FormSubmissionModal
        titleText={positiveTitle}
        bodyText={positiveBody}
        followOn="FREE_TRIAL_CHECKOUT"
        {...rest}
      />
    );
  }

  return (
    <FormSubmissionModal
      titleText={negativeTitle}
      titleEmoji={null}
      bodyText={negativeBody}
      followOn="FREE_TRIAL_CHECKOUT"
      {...rest}
    />
  );
};

export default SubmissionSuccessModal;
