import { useState } from 'react';
import LanguageExposureReportEmailFormCallToAction from '../CallToAction/LangageExposureReportEmailCallToAction';
import React from 'react';
import Title from '../Title';
import { FormSubmissionModal } from '../FormSubmissionModal';
import TextLink from '../TextLink';

export const SubscribeToNewsletterSuccessModal = (props) => (
  <FormSubmissionModal
    titleText="Your report is on the way!"
    bodyText={
      <>
        <p>You should get an email shortly with your report.</p>
        <p>
          In the meantime, we&apos;d love to show you how you can get the same report in half the
          time with our Evalubox platform
        </p>
      </>
    }
    followOn="REQUEST_DEMO_AND_SIGNUP"
    {...props}
  />
);

const EmailReportCTA = ({ results, selectedOption, sampleUrl }) => {
  const [email, setEmail] = useState('');
  const [modalOpen, setModalOpen] = useState(false);

  const handleCloseModal = () => {
    setModalOpen(false);
    setEmail('');
  };
  return (
    <>
      <SubscribeToNewsletterSuccessModal
        modalOpen={modalOpen}
        handleCloseModal={handleCloseModal}
        email={email}
      />
      <div className="relative max-w-lg mx-auto lg:max-w-xl mt-24">
        <div className="text-center">
          <div className="pb-3">
            <Title text={`Get Your Full Summarized Report`} />
          </div>

          <p className="prose prose-primary mb-3 max-w-62 mx-auto">
            Enter your email and we will send you a report-ready summary of language use. Here’s
            what it looks like:{' '}
            <TextLink color="primary" to={sampleUrl} target="_blank">
              Sample Report
            </TextLink>{' '}
            Add the text to your evaluation or simply send it to your team!
          </p>

          <LanguageExposureReportEmailFormCallToAction
            buttonText={`Get Report`}
            // netlifyFormName={FORM_NAMES.subscribeToNewsletter}
            results={results}
            selectedOption={selectedOption}
            setModalOpen={setModalOpen}
            email={email}
            setEmail={setEmail}
          />
        </div>
        {/* <LanguageExposureReportEmailFormCallToAction
              results={results}
              selectedOption={selectedOption}
              setModalOpen={setModalOpen}
              email={email}
              setEmail={setEmail}
            /> */}
      </div>
    </>
  );
};

export default EmailReportCTA;
