import React, { useState } from 'react';

// import { PER_SEAT_PRICES } from '../../components/pricing/prices';
import { Button } from '../forms';

import TeamsTier from './TeamsTier';
import TIERS from './tiers';

const TeamsPricingTier = ({ isSlpImpact = false }) => (
  <TeamsTier
    ctaComponent={
      <Button
        text={TIERS.teams.ctaLabel}
        link={true}
        color="primary"
        to={isSlpImpact ? TIERS.teams.ctaLinkToSlpImpact : TIERS.teams.ctaLinkTo}
        fullWidth
      />
    }
  />
);
export default TeamsPricingTier;
