import React from 'react';
import { Link } from '../../components';

const TopNavItem = ({ to, text }: any) => (
  <Link to={to} className="text-base font-medium text-gray-500 hover:text-gray-900">
    {text}
  </Link>
);

export default TopNavItem;
