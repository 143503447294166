import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { Headline, Lead, SEO, SitewideHeader } from '../../components';
import BillingFrequencySwitcher from '../../components/pricing/BillingFrequencySwitcher';
import { PurchaseTeamsTier, SectionHeader } from '../../components/pricing';
import PricingFAQs from '../../components/landing/PricingFAQs';
import ExaminerPricingTier from '../../components/pricing/ExaminerPricingTier';
import ExaminerPremiumPricingTier from '../../components/pricing/ExaminerPremiumPricingTier';
import FeaturesLists from '../../components/Features/features-lists';
import { isBrowser } from '../../utils/isBrowser';
import ExaminerPAYGPricingTier from '../../components/pricing/ExaminerPAYGPricingTier';
import {
  AgeCalculatorIconSmall,
  EvaluationIconSmall,
  FileAudioIconSmall,
  FormsIconSmall,
  LibraryIconSmall,
} from '../../components/LanguageProficiency/AgeCalculatorIcon';
import { LanguageExposureIconSmall } from '../../components/LanguageProficiency/LanguageUseIcon';

// Method two: using the feature flags component

const Pricing = ({
  data: {
    site: {
      siteMetadata: { title: siteTitle },
    },
  },
}: any) => {
  const [currentlySetFrequency, setFrequency] = useState('Annual');
  const [variant, setVariant] = useState('control');

  useEffect(() => {
    try {
      const variant =
        isBrowser && window.posthog && window?.posthog?.getFeatureFlag('pricing-slppro-only');
      setVariant(variant);
    } catch (error) {}
  }, []);

  const [selectedPlan, setSelectedPlan] = useState('SLP_PRO');
  const params = new URLSearchParams(isBrowser() && location.search);
  const utm_content = decodeURIComponent(params.get('utm_content') || '');

  useEffect(() => {
    if (utm_content) {
      try {
        if (utm_content === 'cta-campus-clinic-slp') {
          setSelectedPlan('PAYGO');
        } else if (utm_content === 'cta-power-evaluator') {
          setSelectedPlan('SLP_PRO');
        } else if (utm_content === 'cta-slp-team-lead') {
          setSelectedPlan('TEAMS');
        } else {
          setSelectedPlan('SLP_PRO');
        }
      } catch (error) {}
    }
  }, [utm_content]);

  return (
    <>
      <SitewideHeader borderBottom={false} />
      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-10 px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:flex-col sm:align-center">
            <SectionHeader headline="Plans and Pricing" />

            <BillingFrequencySwitcher
              setFrequency={setFrequency}
              currentlySetFrequency={currentlySetFrequency}
            />
            <p className="mx-auto text-center mt-2 md:mt-2 text-xl">
              {' '}
              Pay annually and <mark className="underline font-bold">save up to 30%</mark>
            </p>
          </div>
          <Lead className="mx-auto text-primary font-bold text-center mt-2">
            Click on each plan to see whats included!
          </Lead>
          <div
            className={`sm:grid-cols-3 mt-8 space-y-4 sm:mt-8 sm:space-y-0 sm:grid  sm:gap-6 lg:max-w-4xl lg:mx-auto`}
          >
            {/* <ExaminerPricingTier currentlySetFrequency={currentlySetFrequency} /> */}
            <ExaminerPAYGPricingTier
              selectedPlan={selectedPlan}
              setSelectedPlan={setSelectedPlan}
            />
            {/* {variant !== 'slp-pro' && ( */}
            <ExaminerPremiumPricingTier
              currentlySetFrequency={currentlySetFrequency}
              selectedPlan={selectedPlan}
              setSelectedPlan={setSelectedPlan}
            />
            {/* )} */}
            <PurchaseTeamsTier selectedPlan={selectedPlan} setSelectedPlan={setSelectedPlan} />
          </div>
          <div className="max-w-5xl mx-auto pt-16 sm:px-6 lg:px-8">
            <Tools selectedPlan={selectedPlan} />
          </div>
          <div className="max-w-7xl mx-auto py-16 sm:py-24 sm:px-6 lg:px-8">
            {/* <div className="text-center mb-20">
             <Headline el="h2" size="small">
               Plan comparison
             </Headline>
           </div> */}
            <FeaturesLists />
            {/* <FeatureComparisonTable currentlySetFrequency={currentlySetFrequency} /> */}
          </div>
        </div>
      </div>
      <PricingFAQs />
    </>
  );
};

// type PricingTableProps = {
//   currentlySetFrequency: (frequency: string) => void
// }

// const DefaultPricingTable = ({currentlySetFrequency}: any) => {
//   return (
//     <>
//      <PostHogFeature flag='experiment-feature-flag-key' match={false}>
//         <div className="mt-8 space-y-4 sm:mt-8 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-6 lg:max-w-4xl lg:mx-auto">
//           <ExaminerPricingTier currentlySetFrequency={currentlySetFrequency} />
//           <ExaminerPremiumPricingTier currentlySetFrequency={currentlySetFrequency} />
//           <PurchaseTeamsTier />
//         </div>
//       </PostHogFeature>

//     </>
//   )
// }

// const SLPProPricingOnly = ({currentlySetFrequency}: any) => {
//   return (
//     <>
//       <PostHogFeature flag='experiment-feature-flag-key' match={'slp-pro'}>
//         <div className="mt-8 space-y-4 sm:mt-8 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl mx-auto lg:mx-auto">
//           <ExaminerPricingTier currentlySetFrequency={currentlySetFrequency} />
//           <PurchaseTeamsTier />
//         </div>
//       </PostHogFeature>
//     </>
//   )
// }

export default Pricing;

export const Head = () => (
  <>
    <SEO
      title={`Pricing`}
      description="Evaluation solution pricing from Evalubox. Simple, flexible pricing options to fit your evaluation needs. Get started."
    />
  </>
);

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

const solutions = [
  {
    name: 'Evaluations',
    description: 'Write Comprehensive Speech Language Reports in a Fraction of the Time!',
    to: '/',
    icon: EvaluationIconSmall,
    access: ['SLP_PRO', 'TEAMS'],
  },
  {
    name: 'Speech Referral Form',
    description: 'Gather parent, teacher, and health information in multiple languages.',
    to: '/speech-referral-form/',
    icon: FormsIconSmall,
    access: ['PAYGO', 'SLP_PRO', 'TEAMS'],
  },
  {
    name: 'Language Sampling',
    description: 'Record, Transcribe, and Analyze Language Samples in Multiple Languages.',
    to: '/language-sample/',
    icon: FileAudioIconSmall,
    access: ['PAYGO', 'SLP_PRO', 'TEAMS'],
  },
  {
    name: 'Chronological Age Calculator',
    description:
      'Calculate full age - broken down into years, months, days, and cumulative age in months.',
    to: '/resources/tools/chronological-age-calculator/',
    icon: AgeCalculatorIconSmall,
    access: ['PAYGO', 'SLP_PRO', 'TEAMS'],
  },
  {
    name: 'Language Use and Exposure Calculator',
    description: 'Quickly gather information about the language use of your bilingual clients.',
    to: '/resources/tools/language-use-exposure-calculator/',
    icon: LanguageExposureIconSmall,
    access: ['PAYGO', 'SLP_PRO', 'TEAMS'],
  },
  {
    name: 'Speech Therapy Report Templates',
    description: 'Library with popular Speech Therapy Report Templates.',
    to: '/resources/speech-therapy-report-templates/',
    icon: LibraryIconSmall,
    access: ['SLP_PRO', 'TEAMS'],
  },
];

const DropdownNavItem = ({ item, selectedPlan }: any) => (
  <div
    // key={item.name}
    // to={item.to}
    className={`${
      item.access.includes(selectedPlan)
        ? 'hover:bg-gray-50 bg-white ring-1 ring-black ring-opacity-5'
        : 'bg-gray-100 border'
    } relative  -m-3 p-3 flex items-start rounded-lg shadow-lg  overflow-hidden`}
  >
    <item.icon className="flex-shrink-0 h-6 w-6 text-primary-600" aria-hidden="true" />
    <div className="ml-4">
      <p className="text-base font-medium text-gray-900">{item.name}</p>
      <p className="mt-1 text-sm text-gray-500">{item.description}</p>
      {!item.access.includes(selectedPlan) && (
        <div className="absolute right-0 top-0 h-16 w-16">
          <div className="absolute transform rotate-45 bg-green-600 text-center text-white font-semibold py-1 right-[-35px] top-[32px] w-[170px]">
            SLP PRO
          </div>
        </div>
      )}
    </div>
  </div>
);

const Tools = ({ selectedPlan }) => {
  enum Plans {
    SLP_PRO = 'SLP Pro',
    TEAMS = 'Teams',
    PAYGO = 'Pay As You Go',
  }
  return (
    <>
      <div className="flex flex-col w-full mb-12 text-center">
        {/* <h1 className="max-w-5xl text-xl font-bold leading-none tracking-tighter text-stai-1 md:text-4xl lg:text-4xl lg:max-w-7xl">
        Features
      </h1> */}
        <Headline el="h2" size="small">
          What's Included in{' '}
          <span className="underline decoration-primary">{Plans[selectedPlan]}</span>
        </Headline>
        <div>
          {selectedPlan === 'SLP_PRO' && <SlpProDetails />}
          {selectedPlan === 'PAYGO' && <PayAsYoGoDetails />}
          {selectedPlan === 'TEAMS' && <TeamsDetails />}
        </div>
      </div>

      <div className="">
        <div className="relative grid grid-cols-1 md:grid-cols-3 gap-10  px-5 py-6 sm:gap-10 sm:p-8">
          {solutions.map((item) => (
            <DropdownNavItem item={item} key={item.name} selectedPlan={selectedPlan} />
          ))}
        </div>
      </div>
    </>
  );
};

const SlpProDetails = () => {
  return (
    <>
      <Lead>Reduce your workload as an individual provider</Lead>
      <div className="max-w-3xl mt-4 mx-auto text-left">
        <ul className="list-disc list-inside my-4 pl-2 text-base space-y-2">
          <li className="flex items-center space-x-3">
            <svg
              className="flex-shrink-0 w-3.5 h-3.5 text-green-500 dark:text-green-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 16 12"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="4.5"
                d="M1 5.917 5.724 10.5 15 1.5"
              />
            </svg>
            <p className="text-lg md:text-xl text-gray-900">
              Unlimited sending and use of parent forms
            </p>
          </li>
          <li className="flex items-center space-x-3">
            <svg
              className="flex-shrink-0 w-3.5 h-3.5 text-green-500 dark:text-green-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 16 12"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="4.5"
                d="M1 5.917 5.724 10.5 15 1.5"
              />
            </svg>
            <p className="text-lg md:text-xl text-gray-900">Unlimited language sampling</p>
          </li>
          <li className="flex items-center space-x-3">
            <svg
              className="flex-shrink-0 w-3.5 h-3.5 text-green-500 dark:text-green-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 16 12"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="4.5"
                d="M1 5.917 5.724 10.5 15 1.5"
              />
            </svg>
            <p className="text-lg md:text-xl text-gray-900">
              Effortlessly generate speech language reports
            </p>
          </li>
          <li className="flex items-center space-x-3">
            <svg
              className="flex-shrink-0 w-3.5 h-3.5 text-green-500 dark:text-green-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 16 12"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="4.5"
                d="M1 5.917 5.724 10.5 15 1.5"
              />
            </svg>
            <p className="text-lg md:text-xl text-gray-900">
              Free access to a full suite of SLP tools and report-writing templates
            </p>
          </li>
        </ul>
      </div>
    </>
  );
};

const PayAsYoGoDetails = () => {
  return (
    <>
      <Lead>
        Buy credits to create Parent/Teacher/Health forms or Language Samples and have access to a
        suite of time-saving tools
      </Lead>
      <div className="max-w-3xl mt-4 mx-auto text-left">
        <ul className="list-disc list-inside mb-4 pl-2 text-base space-y-2">
          <li className="flex items-center space-x-3">
            <svg
              className="flex-shrink-0 w-3.5 h-3.5 text-green-500 dark:text-green-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 16 12"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="4.5"
                d="M1 5.917 5.724 10.5 15 1.5"
              />
            </svg>
            <p className="text-lg md:text-xl text-gray-900">
              Flexible pricing based on your changing workload
            </p>
          </li>
          <li className="flex items-center space-x-3">
            <svg
              className="flex-shrink-0 w-3.5 h-3.5 text-green-500 dark:text-green-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 16 12"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="4.5"
                d="M1 5.917 5.724 10.5 15 1.5"
              />
            </svg>
            <p className="text-lg md:text-xl text-gray-900">
              Credits can be universally applied to language sampling or Parent/Teacher/Health forms
              in any language
            </p>
          </li>
          <li className="flex items-center space-x-3">
            <svg
              className="flex-shrink-0 w-3.5 h-3.5 text-green-500 dark:text-green-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 16 12"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="4.5"
                d="M1 5.917 5.724 10.5 15 1.5"
              />
            </svg>
            <p className="text-lg md:text-xl text-gray-900">
              Access to automated calculators, goal banks, and tools to speed up your report writing
              process
            </p>
          </li>
        </ul>
      </div>
    </>
  );
};

const TeamsDetails = () => {
  return (
    <>
      <Lead>Give your staff all the time-saving tools included in SLP Pro plus:</Lead>
      <div className="max-w-3xl mt-4 mx-auto text-left">
        <ul className="list-disc list-inside mb-4 pl-2 text-base space-y-2">
          <li className="flex items-center space-x-3">
            <svg
              className="flex-shrink-0 w-3.5 h-3.5 text-green-500 dark:text-green-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 16 12"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="4.5"
                d="M1 5.917 5.724 10.5 15 1.5"
              />
            </svg>
            <p className="text-lg md:text-xl text-gray-900">
              Monitor the status and progress of work that has been completed
            </p>
          </li>
          <li className="flex items-center space-x-3">
            <svg
              className="flex-shrink-0 w-3.5 h-3.5 text-green-500 dark:text-green-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 16 12"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="4.5"
                d="M1 5.917 5.724 10.5 15 1.5"
              />
            </svg>
            <p className="text-lg md:text-xl text-gray-900">Track and assign evaluations</p>
          </li>
          <li className="flex items-center space-x-3">
            <svg
              className="flex-shrink-0 w-3.5 h-3.5 text-green-500 dark:text-green-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 16 12"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="4.5"
                d="M1 5.917 5.724 10.5 15 1.5"
              />
            </svg>
            <p className="text-lg md:text-xl text-gray-900">Add and remove team members</p>
          </li>
        </ul>
      </div>
    </>
  );
};
