import React from 'react';
const InstructionsVideo = ({ videoSrcURL, videoTitle, thumbnailSrc, ...props }) => (
  <div className="mb-8">
    <video controls className="rounded-md mx-auto border" poster={thumbnailSrc}>
      <source src={videoSrcURL} type="video/mp4" />
    </video>
    {/*     
    <iframe
        src={videoSrcURL}
        title={videoTitle}
        width="560"
        height="315"
        className="rounded-md mx-auto"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
    /> */}
  </div>
);
export default InstructionsVideo;
