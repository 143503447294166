import React from 'react';
import { useState } from 'react';
import EmailReportCTA from './EmailReportCTA';

const ParentNoSchoolInput = ({ sampleReportUrl = '' }) => {
  const [parentInput, setParentInput] = useState({
    speaking: {
      weekdayMornings: 50,
      weekdayDuringDay: 50,
      weekdayAfternoonsEvenings: 50,
      weekdayNights: 50,
      weekendDays: 50,
    },
    listening: {
      weekdayMornings: 50,
      weekdayDuringDay: 50,
      weekdayAfternoonsEvenings: 50,
      weekdayNights: 50,
      weekendDays: 50,
    },
  });

  const areas = [
    { key: 'weekdayMornings', label: 'Weekday Mornings' },
    { key: 'weekdayDuringDay', label: 'Weekday During the Day' },
    { key: 'weekdayAfternoonsEvenings', label: 'Weekday Afternoons/Evenings' },
    { key: 'weekdayNights', label: 'Weekday Nights' },
    { key: 'weekendDays', label: 'Weekend Days' },
  ];
  const handleParentInputChange = (event, type, category) => {
    setParentInput({
      ...parentInput,
      [type]: {
        ...parentInput[type],
        [category]: parseInt(event.target.value),
      },
    });
  };

  const renderParentLabel = (value, type) => {
    const speaks =
      parentInput[type][value] === 0
        ? 'Speaks only in Home Language'
        : parentInput[type][value] === 100
        ? 'Speaks only in English'
        : 'Speaks English';
    const listens =
      parentInput[type][value] === 0
        ? 'Listens only in Home Language'
        : parentInput[type][value] === 100
        ? 'Listens only in English'
        : 'Listens to English';

    return type === 'speaking' ? speaks : listens;
  };

  const calculateTotalHours = (type) => {
    const hoursWeekdayMornings = (10 * parentInput[type].weekdayMornings) / 100;
    const hoursWeekdayDuringDay = (40 * parentInput[type].weekdayDuringDay) / 100;
    const hoursWeekdayAfternoonsEvenings = (15 * parentInput[type].weekdayAfternoonsEvenings) / 100;
    const hoursWeekdayNights = (15 * parentInput[type].weekdayNights) / 100;
    const hoursWeekendDays = (32 * parentInput[type].weekendDays) / 100;

    const totalHoursInEnglish =
      hoursWeekdayMornings +
      hoursWeekdayDuringDay +
      hoursWeekdayAfternoonsEvenings +
      hoursWeekdayNights +
      hoursWeekendDays;
    const totalPercentageInEnglish = (totalHoursInEnglish / 112) * 100; // Total hours at home is 112

    const totalHoursByArea = {
      weekdayMornings: hoursWeekdayMornings.toFixed(2),
      weekdayDuringDay: hoursWeekdayDuringDay.toFixed(2),
      weekdayAfternoonsEvenings: hoursWeekdayAfternoonsEvenings.toFixed(2),
      weekdayNights: hoursWeekdayNights.toFixed(2),
      weekendDays: hoursWeekendDays.toFixed(2),
      overall: totalHoursInEnglish.toFixed(2),
      percentageInEnglish: totalPercentageInEnglish.toFixed(0),
      percentageInHomeLanguage: (100 - totalPercentageInEnglish).toFixed(0),
    };

    return totalHoursByArea;
  };

  const totalSpeakingHours = calculateTotalHours('speaking');
  const totalListeningHours = calculateTotalHours('listening');

  // Calculating averages
  const averageInputOutputEnglish = (
    (parseFloat(totalSpeakingHours.percentageInEnglish) +
      parseFloat(totalListeningHours.percentageInEnglish)) /
    2
  ).toFixed(0);
  const averageInputOutputHomeLanguage = (100 - parseFloat(averageInputOutputEnglish)).toFixed(0);

  const results = {
    input: parentInput,
    totalSpeakingHours: totalSpeakingHours,
    totalListeningHours: totalListeningHours,
    averageInputOutputEnglish: averageInputOutputEnglish,
    averageInputOutputHomeLanguage: averageInputOutputHomeLanguage,
  };

  return (
    <div>
      <h2 className="text-xl font-semibold mb-2">Parent Input - Does Not Attend School</h2>
      <div className="md:grid md:grid-cols-2 md:gap-x-10 ">
        <div>
          <h3 className="text-lg font-semibold mb-2">Speaking</h3>
          {areas.map((area, index) => (
            <div key={index}>
              <label className="block mb-2">{area.label}</label>
              <input
                type="range"
                min="0"
                max="100"
                value={parentInput.speaking[area.key]}
                onChange={(e) => handleParentInputChange(e, 'speaking', area.key)}
                className="w-full"
              />
              <p className="text-center">
                {parentInput.speaking[area.key]}% - {renderParentLabel(area.key, 'speaking')}
              </p>
            </div>
          ))}
          {/* Add similar inputs for weekdayDuringDay, weekdayAfternoonsEvenings, weekdayNights, and weekendDays */}
          <table className="w-full mb-4 border border-collapse border-gray-800 hidden ">
            <thead>
              <tr className="bg-gray-200">
                <th className="border border-gray-800 px-4 py-2">Area</th>
                <th className="border border-gray-800 px-4 py-2">Total Hours in English</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border border-gray-800 px-4 py-2">Weekday Mornings</td>
                <td className="border border-gray-800 px-4 py-2">
                  {totalSpeakingHours.weekdayMornings} hours
                </td>
              </tr>
              <tr>
                <td className="border border-gray-800 px-4 py-2">Weekday During Day</td>
                <td className="border border-gray-800 px-4 py-2">
                  {totalSpeakingHours.weekdayDuringDay} hours
                </td>
              </tr>
              <tr>
                <td className="border border-gray-800 px-4 py-2">Weekday Afternoons/Evenings</td>
                <td className="border border-gray-800 px-4 py-2">
                  {totalSpeakingHours.weekdayAfternoonsEvenings} hours
                </td>
              </tr>
              <tr>
                <td className="border border-gray-800 px-4 py-2">Weekday Nights</td>
                <td className="border border-gray-800 px-4 py-2">
                  {totalSpeakingHours.weekdayNights} hours
                </td>
              </tr>
              <tr>
                <td className="border border-gray-800 px-4 py-2">Weekend Days</td>
                <td className="border border-gray-800 px-4 py-2">
                  {totalSpeakingHours.weekendDays} hours
                </td>
              </tr>
              <tr>
                <td className="border border-gray-800 px-4 py-2">Total Overall Hours in English</td>
                <td className="border border-gray-800 px-4 py-2">
                  {totalSpeakingHours.overall} hours
                </td>
              </tr>
              <tr>
                <td className="border border-gray-800 px-4 py-2">
                  Percentage of Time Speaking English at Home
                </td>
                <td className="border border-gray-800 px-4 py-2">
                  {totalSpeakingHours.percentageInEnglish}%
                </td>
              </tr>
              <tr>
                <td className="border border-gray-800 px-4 py-2">
                  Percentage of Time Speaking in Home Language at Home
                </td>
                <td className="border border-gray-800 px-4 py-2">
                  {totalSpeakingHours.percentageInHomeLanguage}%
                </td>
              </tr>
              <tr>
                <td className="border border-gray-800 px-4 py-2" colSpan={2}>
                  Assumptions: 168 hours in a week, 8 hours per day sleeping, 40 hours at school,
                  and 72 hours outside of school. We assume students spend 3 hours per day with
                  students and with teachers for 5 days a week, bringing a total of 15 hours per
                  week. We assume students spend 2 hours a day with other school personnel for 5
                  days a week, bringing the total to 10 hours per week with other school personnel.
                  In total, the weekly hours combined to be 40 hours.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <h3 className="text-lg font-semibold mb-2">Listening</h3>
          {areas.map((area, index) => (
            <div key={index}>
              <label className="block mb-2">{area.label}</label>
              <input
                type="range"
                min="0"
                max="100"
                value={parentInput.listening[area.key]}
                onChange={(e) => handleParentInputChange(e, 'listening', area.key)}
                className="w-full"
              />
              <p className="text-center">
                {parentInput.listening[area.key]}% - {renderParentLabel(area.key, 'listening')}
              </p>
            </div>
          ))}

          <table className="w-full mb-4 border border-collapse border-gray-800 hidden">
            <thead>
              <tr className="bg-gray-200">
                <th className="border border-gray-800 px-4 py-2">Area</th>
                <th className="border border-gray-800 px-4 py-2">Total Hours in English</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border border-gray-800 px-4 py-2">Weekday Mornings</td>
                <td className="border border-gray-800 px-4 py-2">
                  {totalListeningHours.weekdayMornings} hours
                </td>
              </tr>
              <tr>
                <td className="border border-gray-800 px-4 py-2">Weekday During Day</td>
                <td className="border border-gray-800 px-4 py-2">
                  {totalListeningHours.weekdayDuringDay} hours
                </td>
              </tr>
              <tr>
                <td className="border border-gray-800 px-4 py-2">Weekday Afternoons/Evenings</td>
                <td className="border border-gray-800 px-4 py-2">
                  {totalListeningHours.weekdayAfternoonsEvenings} hours
                </td>
              </tr>
              <tr>
                <td className="border border-gray-800 px-4 py-2">Weekday Nights</td>
                <td className="border border-gray-800 px-4 py-2">
                  {totalListeningHours.weekdayNights} hours
                </td>
              </tr>
              <tr>
                <td className="border border-gray-800 px-4 py-2">Weekend Days</td>
                <td className="border border-gray-800 px-4 py-2">
                  {totalListeningHours.weekendDays} hours
                </td>
              </tr>
              <tr>
                <td className="border border-gray-800 px-4 py-2">Total Overall Hours in English</td>
                <td className="border border-gray-800 px-4 py-2">
                  {totalListeningHours.overall} hours
                </td>
              </tr>
              <tr>
                <td className="border border-gray-800 px-4 py-2">
                  Percentage of Time Listening English at Home
                </td>
                <td className="border border-gray-800 px-4 py-2">
                  {totalListeningHours.percentageInEnglish}%
                </td>
              </tr>
              <tr>
                <td className="border border-gray-800 px-4 py-2">
                  Percentage of Time Listening in Home Language at Home
                </td>
                <td className="border border-gray-800 px-4 py-2">
                  {totalListeningHours.percentageInHomeLanguage}%
                </td>
              </tr>
              <tr>
                <td className="border border-gray-800 px-4 py-2" colSpan={2}>
                  Assumptions: 168 hours in a week, 8 hours per day sleeping, 40 hours at school,
                  and 72 hours outside of school. We assume students spend 3 hours per day with
                  students and with teachers for 5 days a week, bringing a total of 15 hours per
                  week. We assume students spend 2 hours a day with other school personnel for 5
                  days a week, bringing the total to 10 hours per week with other school personnel.
                  In total, the weekly hours combined to be 40 hours.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/* Summary table */}
      <div className="mt-10">
        <h2 className="text-xl font-semibold mb-2">Summary</h2>
        <table className="w-full mb-4 border border-collapse border-gray-800">
          <caption className="caption-top ">
            The parent survey indicated that the child is not yet in school, and that they they hear
            and use English {averageInputOutputEnglish}% of the time and hear and use (home lanuage){' '}
            {averageInputOutputHomeLanguage}% of the time.
          </caption>

          <thead>
            <tr className="bg-gray-200">
              <th className="border border-gray-800 px-4 py-2">Category</th>
              <th className="border border-gray-800 px-4 py-2">Average Percentage</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border border-gray-800 px-4 py-2">Overall Input/Output in English</td>
              <td className="border border-gray-800 px-4 py-2">{averageInputOutputEnglish}%</td>
            </tr>
            <tr>
              <td className="border border-gray-800 px-4 py-2">
                Overall Input/Output in Home Language
              </td>
              <td className="border border-gray-800 px-4 py-2">
                {averageInputOutputHomeLanguage}%
              </td>
            </tr>
            <tr>
              <td className="border border-gray-800 px-4 py-2" colSpan={2}>
                The parent survey indicated that the child is not yet in school, and that they they
                hear and use English {averageInputOutputEnglish}% of the time and hear and use (home
                lanuage) {averageInputOutputHomeLanguage}% of the time.
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Capture Email CTA */}
      <EmailReportCTA
        results={results}
        selectedOption={'parentInputDoesNotAttendSchool'}
        sampleUrl={sampleReportUrl}
      />
    </div>
  );
};

export default ParentNoSchoolInput;
