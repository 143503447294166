import React from 'react';
import { Headline, TextLink as Link } from '../../components';

const FAQ_CONTENT = [
  {
    question: `How will Evalubox save me time in my report writing?`,
    answer: () =>
      `Evalubox uses electronic forms that automatically integrate into your report template. 
      No more going through a pile of intake forms, health information, and parent and teacher questionnaires to determine where to put the information in your report. 
      When a parent or teacher completes a form (or you complete it with them) the information automatically goes to the correct section of the report. 
      Evalubox also has forms for to include Assessment Impressions, Recommendations, and Goals that appear in your report as well.`,
  },
  {
    question: `What if the template provided by Evalubox does not meet the needs of my school?`,
    answer: () =>
      `Evalubox provides the ultimate flexibility for your templates. 
      You can use the templates provided, modify the ones provided, or create your own from scratch.`,
  },
  {
    question: `What is an evaluation record and what is an evaluation report?`,
    answer: () =>
      `An evaluation record is any evaluation that you have initiated by entering a student's or client’s name.  
      An evaluation is counted as a report when you enter your assessment impressions, make recommendations, or create a report.`,
  },
  {
    question: `Will I still have to have different template versions for different genders?`,
    answer: () => (
      <p>
        No, you only need one version of your template. In the intake information section you select
        the pronouns you want to use throughout your report for each student.
      </p>
    ),
  },
  {
    question: `Once I create a report, am I able to edit any of the information in the report that is generated?`,
    answer: () => <p>Yes, the report that is generated is fully editable.</p>,
  },
  {
    question: `Can I have more than one template in Evalubox?`,
    answer: () => (
      <p>
        Yes, for example, many of us have different templates for different ages. After entering
        your child’s information, you choose the template you want to use.
      </p>
    ),
  },
  {
    question: `Can we have all of the evaluations for our district on one system or does each SLP have a separate system?`,
    answer: () => (
      <p>
        You can do it either way. Individual SLPs can have their own account or a district can have
        an account with all evaluations in one place. The system can be set so that SLPs see only
        the evaluations that have been assigned to them and administrators can see all of the
        evaluations.
      </p>
    ),
  },
  {
    question: `What if I don’t have all of the information I need in my forms but I want to go ahead and get started on my report?`,
    answer: () => (
      <p>
        You can go ahead and get started on your report. Any fields that do not have information in
        them will appear in the generated report as a yellow placeholder with the field name inside
        of it. If you are waiting on specific information, you will want to leave the placeholder
        there. If you do not need the information in your report, simply delete the placeholder.
        Once you do put the information in your forms and want to add it to your report, just click
        Update Report and the new information in the forms will appear in your report.
      </p>
    ),
  },
  {
    question: `What if I finalize a report in PDF format and then someone asks me to change something. Can I do that?`,
    answer: () => (
      <p>
        Yes, you can always go back and change things. Once you do, you just need to generate a new
        PDF after you’ve made your changes.
      </p>
    ),
  },
  {
    question: `Can I bring my existing templates into Evalubox?`,
    answer: () => (
      <p>
        Yes, you can copy and paste your template into Evalubox and then add your place holders. For
        example, if you used to use Find and Replace for Student, Teacher, and pronouns, you simply
        highlight the word you want to replace with a placeholder, then use the dropdown menus to
        find and click on the placeholder you want to use. The dropdown menu categories include:
        Evaluation, Student, Examiner, Parent Report, Teacher Report, Health, Assessment Tools,
        Language Exposure, Impressions after assessment, and Recommendations. Evalubox makes
        creating and using existing templates easy.
      </p>
    ),
  },
  {
    question: `What do I do if I need help?`,
    answer: () =>
      `We provide customer support in multiple ways. 
      We offer regular virtual meeting calls where you can join us to get questions answered or just learn more about the things you can accomplish in Evalubox. 
      We do webinars to teach you more about the tool. 
      We also have an in-app chat feature for when you encounter a question in the middle of an evaluation. 
      And, you can always send us an email. We deeply value customer feedback and prioritize your requests in our roadmap.`,
  },
  {
    question: `How is Evalubox compliant with HIPAA and FERPA requirements?`,
    answer: () => (
      <p>
        The Evalubox system was designed with end-to-end encryption, password protection, and
        automatic logout when idle to comply with HIPAA and FERPA standards. For our customers that
        are HIPAA-covered entities, we maintain HIPAA-compliant business associate agreements. For
        our customers and service providers in the schools, we are subject to laws specific to
        students, such as the Family Educational Rights and Privacy Act. We designed a high security
        system to keep patient and client information safe. Learn more about Learn more about{' '}
        <Link to="/security/" color="primary">
          how we protect the privacy of student evaluation records
        </Link>
        .
      </p>
    ),
  },
];

const freeTrialFaqs = [
  {
    question: `What happens at the end of my free trial?`,
    answer: () => (
      <p>
        Your subscription will automatically convert to a paid subscription at the end of your
        trial. You can cancel at any time prior to the end of your trial on the Manage Account page
        so you will not be charged.
      </p>
    ),
  },
  {
    question: `Can I start a trial without a credit card?`,
    answer: () => <p>No, we require a valid credit card on file in order to start a trial.</p>,
  },
  {
    question: `How will you bill me?`,
    answer: () => (
      <p>
        Your subscription will be billed annually at the end of your trial and will automatically be
        renewed on an annual basis.
      </p>
    ),
  },
];

const Item = ({ question, answer }) => (
  <div>
    <dt className="font-semibold text-gray-900">{question}</dt>
    <dd className="mt-3 text-gray-500">{answer()}</dd>
  </div>
);

const FAQs = ({
  faqs = FAQ_CONTENT,
  heading = 'Frequently asked questions',
  freeTrial = false,
}: any) => (
  <div className="bg-white">
    <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
      <div className="max-w-2xl lg:mx-auto lg:text-center">
        <Headline el="h2" size="medium">
          {heading}
        </Headline>
      </div>

      <div className="mt-20 px-8">
        <dl className="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-10">
          {freeTrial
            ? freeTrialFaqs.map((faq) => <Item {...faq} key={faq.question} />)
            : faqs.map((faq) => <Item {...faq} key={faq.question} />)}
        </dl>
      </div>
    </div>
  </div>
);

export default FAQs;
