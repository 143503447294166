import React from 'react';
import { Button, Headline, Lead } from '../..';
import CatalogScreenshot from '../../../../static/images/landing/screenshot-catalog.png';
import HeroForm from './HeroForm';
import { LOGOS } from '../CustomerLogoCloud';
import has from 'lodash/has';
import ImageCarousel from '../../ImageCarousel';
import TemplatePic from '../../../../static/images/logos/TemplatePicEx.png';
import NamePronounExampleVideo from '../../../../static/images/logos/WhatIsEvalubox.mp4';
import RequestAccessForm from './RequestAccessForm';

import ShareParentFormLanguageToggle from '../../../../content/assets/home/forms/ShareMultiLingualForms.png';
import FormsLaptopMockup from '../../../../content/assets/home/forms/FormsLaptopMockup.mp4';
import InstructionsVideo from '../../LanguageProficiency/InstructionsVideo';
const FormsCenteredHero = () => {
  const whiteLogos = [...LOGOS].filter(({ src }) => has(src, 'white')).slice(0, 4);

  return (
    <>
      <div className="relative landing-hero-background overflow-x-hidden pb-10" id="forms-hero">
        <div className="relative pb-0">
          <main className="container mx-auto max-w-8xl sm:mt-20 ">
            <div className="h-full w-full grid gap-6 p-4 xl:p-6 grid-cols-1 xl:grid-cols-2">
              <HeroDescription />
              <HeroVideo />
            </div>
          </main>
        </div>
      </div>

      <script async src="https://player.vimeo.com/api/player.js" />
    </>
  );
};

export default FormsCenteredHero;

const HeroDescription = () => {
  return (
    <div className="xl:col-span-1 xl:max-w-2xl h-full w-full">
      <Headline size="medium" className="text-inherit leading-snug">
        Effortlessly send secure digital speech therapy referral forms to parents, teachers, and
        nurses.
      </Headline>
      <br />
      <Lead size="medium" className="text-inherit leading-snug pt-4">
        <ul className="list-disc list-inside mb-4 pl-2 text-base space-y-2">
          <li className="flex items-center space-x-3">
            <svg
              className="flex-shrink-0 w-3.5 h-3.5 text-green-500 dark:text-green-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 16 12"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="4.5"
                d="M1 5.917 5.724 10.5 15 1.5"
              />
            </svg>
            <p className="text-lg md:text-xl text-gray-900">Customize your questionnaires</p>
          </li>
          <li className="flex items-center space-x-3">
            <svg
              className="flex-shrink-0 w-3.5 h-3.5 text-green-500 dark:text-green-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 16 12"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="4.5"
                d="M1 5.917 5.724 10.5 15 1.5"
              />
            </svg>
            <p className="text-lg md:text-xl text-gray-900">
              Send to multiple teachers or parents{' '}
            </p>
          </li>
          <li className="flex items-center space-x-3">
            <svg
              className="flex-shrink-0 w-3.5 h-3.5 text-green-500 dark:text-green-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 16 12"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="4.5"
                d="M1 5.917 5.724 10.5 15 1.5"
              />
            </svg>
            <p className="text-lg md:text-xl text-gray-900">Complete and submit from any device </p>
          </li>

          <li className="flex items-center space-x-3">
            <svg
              className="flex-shrink-0 w-3.5 h-3.5 text-green-500 dark:text-green-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 16 12"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="4.5"
                d="M1 5.917 5.724 10.5 15 1.5"
              />
            </svg>
            <p className="text-lg md:text-xl text-gray-900">
              Get parent forms and responses in 16 languages{' '}
            </p>
          </li>
          <li className="flex items-center space-x-3">
            <svg
              className="flex-shrink-0 w-3.5 h-3.5 text-green-500 dark:text-green-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 16 12"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="4.5"
                d="M1 5.917 5.724 10.5 15 1.5"
              />
            </svg>
            <p className="text-lg md:text-xl text-gray-900">Ensure HIPAA/FERPA compliance</p>
          </li>
        </ul>
      </Lead>

      <div className="my-5 md:mt-8">
        <div className="mt-8 flex justify-left">
          <div className="inline-flex">
            <Button
              link={true}
              to="https://app.evalubox.com/register?intent=forms&utm_medium=display&utm_source=forms&utm_campaign=forms_to_evalubox_register&utm_content=cta-hero-try-it-for-free"
              color="primary"
              text="Try it For Free"
              size="large"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const HeroVideo = () => {
  return (
    <div className="xl:col-span-1 mx-auto h-full w-full">
      <video
        preload="auto"
        className="mx-auto rounded-md border object-cover"
        loop
        controls
        poster={
          'https://cdn.app.evalubox.com/marketing-site/forms-product/landing-videos/forms+overview.png'
        }
      >
        <source
          src={
            'https://cdn.app.evalubox.com/marketing-site/forms-product/landing-videos/speech+referral+forms+overview+video.mp4'
          }
          type="video/mp4"
        />
        your browser is not supported!
      </video>
      <Lead size="medium" className="text-inherit leading-snug pt-4">
        Gathering evaluation information in multiple languages has never been faster, safer, or
        easier. Spend your time evaluating and working with the child or client.
      </Lead>
    </div>
  );
};
