import React from 'react';
import lodashEscape from 'lodash/escape';
import kebabCase from 'lodash/kebabCase';
import { useCopyToClipboard } from '../hooks/useCopyToClipboard';

const TextBlock = ({ language, code, intro, sectionId, bolded = false, personal = false }: any) => {
  const [copiedText, copy] = useCopyToClipboard();

  const handleCopy = (el: any) => {
    const text = el.target.innerText;
    copy(text)
      .then(() => {})
      .catch((error) => {});
  };

  return (
    <div id={sectionId && `section-${sectionId}`} className="my-4">
      {intro && intro !== '' && (
        <h3
          className="prose-xl prose-primary py-2"
          dangerouslySetInnerHTML={{ __html: intro.trim() }}
        />
      )}
      {bolded && bolded !== '' && (
        <div
          className="prose-xl prose-primary font-bold py-2"
          dangerouslySetInnerHTML={{ __html: bolded.trim() }}
        />
      )}
      {personal && personal !== '' && (
        <div
          className="prose-xl prose-primary py-2"
          dangerouslySetInnerHTML={{ __html: personal.trim() }}
        />
      )}

      {code && code !== '' && (
        <div className="gatsby-highlight" data-language={language}>
          <div
            className={`language-${language}`}
            onClick={(el) => handleCopy(el)}
            dangerouslySetInnerHTML={{
              __html: code.trim(),
            }}
          />
        </div>
      )}
    </div>
  );
};

TextBlock.generateKey = ({ code, intro }: any) => {
  if (intro && intro !== '') {
    return `key-${kebabCase(intro)}`;
  }

  return `key-${kebabCase(code)}`;
};

export default TextBlock;
