exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-faqs-tsx": () => import("./../../../src/pages/faqs.tsx" /* webpackChunkName: "component---src-pages-faqs-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-language-sample-index-tsx": () => import("./../../../src/pages/language-sample/index.tsx" /* webpackChunkName: "component---src-pages-language-sample-index-tsx" */),
  "component---src-pages-pricing-index-tsx": () => import("./../../../src/pages/pricing/index.tsx" /* webpackChunkName: "component---src-pages-pricing-index-tsx" */),
  "component---src-pages-request-demo-index-tsx": () => import("./../../../src/pages/request-demo/index.tsx" /* webpackChunkName: "component---src-pages-request-demo-index-tsx" */),
  "component---src-pages-request-demo-thank-you-tsx": () => import("./../../../src/pages/request-demo/thank-you.tsx" /* webpackChunkName: "component---src-pages-request-demo-thank-you-tsx" */),
  "component---src-pages-resources-speech-therapy-report-templates-tsx": () => import("./../../../src/pages/resources/speech-therapy-report-templates.tsx" /* webpackChunkName: "component---src-pages-resources-speech-therapy-report-templates-tsx" */),
  "component---src-pages-resources-tools-chronological-age-calculator-tsx": () => import("./../../../src/pages/resources/tools/chronological-age-calculator.tsx" /* webpackChunkName: "component---src-pages-resources-tools-chronological-age-calculator-tsx" */),
  "component---src-pages-resources-tools-index-tsx": () => import("./../../../src/pages/resources/tools/index.tsx" /* webpackChunkName: "component---src-pages-resources-tools-index-tsx" */),
  "component---src-pages-resources-tools-language-use-exposure-calculator-tsx": () => import("./../../../src/pages/resources/tools/language-use-exposure-calculator.tsx" /* webpackChunkName: "component---src-pages-resources-tools-language-use-exposure-calculator-tsx" */),
  "component---src-pages-security-index-tsx": () => import("./../../../src/pages/security/index.tsx" /* webpackChunkName: "component---src-pages-security-index-tsx" */),
  "component---src-pages-speech-referral-form-index-tsx": () => import("./../../../src/pages/speech-referral-form/index.tsx" /* webpackChunkName: "component---src-pages-speech-referral-form-index-tsx" */),
  "component---src-pages-why-evalubox-tsx": () => import("./../../../src/pages/why-evalubox.tsx" /* webpackChunkName: "component---src-pages-why-evalubox-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/BlogPost.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-legal-notice-tsx": () => import("./../../../src/templates/LegalNotice.tsx" /* webpackChunkName: "component---src-templates-legal-notice-tsx" */),
  "component---src-templates-smart-template-tsx": () => import("./../../../src/templates/SmartTemplate.tsx" /* webpackChunkName: "component---src-templates-smart-template-tsx" */)
}

