import React from 'react';
import { graphql } from 'gatsby';

import { SEO, Headline, SensibleNavList } from '../../components';
import { MailIcon } from '@heroicons/react/outline';
import classnames from 'classnames';
const PurchaseSuccessPage = ({
  data: {
    site: {
      siteMetadata: { title },
    },
  },
}: any) => (
  <>
    <div className="bg-white">
      <main className="max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto py-16 sm:py-24">
          <div className="text-center">
            <p className="text-sm font-semibold text-indigo-600 uppercase tracking-wide">
              Success 🎉
            </p>

            <div className="mt-2">
              <Headline size="medium" el="h2">
                Thank you for requesting a demo
              </Headline>
            </div>
          </div>
          {/* <div className="mt-4 text-center">
            <div className="mb-2">
              <Lead>
                Get ready to take your report writing to a whole new level.
              </Lead>
            </div>
          </div> */}

          <div className="max-w-xl px-5 text-center mx-auto">
            <MailIcon
              className={classnames({
                'h-6 w-6': false,
                'h-52 w-52 mx-auto text-primary': true,
              })}
              aria-hidden="true"
            />
            <h2 className="mb-2 text-[42px] font-bold text-zinc-800">Check your inbox</h2>
            <p className="mb-6 text-lg text-zinc-500">
              We’ve sent you a link to your email address that you'll use to schedule a demo date
              and time that works best for you.
            </p>
            <SensibleNavList lead="In the meantime, you can learn more about Evalubox" />
          </div>
        </div>
      </main>
    </div>
  </>
);

export default PurchaseSuccessPage;

export const Head = () => (
  // Remove this from SEO
  <>
    <SEO
      title={`Welcome to Evalubox`}
      description="Success page which is shown to people who become Evalubox customers"
    />
  </>
);

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
