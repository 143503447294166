import React, { useState } from 'react';
import { Page } from '../../../components/layouts';
import { Button, Headline, Lead, SEO, TextLink } from '../../../components';
import { FooterCTA, SimpleCenteredHeading } from '../../../components/landing';
import { ToolsFooterCTA } from '../../../components/landing/FooterCTA';

import ChronologicalAgeCalculatorImage from '../../../../content/assets/tools/age-calculator/age-chronological-calculator.jpeg';
import ChronologicalAgeCalculatorBanner from '../../../../content/assets/tools/age-calculator/chronological-age-calculator-banner.jpeg';
import ChronologicalAgeCalculatorPearson from '../../../../content/assets/tools/age-calculator/chronological-age-calculator-pearson.png';
import AgeCalculatorHeader from '../../../components/LanguageProficiency/AgeCalculatorHeader';
import ResourcesPage from '../../../components/layouts/ResourcesPage';

const ChronologicalAgeCalculator = () => {
  const [dob, setDob] = useState('');
  const [endDate, setEndDate] = useState(new Date().toISOString().slice(0, 10));
  const [results, setResults] = useState({
    fullAge: '',
    ageInYears: '',
    ageInMonths: '',
  });

  const calculateAge = () => {
    const birthDate = new Date(dob);
    const todayDate = new Date(endDate);

    const diffInMonths =
      (todayDate.getFullYear() - birthDate.getFullYear()) * 12 +
      todayDate.getMonth() -
      birthDate.getMonth();

    const fullAge = `${Math.floor(diffInMonths / 12)};${diffInMonths % 12}`;

    setResults({
      fullAge,
      ageInYears: Math.floor(diffInMonths / 12).toString(),
      ageInMonths: (diffInMonths % 12).toString(),
    });
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <>
      <ResourcesPage titleDivide={true}>
        <div className="mx-auto max-w-screen-xl px-4  sm:px-6 lg:px-8 ">
          <AgeCalculatorHeader headline="Chronological Age Calculator" lead={''} attribution="" />

          {/* <h2 className="text-center py-4 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Chronological Age Calculator
          </h2> */}

          <p className="pt-8 text-gray-700 text-lg">
            We’ve all been there! You do the long and difficult work of scheduling a speech language
            evaluation, finishing your testing, and sitting down to write your report. Then comes
            the need for one extremely important piece of information: calculating chronological age
            for the child! This weird, two numeral piece of data can mean the difference between
            correctly or incorrectly diagnosing a child even after all your other hard work! That’s
            why we put together this free chronological age calculator that is fast and easy to use.
          </p>
          <div className="py-8">
            <strong className="mt-4 text-gray-700 text-lg">
              A chronological age calculator enables a professional to enter a testing date and date
              of birth to produce a child’s chronological age in years and months (e.g. 7 years and
              10 months or 7;10). If we don't get the child's chronological age correct, we'll use
              the wrong norms, use incorrect developmental information, and potentially misdiagnose
              someone.
            </strong>
          </div>

          <div>
            <div className="max-w-4xl mx-auto text-center pb-2">
              <div className="mt-2 py-3 border-y">
                <h3 className="font-base text-xl py-4 text-center tracking-tight text-gray-900 sm:text-2xl">
                  Simply enter the child’s date of birth, today’s date or the date of testing, then
                  click button Calculate Age to view results.
                </h3>
              </div>
            </div>
          </div>
          <ChronologicalAgeCalculatorComponent />
          <ChronologicalInlineCTA />
          <h2 className="text-center text-xl font-bold tracking-tight text-gray-900 sm:text-2xl">
            Just the chronological age calculator please! No frills or unneeded information!
          </h2>

          <p className="mt-4 text-gray-700 text-md">
            With the many students and clinical fellows that I have supervised, I have to say that
            getting this number wrong is one of the most common errors I need to correct. Even me as
            a seasoned vet, I have had to triple check that it is accurate.
          </p>
          <p className="mt-4 text-gray-700 text-lg">
            The problem is that many of the test publisher’s calculators, like the Pearson
            chronological age calculator, move and I lose the bookmark. Either that, or they are
            broken, or have a ton of other unneeded information. I just need a single number that I
            can trust. In response to the discontinuation of the chronological age calculator from
            Pearson, we built this free chronological age calculator as a reliable alternative. And
            why will it be updated and always easy to find? Because we use this chronological age
            calculator for testing every week!
          </p>

          <div className="flex align-center justify-center py-8 sm:px-0 rounded-lg">
            <picture className="rounded-lg">
              {/* <source srcSet={illustration.webp} type="image/webp" /> */}
              {/* <source srcSet={illustration.png} type="image/png" /> */}
              <img
                className="rounded-lg"
                src={ChronologicalAgeCalculatorBanner}
                alt={'chronological age calculator'}
              />
            </picture>
          </div>

          <HowToUseCalculator />
          <WhyChronologicalAgeCalculator />
          <WhyTestDevelopers />
          <ChronologicalFooterCTA />
        </div>
      </ResourcesPage>
    </>
  );
};

export default ChronologicalAgeCalculator;

export const Head = () => (
  <>
    <SEO
      title={`Finally! A Quick and Accurate Chronological Age Calculator`}
      description="Easily calculate a child’s age in years and months with this free chronological age calculator."
    />
  </>
);

export const ChronologicalInlineCTA = () => (
  <div className="bg-white">
    <div className="max-w-4xl mx-auto text-center pb-12">
      <div className="mt-6 py-4 border-y">
        <h3 className="font-bold text-2xl py-4 text-center tracking-tight text-gray-900 sm:text-3xl">
          Did you know that this is just one of seven tools helping SLPs write reports and do their
          job faster?
        </h3>
        <TextLink
          to="/?utm_medium=display&utm_source=age-calculator&utm_campaign=age_calculator_to_evalubox_home&utm_content=text_active"
          className="font-bold text-xl"
          color="primary"
        >
          {/* <span className="text-orange-500">←</span>  */}
          Learn more
          <span className="text-orange-500 px-1">→</span>
        </TextLink>
      </div>
    </div>
  </div>
);

export const ChronologicalFooterCTA = () => (
  <div className="bg-white">
    <div className="max-w-7xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-32 lg:px-8">
      {/* <div className="pb-3">
        <Headline el="h2">
          <span className="block">Start Using Evalubox Today.</span>
        </Headline>
        <Lead>
        When you're crunched for time, analyzing and writing a comprehensive report can seem overwhelming! 
        </Lead>
      </div> */}

      <SimpleCenteredHeading
        headline="Start Using Evalubox Today."
        lead="Write Comprehensive Speech Language Reports in a Fraction of the Time!"
      />

      <div className="lg:mt-4 mx-auto max-w-2xl">
        {/* <RequestAccessForm from={"home-page"} showTC={true} /> */}
        <div className="inline-flex">
          <Button
            link={true}
            to="/?utm_medium=display&utm_source=age-calculator&utm_campaign=age_calculator_to_evalubox_home&utm_content=footer-cta-learn-more"
            color="primary"
            text="Learn More"
            size="large"
          />
        </div>

        <div className="ml-3 inline-flex">
          <Button
            link={true}
            to="/request-demo/?utm_medium=display&utm_source=age-calculator&utm_campaign=age_calculator_to_evalubox_demo&utm_content=footer-cta-request-demo"
            color="inset"
            text="Request Demo"
            size="large"
          />
        </div>
      </div>
    </div>
  </div>
);

const ChronologicalAgeCalculatorComponent = () => {
  const [dob, setDob] = useState('');
  const [endDate, setEndDate] = useState(new Date().toISOString().slice(0, 10));
  const [results, setResults] = useState({
    fullAge: '',
    ageInYears: '',
    ageInMonths: '',
    ageInDays: '',
    cumulativeAgeInMonths: '',
  });
  const [showDateError, setShowDateError] = useState(false);
  const [showCopyNotification, setShowCopyNotification] = useState(false);
  const [isDisclosureOpen, setDisclosureOpen] = useState(false);

  const toggleDisclosure = () => {
    setDisclosureOpen(!isDisclosureOpen);
  };

  // Function to determine if a year is a leap year
  const isLeapYear = (year) => {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  };

  // Array to hold the length of each month
  const monthLengths = [
    31, // January
    isLeapYear ? 29 : 28, // February
    31, // March
    30, // April
    31, // May
    30, // June
    31, // July
    31, // August
    30, // September
    31, // October
    30, // November
    31, // December
  ];

  const calculateAge = () => {
    if (!dob) {
      setShowDateError(true);
      return;
    }

    setShowDateError(false);

    const birthDate = new Date(dob);
    const todayDate = new Date(endDate);

    // Calculate difference in total days
    let totalDiffDays = Math.floor(
      (todayDate.getTime() - birthDate.getTime()) / (1000 * 60 * 60 * 24)
    );

    let years = 0;
    let months = 0;
    let days = 0;

    // Calculate years
    while (totalDiffDays >= 365) {
      if (isLeapYear(birthDate.getFullYear() + years)) {
        if (totalDiffDays >= 366) {
          totalDiffDays -= 366;
          years++;
        } else {
          break;
        }
      } else {
        totalDiffDays -= 365;
        years++;
      }
    }

    // Calculate months
    while (totalDiffDays >= monthLengths[months]) {
      totalDiffDays -= monthLengths[months];
      months++;
      if (months === 12) {
        years++;
        months = 0;
      }
    }

    // Remaining days
    days = totalDiffDays;

    // Construct the full age string as "years;months"
    const fullAge = `${years};${months};${days}`;

    // Calculate cumulative age in months
    const cumulativeAgeInMonths = years * 12 + months;

    // Set the results
    setResults({
      fullAge,
      ageInYears: years.toString(),
      ageInMonths: months.toString(),
      ageInDays: days.toString(),
      cumulativeAgeInMonths: cumulativeAgeInMonths.toString(),
    });
  };
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setShowCopyNotification(true);

    setTimeout(() => {
      setShowCopyNotification(false);
    }, 2000);
  };

  return (
    <div className="mx-auto pt-4 text-black">
      <h3 className="text-center py-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
        Chronological Age Calculator In Months and Years
      </h3>
      <div className="text-center">
        <div className="lg:flex justify-center lg:gap-x-4">
          <div className="mb-4 px-4">
            <label className="block text-gray-700 text-lg font-bold mb-2">Date of Birth</label>
            <input
              type="date"
              className="rounded border border-gray-300 px-8 py-3 md:py-4 md:text-lg md:px-10"
              value={dob}
              autoFocus
              onChange={(e) => setDob(e.target.value)}
            />
            {showDateError && (
              <p className="text-red-500 text-lg mt-2">Please select a date of birth</p>
            )}
          </div>

          <div className="mb-4 px-4">
            <label className="block text-gray-700 text-lg font-bold mb-2">Date of Testing</label>
            <input
              type="date"
              className="rounded border border-gray-300 px-8 py-3 md:py-4 md:text-lg md:px-10"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
        </div>

        <button
          className="bg-primary-600 text-white rounded-md mb-4 px-8 py-3 md:py-4 md:text-lg md:px-10"
          onClick={calculateAge}
        >
          Calculate Age
        </button>

        <div className="mb-8">
          <div className="result-item mb-2">
            <label className="block text-gray-700 text-lg font-bold mb-2">
              Full Age (Years;Months;Days):
            </label>
            <button
              className="rounded-full bg-green-500 text-black py-2 px-4"
              onClick={() => copyToClipboard(results.fullAge)}
            >
              {results.fullAge}
            </button>
          </div>
          <div className="flex justify-between mx-auto max-w-md">
            <div className="result-item mb-2">
              <label className="block text-gray-700 text-lg font-bold mb-2">Years:</label>
              <button
                className="rounded-full bg-green-500 text-black py-2 px-4"
                onClick={() => copyToClipboard(results.ageInYears)}
              >
                {results.ageInYears}
              </button>
            </div>
            <div className="result-item mb-2">
              <label className="block text-gray-700 text-lg font-bold mb-2">Months:</label>
              <button
                className="rounded-full bg-green-500 text-black py-2 px-4"
                onClick={() => copyToClipboard(results.ageInMonths)}
              >
                {results.ageInMonths}
              </button>
            </div>
            <div className="result-item mb-2">
              <label className="block text-gray-700 text-lg font-bold mb-2">Days:</label>
              <button
                className="rounded-full bg-green-500 text-white py-2 px-4"
                onClick={() => copyToClipboard(results.ageInDays)}
              >
                {results.ageInDays}
              </button>
            </div>
          </div>

          <div className="result-item">
            <label className="block text-gray-700 text-lg font-bold ">
              Cumulative Age in Months:
            </label>
            <button
              className="rounded-full bg-green-500 text-white py-2 px-4"
              onClick={() => copyToClipboard(results.cumulativeAgeInMonths)}
            >
              {results.cumulativeAgeInMonths}
            </button>
          </div>
        </div>

        {showCopyNotification && (
          <div className="text-green-500 text-lg mb-4">Result copied to clipboard!</div>
        )}
        {/* Expandable Disclosure */}
        <div className="mb-8">
          <button className="text-blue-500 underline cursor-pointer" onClick={toggleDisclosure}>
            {isDisclosureOpen ? 'Hide' : 'Show me'} how the results are calculated
          </button>
          {isDisclosureOpen && (
            <div className="bg-gray-100 p-4 text-lg rounded-lg mt-2 text-gray-700 transition duration-300 ease-in-out mb-20">
              <p className="font-semibold">How the results are calculated:</p>
              <p className="mt-2">
                The age calculation uses the date of birth and the provided date of testing to
                calculate the total difference in days between the two dates. The difference is then
                broken down into years, months, and days. The algorithm considers leap years and
                varying lengths of months to provide accurate results. Leap years have 366 days (due
                to an extra day in February), while common years have 365 days. Each month is
                accounted for according to its length (28, 29, 30, or 31 days). The calculated full
                age - broken down into years, months, days, and cumulative age in months are
                provided as results.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const HowToUseCalculator = () => {
  return (
    <div className="py-8">
      <h2 className="text-center text-xl font-bold tracking-tight text-gray-900 sm:text-2xl">
        How to Calculate Chronological Age
      </h2>
      <p className="mt-4 text-gray-700 text-lg">
        If you are new to the field or like to check your work, here is how to calculate
        chronological age. It requires no special math skills but is a little bit quirky because you
        are adding everything backwards.
      </p>
      <ol className="list-decimal ml-8 py-2">
        <li className="pt-2">
          Start with today’s date written backwards: (year month day) 2024 12 25
        </li>
        <li className="pt-2">
          Underneath write the child’s birthday written backwards (year month day) 2015 06 15
        </li>
        <li className="pt-2">Subtract the day (10)</li>
        <li className="pt-2">Subtract the month (6)</li>
        <li className="pt-2">Subtract the year (9)</li>
        <li className="pt-2">Discard the day</li>
        <li className="pt-2">Put a semi-colon between the year and the month (9;6)</li>
      </ol>

      <h3 className="text-center text-lg pt-4 font-bold tracking-tight text-gray-900 sm:text-xl">
        How to Calculate Chronological Age with Larger Numbers in the Birth Date
      </h3>
      <p className="mt-4 text-gray-700 text-lg">
        Any professional who has made an error calculating chronological age is probably breaking a
        sweat right now if it resulted in a misdiagnosis that hopefully you or your supervisor
        caught. Chances are it was related to math errors when the birth day or month was larger
        than the testing day or month. Here is how to calculate chronological age properly in this
        case:
      </p>
      <ol className="list-decimal ml-8 py-2">
        <li className="pt-2">
          Start with today’s date written backwards: (year month day) 2024 02 05
        </li>
        <li className="pt-2">
          Underneath write the child’s birthday written backwards (year month day) 2015 06 15
        </li>
        <li className="pt-2">
          Subtract the day BY ADDING 3O TO TODAY’S DATE, BORROWING 1 FROM THE MONTH. In this example
          we are now taking 35-15=20 and the month (February) becomes 1.
        </li>
        <li className="pt-2">
          Subtract the month (now 1) BY BORROWING 12 (MONTHS) FROM THE YEAR. In this case we are now
          taking 13-6=7
        </li>
        <li className="pt-2">
          Subtract the year REMEMBERING THAT YOU TOOK ONE AWAY. In this case, you are now
          subtracting 2015 from 2023 or 8.
        </li>
        <li className="pt-2">Discard the day</li>
        <li className="pt-2">Put a semi-colon between the year and the month (8;7)</li>
      </ol>

      <p className="mt-4 text-gray-700 text-lg">Not hard but certainly easier with a calculator.</p>
    </div>
  );
};

const WhyChronologicalAgeCalculator = () => {
  return (
    <div className="py-4">
      <div className="flex align-center justify-center pb-8 sm:px-0 rounded-lg">
        <picture className="rounded-lg">
          {/* <source srcSet={illustration.webp} type="image/webp" /> */}
          {/* <source srcSet={illustration.png} type="image/png" /> */}
          <img
            className="rounded-lg"
            src={ChronologicalAgeCalculatorImage}
            alt={'age chronological calculator'}
          />
        </picture>
      </div>

      <h2 className="text-center text-xl font-bold tracking-tight text-gray-900 sm:text-2xl">
        Why is Calculating Chronological Age Important in Speech Therapy Evaluations?
      </h2>
      <p className="mt-4 text-gray-700 text-lg">
        Calculating chronological age is a crucial factor in speech therapy evaluations as it
        provides a baseline for assessing a person's speech and language development.
        Speech-language pathologists calculate chronological age to compare an individual's
        communication skills with expected milestones for their age group. This information is
        essential for tailoring therapy plans to meet specific needs and tracking progress over
        time.
      </p>

      <p className="mt-4 text-gray-700 text-lg">
        Calculating chronological age allows professionals like speech-language pathologists to
        identify potential speech and language disorders, developmental delays, or other
        communication challenges. It forms the basis for creating personalized interventions that
        address individual strengths and weaknesses, ultimately improving the effectiveness of
        speech therapy.
      </p>
    </div>
  );
};

const WhyTestDevelopers = () => {
  return (
    <div className="pt-4">
      <h2 className="text-center text-xl font-bold tracking-tight text-gray-900 sm:text-2xl">
        Why Don’t Test Developers Have a Free Chronological Age Calculator?
      </h2>
      <p className="mt-4 text-gray-700 text-lg">
        We’re not really sure how to answer this. They definitely have one because they would have
        needed it to develop their tests. At times they make it available. But then, as websites get
        redesigned or things don’t get updated, they tend to go away. We relied on Pearson’s
        chronological age calculator for years as they are the publishers of many evaluations.
        However, at the time of this writing they simply abandoned it and said:
      </p>

      <div className="flex align-center justify-center py-8 sm:px-0 rounded-lg">
        <picture className="rounded-lg">
          {/* <source srcSet={illustration.webp} type="image/webp" /> */}
          {/* <source srcSet={illustration.png} type="image/png" /> */}
          <img
            className="rounded-lg"
            src={ChronologicalAgeCalculatorPearson}
            alt={'chronological age calculator pearson'}
          />
        </picture>
      </div>

      <p className="mt-4 text-gray-700 text-lg">
        Instead, they point to a world clock that is crammed with ads and a ton of unneeded
        information. Plus, they give instructions for how to modify it for special education
        purposes. We can’t fault them. We know what it takes to keep a website updated and they
        certainly have other priorities. So bookmark this chronological age calculator for testing
        your little ones so you get your numbers and diagnosis straight!
      </p>
    </div>
  );
};
