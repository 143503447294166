import React from 'react';
import { Button } from '../../components';

import GrowthTier from './GrowthTier';
import TIERS from './tiers';

const GrowthPricingTier = () => (
  <GrowthTier
    ctaComponent={
      <Button
        text={TIERS.premium.ctaLabel}
        link={true}
        color="primary"
        to={TIERS.premium.ctaLinkTo}
        fullWidth
      />
    }
  />
);

export default GrowthPricingTier;
