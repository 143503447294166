import React from 'react';
import { Title, TextLink as Link } from '..';
import { FormSubmissionModal } from '../FormSubmissionModal';

import CallToAction from './NetlifyFormCallToAction';
import { FORM_NAMES } from '../../contactFormConstants';
import EmailFormCallToAction from './EmailCallToAction';

export const SubscribeToNewsletterSuccessModal = (props) => (
  <FormSubmissionModal
    titleText="You're subscribed!"
    bodyText={
      <>
        <p>We publish most Mondays so you&apos;ll receive your first edition soon.</p>
        <p>In the meantime, we&apos;d love to show you our flexible Evalubox platform...</p>
      </>
    }
    followOn="GET_TRIAL"
    {...props}
  />
);

export const RequestTemplateToInboxCTA = (props) => (
  <div className="text-center">
    <div className="pb-3">
      <Title text={`Get the ${props.template} report template emailed to you!`} />
    </div>
    <p className="prose prose-primary mb-3 max-w-62 mx-auto">
      Easily modify and paste this template into your report. Send us a request at{' '}
      <Link
        color="primary"
        to={`mailto:admin@evalubox.com?subject=Request ${props.template} template`}
      >
        admin@evalubox.com.
      </Link>{' '}
      including the template name in the email or subject line.
    </p>
  </div>
);

export const RequestLanguageExposureToInboxCTA = (props) => (
  <div className="text-center">
    <div className="pb-3">
      <Title text={`Get a language exposure report in your mailbox`} />
    </div>

    <p className="prose prose-primary mb-3 max-w-62 mx-auto">
      To get the language exposure report sent to your mailbox, provide your email below and get
      language exposure report
    </p>

    <EmailFormCallToAction
      buttonText={`Get language exposure report`}
      netlifyFormName={FORM_NAMES.subscribeToNewsletter}
      {...props}
    />
  </div>
);
