import React from 'react';
import { Popover } from '@headlessui/react';
import classnames from 'classnames';
import {
  CodeIcon,
  BookOpenIcon,
  ChatIcon,
  ShieldCheckIcon,
  AcademicCapIcon,
  PlayIcon,
  BookmarkIcon,
  NewspaperIcon,
} from '@heroicons/react/outline';
import { Button } from '../../components';

import Logo from '../Logo';
import TopNavItem from './TopNavItem';
import OpenMenuButton from './OpenMenuButton';
import TopNavDropdownItem from './TopNavDropdownItem';
import DrawerMenu from './DrawerMenu';
import AgeCalculatorIcon, {
  AgeCalculatorIconSmall,
  EvaluationIconSmall,
  FileAudioIconSmall,
  FormsIconSmall,
  LibraryIconSmall,
} from '../LanguageProficiency/AgeCalculatorIcon';
import { LanguageExposureIconSmall } from '../LanguageProficiency/LanguageUseIcon';

const solutions = [
  {
    name: 'Evaluations',
    description: 'Write Comprehensive Speech Language Reports in a Fraction of the Time!',
    to: '/',
    icon: EvaluationIconSmall,
  },
  {
    name: 'Speech Referral Form',
    description: 'Gather parent, teacher, and health information in multiple languages.',
    to: '/speech-referral-form/',
    icon: FormsIconSmall,
  },
  {
    name: 'Language Sampling',
    description: 'Record, Transcribe, and Analyze Language Samples in Multiple Languages.',
    to: '/language-sample/',
    icon: FileAudioIconSmall,
  },
  {
    name: 'Chronological Age Calculator',
    description:
      'Calculate full age - broken down into years, months, days, and cumulative age in months.',
    to: '/resources/tools/chronological-age-calculator/',
    icon: AgeCalculatorIconSmall,
  },
  {
    name: 'Language Use and Exposure Calculator',
    description: 'Quickly gather information about the language use of your bilingual clients.',
    to: '/resources/tools/language-use-exposure-calculator/',
    icon: LanguageExposureIconSmall,
  },
  {
    name: 'Speech Therapy Report Templates',
    description: 'Library with popular Speech Therapy Report Templates.',
    to: '/resources/speech-therapy-report-templates/',
    icon: LibraryIconSmall,
  },
];
const learn = [
  {
    name: 'Evalubox Academy',
    description: 'Become an expert quickly with short, searchable, video tutorials.',
    to: 'https://academy.evalubox.com',
    icon: PlayIcon,
  },
  {
    name: 'Free CEU: 7 Reasons Your Reports Take Too Long and How to Shorten the Process',
    description:
      'Streamline Evaluations: 7 Time-Saving Tips for SLPs + Free Resources & Our All-in-One Solution',
    to: 'https://bilinguistics.com/catalog/speech-pathology-continuing-education/video/7-reasons-your-reports-take-too-long/',
    icon: AcademicCapIcon,
  },
  {
    name: 'Free CEU: 5 Ways SLPs are Increasing Their Income and Serving More Children',
    description:
      'Increase Income: Monetize Expertise & Meet Service Needs in Schools, Clinics, & Communities',
    to: 'https://bilinguistics.com/catalog/speech-pathology-continuing-education/free-courses/5-ways-slps-are-increasing-their-income-and-serving-more-children/',
    icon: AcademicCapIcon,
  },
  {
    name: 'Security',
    description:
      'Superior protection, compliance, and back-ups for documents and client information',
    to: '/security/',
    icon: ShieldCheckIcon,
  },
  //  {
  //   title: 'Case Studies',
  //   description: 'How organisations succeed with Evalubox',
  //   to: '/case-studies/',
  //   icon: ShieldCheckIcon,
  // },
  // {
  //   name: 'Send us an email',
  //   description: 'Get support or provide feedback.',
  //   to: 'mailto:admin@evalubox.com?subject=Feedback',
  //   icon: ChatIcon,
  // },
  // {
  //   name: 'Backstage Plugins',
  //   description: 'Browse our Backstage plugin marketplace.',
  //   to: '/backstage/plugins/',
  //   icon: CodeIcon,
  // }, {
  //   name: 'Blog',
  //   description: 'Read our posts on Evalubox.',
  //   to: '/blog/',
  //   icon: BookOpenIcon,
  // }, {
  //   name: 'Case Studies',
  //   description: "How organisations succeed with Backstage",
  //   to: '/case-studies/',
  //   icon: ShieldCheckIcon
  // },
  // {
  //   name: 'Documentation',
  //   description: "Set up your Evalubox experience.",
  //   to: '/docs/',
  //   icon: AcademicCapIcon,
  // },
  // {
  //   name: 'Chat with us on Discord',
  //   description: 'Get support or provide feedback.',
  //   to: 'https://discord.gg/W3qEMhmx4f',
  //   icon: ChatIcon,
  // }
];

const SitewideHeader = ({
  maxWidth = '7xl',
  borderBottom = true,
  ctaTo = '/pricing/',
  ctaText = 'Get Started',
}: any) => (
  <Popover className="relative bg-white z-20">
    <div className={`max-w-${maxWidth} mx-auto px-2 sm:px-6`}>
      <div
        className={classnames(
          `flex justify-between items-center py-6 lg:justify-start lg:space-x-10`,
          {
            'border-b-2 border-gray-100': borderBottom,
          }
        )}
      >
        <div className="flex justify-start lg:w-0 lg:flex-1">
          <Logo />
        </div>

        <div className="-mr-2 -my-2 lg:hidden">
          <OpenMenuButton />
        </div>

        <Popover.Group as="nav" className="hidden lg:flex space-x-10">
          <TopNavDropdownItem text="Solutions" subItems={solutions} />
          <TopNavItem to="/request-demo/" text="Enroll Your Team" />
          {/* <TopNavItem to="/request-demo/" text="Contact Us" /> */}
          <TopNavItem to="/pricing/" text="Pricing" />
          {/* <TopNavItem to="/backstage-weekly/" text="Backstage Weekly" /> */}
          <TopNavDropdownItem text="Learn" subItems={learn} />
          <TopNavItem to="https://app.evalubox.com/" text="Log in" />
        </Popover.Group>

        <div className="hidden lg:flex items-center justify-end lg:flex-1 lg:w-0">
          <Button link={true} color="primary" size="medium" to={ctaTo} text={ctaText} />
        </div>
      </div>
    </div>

    <DrawerMenu />
  </Popover>
);

export default SitewideHeader;
