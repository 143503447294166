import React, { useEffect } from 'react';
import { Headline, Lead } from '.';
const OurStory = () => {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-12 px-4 text-center sm:px-6 lg:px-8 lg:py-24">
        <div className="space-y-12">
          <div className="space-y-5 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-3xl">
            <div className="mt-1">
              <Headline id="open-roles">Over 20 years of Bilingual Evaluations.</Headline>
            </div>
            <div className="mt-5">
              <Lead>
                The critical shortage of speech language pathologists in the United States has left
                us with a lot of work on our plates. As a team of speech language pathologists who
                write dozens of reports each year, we needed a way to be faster, more efficient, and
                just as accurate, even when the child is bilingual. We have been working on small
                solutions for over 10 years and have pulled them all together for you in Evalubox.
              </Lead>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurStory;
