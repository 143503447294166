import React from 'react';
import Lead from '../Lead';
import Headline from '../Headline';
import TextLink from '../TextLink';
const ResourcesQuestionaireForms = () => (
  <>
    <div className="lg:mr-8 mx-auto">
      <div className="mb-4">
        <Headline el="h2" size="small" className="text-center">
          Easily Gather Language Use Information
        </Headline>
      </div>
      <div className="mb-10 mx-auto">
        <h3 className=" text-xl">
          Gather language use from parents and teachers in three easy ways:
        </h3>

        <ol className="list-decimal text-xl">
          <li className="ml-10">Adjust the sliders below during an interview.</li>
          <li className="ml-10">
            Download these{' '}
            <TextLink
              target="_blank"
              to="https://cdn.app.evalubox.com/marketing-site/language-exposure/Parent+Questionnaire+for+Home+Language+Use.pdf"
              color="primary"
            >
              Parent
            </TextLink>{' '}
            and{' '}
            <TextLink
              target="_blank"
              to="https://cdn.app.evalubox.com/marketing-site/language-exposure/Teacher+Questionnaire+for+Classroom+Language+Use.pdf"
              color="primary"
            >
              Teacher
            </TextLink>{' '}
            Questionnaires. Move these sliders below with their responses.
          </li>
          <li className="ml-10">
            For bilingual forms, download the teacher and language-specific questionnaires from our
            Parent Questionnaire Docs Page:{' '}
            <TextLink
              target="_blank"
              to="https://bilinguistics.com/speech-language-parent-questionnaire/"
              color="primary"
            >
              Bilingual Speech Language Parent Questionnaire
            </TextLink>{' '}
          </li>
        </ol>

        {/*         
        <Lead className="text-center">
          Download{' '}
          <TextLink
            target="_blank"
            to="https://cdn.app.evalubox.com/marketing-site/language-exposure/Parent+Questionnaire+for+Home+Language+Use.pdf"
            color="primary"
          >
            Parent Questionnaire for Home Language Use
          </TextLink>{' '}
          or{' '}
          <TextLink
            target="_blank"
            to="https://cdn.app.evalubox.com/marketing-site/language-exposure/Teacher+Questionnaire+for+Classroom+Language+Use.pdf"
            color="primary"
          >
            Teacher Questionnaire for Home Language Use
          </TextLink>{' '}
          to gather information offline.
        </Lead> */}
      </div>
    </div>
  </>
);
export default ResourcesQuestionaireForms;
