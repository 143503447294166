import React from 'react';
import { Tags } from '../../components';
import get from 'lodash/get';
import classnames from 'classnames';

import Attribution from './Attribution';
import TitleAndDescription from './TitleAndDescription';

// export const getAuthor = async (id) => {
//   try {
//     const {
//       data: { name },
//     } = await axios.get(`${AUTHORS_API_URL}/${id}`);
//     return name;

//   } catch (error) {
//    console.log(error);
//     return error;
//   }
// };

// export const getFeaturedImage = async (id) => {

//   try {
//     const response = await axios.get(`${MEDIA_API_URL}/${id}`);
//     return response.data.guid.rendered;
//   } catch (error) {
//     console.log(error);
//     return error;
//   }
// };

const CoverImage = ({ post }: any) => {
  const images = get(post, 'coverImage.gatsbyImage.images');
  const className = 'h-48 w-full object-cover bg-gray-200';
  if (!images) return <div className={className} />;

  return (
    <img
      className={className}
      srcSet={images.sources[0].srcSet}
      sizes={images.sources[0].sizes}
      src={images.fallback.src}
      alt={post.coverImage.title}
    />
  );
};

const PostSummary = ({ post, className }: any) => (
  <div className={classnames('flex flex-col rounded-lg shadow-lg overflow-hidden', className)}>
    <div className="flex-shrink-0 relative">
      <CoverImage post={post} />
    </div>

    <div className="flex-1 bg-white p-6 flex flex-col justify-between">
      {/* min-height ensures the content below the tags is aligned even when there are no tags */}
      <div className="min-h-[24px]">
        <Tags tags={post?.frontmatter?.tags} />
      </div>

      <div className="flex-1">
        <TitleAndDescription post={post} />
      </div>

      <div className="mt-6 flex items-center">
        <Attribution post={post} />
      </div>
    </div>
  </div>
);

export default PostSummary;
