import React from 'react';

import ListItemHeader from './ListItemHeader';
import Attribution from './Attribution';

const ToolsListItem = ({
  fields: { slug },
  frontmatter: { logoImage, humanName, attribution, heading },
}: any) => (
  <div className="border-2 py-4 px-12 text-center" style={{ height: 350 }}>
    <ListItemHeader slug={slug} logoImage={logoImage} humanName={humanName} heading={heading} />
    <Attribution attribution={attribution} />
  </div>
);

export default ToolsListItem;
