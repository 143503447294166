import React from 'react';
import { useEffect, useState } from 'react';
import EmailReportCTA from './EmailReportCTA';

const ParentInput = ({
  showcta = false,
  setParentResults,
  showSummary = true,
  sampleReportUrl = '',
}) => {
  const [parentInput, setParentInput] = useState({
    speaking: {
      afterSchool: 50,
      eveningsNights: 50,
      weekends: 50,
    },
    listening: {
      afterSchool: 50,
      eveningsNights: 50,
      weekends: 50,
    },
  });
  const handleParentInputChange = (event, type, category) => {
    setParentInput({
      ...parentInput,
      [type]: {
        ...parentInput[type],
        [category]: parseInt(event.target.value),
      },
    });
  };

  const renderParentLabel = (value, type) => {
    const speaks =
      parentInput[type][value] === 0
        ? 'Speaks only in Home Language outside of School'
        : parentInput[type][value] === 100
        ? 'Speaks only in English outside of School'
        : 'Speaks English outside of School';
    const listens =
      parentInput[type][value] === 0
        ? 'Listens only in Home Language outside of School'
        : parentInput[type][value] === 100
        ? 'Listens only in English outside of School'
        : 'Listens to English outside of School';

    return type === 'speaking' ? speaks : listens;
  };

  const calculateTotalHours = (type) => {
    const hoursAfterSchool = (15 * parentInput[type].afterSchool) / 100;
    const hoursEveningsNights = (15 * parentInput[type].eveningsNights) / 100;
    const hoursWeekends = (32 * parentInput[type].weekends) / 100;

    const totalHoursInEnglish = hoursAfterSchool + hoursEveningsNights + hoursWeekends;
    const totalPercentageInEnglish = (totalHoursInEnglish / 62) * 100; // Total school hours is 40

    const totalHoursByArea = {
      afterSchool: hoursAfterSchool.toFixed(2),
      eveningsNights: hoursEveningsNights.toFixed(2),
      weekends: hoursWeekends.toFixed(2),
      overall: totalHoursInEnglish.toFixed(2),
      percentageInEnglish: totalPercentageInEnglish.toFixed(0),
      percentageInHomeLanguage: (100 - totalPercentageInEnglish).toFixed(0),
    };

    return totalHoursByArea;
  };

  const totalSpeakingHours = calculateTotalHours('speaking');
  const totalListeningHours = calculateTotalHours('listening');

  // Calculating averages
  const averageInputOutputEnglish = (
    (parseFloat(totalSpeakingHours.percentageInEnglish) +
      parseFloat(totalListeningHours.percentageInEnglish)) /
    2
  ).toFixed(0);
  const averageInputOutputHomeLanguage = (100 - parseFloat(averageInputOutputEnglish)).toFixed(0);
  const [showCalculations, setShowCalculations] = useState(true);

  const results = {
    input: parentInput,
    totalSpeakingHours: totalSpeakingHours,
    totalListeningHours: totalListeningHours,
    averageInputOutputEnglish: averageInputOutputEnglish,
    averageInputOutputHomeLanguage: averageInputOutputHomeLanguage,
  };
  useEffect(() => {
    if (!showcta) {
      setParentResults(results);
    }
  }, [parentInput]);
  return (
    <div>
      <h2 className="text-xl font-semibold mb-2">Parent Input</h2>
      <div className="md:grid md:grid-cols-2 md:gap-x-10 ">
        <div>
          <h3 className="text-lg font-semibold mb-2">Speaking outside of School</h3>
          <div>
            <label className="block mb-2">After School</label>
            <input
              type="range"
              min="0"
              max="100"
              value={parentInput.speaking.afterSchool}
              onChange={(e) => handleParentInputChange(e, 'speaking', 'afterSchool')}
              className="w-full"
            />
            <p className="text-center">
              {parentInput.speaking.afterSchool}% - {renderParentLabel('afterSchool', 'speaking')}
            </p>
          </div>
          <div>
            <label className="block mb-2">Evenings/Nights</label>
            <input
              type="range"
              min="0"
              max="100"
              value={parentInput.speaking.eveningsNights}
              onChange={(e) => handleParentInputChange(e, 'speaking', 'eveningsNights')}
              className="w-full"
            />
            <p className="text-center">
              {parentInput.speaking.eveningsNights}% -{' '}
              {renderParentLabel('eveningsNights', 'speaking')}
            </p>
          </div>
          <div>
            <label className="block mb-2">Weekends</label>
            <input
              type="range"
              min="0"
              max="100"
              value={parentInput.speaking.weekends}
              onChange={(e) => handleParentInputChange(e, 'speaking', 'weekends')}
              className="w-full"
            />
            <p className="text-center">
              {parentInput.speaking.weekends}% - {renderParentLabel('weekends', 'speaking')}
            </p>
          </div>
          {/* <button onClick={() => setShowCalculations((prev) => !prev)}>
              {!showCalculations ? 'Show Calculations' : 'Hide Calculations'}
            </button> */}
          {showCalculations && (
            <table className="w-full mb-4 border border-collapse border-gray-800 hidden">
              <thead>
                <tr className="bg-gray-200">
                  <th className="border border-gray-800 px-4 py-2">Area</th>
                  <th className="border border-gray-800 px-4 py-2">Total Hours in English</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border border-gray-800 px-4 py-2">After School</td>
                  <td className="border border-gray-800 px-4 py-2">
                    {totalSpeakingHours.afterSchool} hours
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-800 px-4 py-2">Evenings/Nights</td>
                  <td className="border border-gray-800 px-4 py-2">
                    {totalSpeakingHours.eveningsNights} hours
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-800 px-4 py-2">Eeekends</td>
                  <td className="border border-gray-800 px-4 py-2">
                    {totalSpeakingHours.weekends} hours
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-800 px-4 py-2">
                    Total Overall Hours in English
                  </td>
                  <td className="border border-gray-800 px-4 py-2">
                    {totalSpeakingHours.overall} hours
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-800 px-4 py-2">
                    Percentage of Time Speaking in English Outside of School
                  </td>
                  <td className="border border-gray-800 px-4 py-2">
                    {totalSpeakingHours.percentageInEnglish}%
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-800 px-4 py-2">
                    Percentage of Time Speaking in Home Language Outside School
                  </td>
                  <td className="border border-gray-800 px-4 py-2">
                    {totalSpeakingHours.percentageInHomeLanguage}%
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-800 px-4 py-2" colSpan={2}>
                    Assumptions: 168 hours in a week, 8 hours per day sleeping, 40 hours at school,
                    and 72 hours outside of school. We assume students spend 3 hours per day with
                    students and with teachers for 5 days a week, bringing a total of 15 hours per
                    week. We assume students spend 2 hours a day with other school personnel for 5
                    days a week, bringing the total to 10 hours per week with other school
                    personnel. In total, the weekly hours combined to be 40 hours.
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
        <div>
          <h3 className="text-lg font-semibold mb-2">Listening outside of School</h3>
          <div>
            <label className="block mb-2">After School</label>
            <input
              type="range"
              min="0"
              max="100"
              value={parentInput.listening.afterSchool}
              onChange={(e) => handleParentInputChange(e, 'listening', 'afterSchool')}
              className="w-full"
            />
            <p className="text-center">
              {parentInput.listening.afterSchool}% - {renderParentLabel('weekends', 'listening')}
            </p>
          </div>
          <div>
            <label className="block mb-2">Evenings/Nights</label>
            <input
              type="range"
              min="0"
              max="100"
              value={parentInput.listening.eveningsNights}
              onChange={(e) => handleParentInputChange(e, 'listening', 'eveningsNights')}
              className="w-full"
            />
            <p className="text-center">
              {parentInput.listening.eveningsNights}% - {renderParentLabel('weekends', 'listening')}
            </p>
          </div>
          <div>
            <label className="block mb-2">Weekends</label>
            <input
              type="range"
              min="0"
              max="100"
              value={parentInput.listening.weekends}
              onChange={(e) => handleParentInputChange(e, 'listening', 'weekends')}
              className="w-full"
            />
            <p className="text-center">
              {parentInput.listening.weekends}% - {renderParentLabel('weekends', 'listening')}
            </p>
          </div>

          <table className="w-full mb-4 border border-collapse border-gray-800 hidden">
            <thead>
              <tr className="bg-gray-200">
                <th className="border border-gray-800 px-4 py-2">Area</th>
                <th className="border border-gray-800 px-4 py-2">Total Hours in English</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border border-gray-800 px-4 py-2">After School</td>
                <td className="border border-gray-800 px-4 py-2">
                  {totalListeningHours.afterSchool} hours
                </td>
              </tr>
              <tr>
                <td className="border border-gray-800 px-4 py-2">Evenings/Nights</td>
                <td className="border border-gray-800 px-4 py-2">
                  {totalListeningHours.eveningsNights} hours
                </td>
              </tr>
              <tr>
                <td className="border border-gray-800 px-4 py-2">Eeekends</td>
                <td className="border border-gray-800 px-4 py-2">
                  {totalListeningHours.weekends} hours
                </td>
              </tr>
              <tr>
                <td className="border border-gray-800 px-4 py-2">Total Overall Hours in English</td>
                <td className="border border-gray-800 px-4 py-2">
                  {totalListeningHours.overall} hours
                </td>
              </tr>
              <tr>
                <td className="border border-gray-800 px-4 py-2">
                  Percentage of Time Listening in English Outside of School
                </td>
                <td className="border border-gray-800 px-4 py-2">
                  {totalListeningHours.percentageInEnglish}%
                </td>
              </tr>
              <tr>
                <td className="border border-gray-800 px-4 py-2">
                  Percentage of Time Listening in Home Language Outside School
                </td>
                <td className="border border-gray-800 px-4 py-2">
                  {totalListeningHours.percentageInHomeLanguage}%
                </td>
              </tr>
              <tr>
                <td className="border border-gray-800 px-4 py-2" colSpan={2}>
                  Assumptions: 168 hours in a week, 8 hours per day sleeping, 40 hours at school,
                  and 72 hours outside of school. We assume students spend 3 hours per day with
                  students and with teachers for 5 days a week, bringing a total of 15 hours per
                  week. We assume students spend 2 hours a day with other school personnel for 5
                  days a week, bringing the total to 10 hours per week with other school personnel.
                  In total, the weekly hours combined to be 40 hours.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/* Summary table */}
      {showSummary && (
        <div className="mt-10">
          <h2 className="text-xl font-semibold mb-2">Summary</h2>
          <table className="w-full mb-4 border border-collapse border-gray-800">
            <caption className="caption-top ">
              The parent survey indicated that, during the child's time at outside of school, which
              represents approximately two-thirds of their waking hours, they hear and use English{' '}
              {averageInputOutputEnglish}% of the time and hear and use (home language){' '}
              {averageInputOutputHomeLanguage}% of the time.
            </caption>

            <thead>
              <tr className="bg-gray-200">
                <th className="border border-gray-800 px-4 py-2">Category</th>
                <th className="border border-gray-800 px-4 py-2">Average Percentage</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border border-gray-800 px-4 py-2">
                  Overall Input/Output in English Outside of School
                </td>
                <td className="border border-gray-800 px-4 py-2">{averageInputOutputEnglish}%</td>
              </tr>
              <tr>
                <td className="border border-gray-800 px-4 py-2">
                  Overall Input/Output in Home Language Outside of School
                </td>
                <td className="border border-gray-800 px-4 py-2">
                  {averageInputOutputHomeLanguage}%
                </td>
              </tr>
              <tr>
                <td className="border border-gray-800 px-4 py-2" colSpan={2}>
                  The parent survey indicated that, during the child's time at outside of school,
                  which represents approximately two-thirds of their waking hours, they hear and use
                  English {averageInputOutputEnglish}% of the time and hear and use (home language){' '}
                  {averageInputOutputHomeLanguage}% of the time.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}

      {/* Capture Email CTA */}
      {showcta && (
        <EmailReportCTA
          results={results}
          selectedOption={'parentInputAttendsSchool'}
          sampleUrl={sampleReportUrl}
        />
      )}
    </div>
  );
};

export default ParentInput;
