import React from 'react';
import { Lead, Headline, Link, Chip } from '..';
import Logo from '../SmartTemplates/Logo';
import { Attribution } from '../article';
import AgeCalculatorIcon from './AgeCalculatorIcon';
import LanguageExposureIcon from './LanguageUseIcon';

const EvaluboxDocsChip = ({ availableOnEvalubox, evaluboxDocsPath }: any) => {
  if (!availableOnEvalubox) return null;

  const chip = <Chip label="Available on Evalubox" color="green" />;
  if (!availableOnEvalubox) return chip;
  return (
    <Link to={`/docs/integrations${evaluboxDocsPath}`} className="inline-block">
      {chip}
    </Link>
  );
};

const AgeCalculatorHeader = ({
  // template: {
  //   frontmatter: {
  //     humanName,
  //     logoImage,
  //     heading,
  //     lead,
  //     attribution,
  //     // availableOnEvalubox,
  //     // evaluboxDocsPath,
  //   },
  // },
  headline,
  lead,
  attribution,
}: any) => (
  <>
    <div className="mx-auto max-w-7xl px-4 py-10">
      <Link to="/resources/tools/" className="font-bold text-blueroadie">
        <span className="text-orange-500">←</span> Tools
      </Link>
    </div>
    <header className="bg-white mx-auto max-w-7xl px-4 py-5 text-center lg:text-left xl:rounded-lg lg:flex lg:px-0 lg:mb-10 items-center">
      <div className="flex justify-center lg:w-1/4 mt-0 lg:mt-0">
        {/* <Logo sharpImage={logoImage?.childImageSharp} alt={`${humanName} logo`} /> */}
        <AgeCalculatorIcon showIconText={true} />
      </div>
      <div>
        <Headline className="mb-4">{headline}</Headline>
        <Lead className="mb-4">{lead}</Lead>
        {/* <Attribution attribution={attribution} className="mb-4" /> */}

        {/* <EvaluboxDocsChip availableOnEvalubox={availableOnEvalubox} evaluboxDocsPath={evaluboxDocsPath} /> */}
      </div>
    </header>
  </>
);

export default AgeCalculatorHeader;
