import React from 'react';
import { graphql } from 'gatsby';
import { SEO, SitewideHeader, SitewideFooter } from '../../components';
import SecurityLists from '../../components/Security/security-lists';
import SoftwareSecurityLists from '../../components/Security/software-security-lists';
import SecurityHeader from '../../components/Security/security-header';

const Security = ({
  data: {
    site: {
      siteMetadata: { title: siteTitle },
    },
  },
}: any) => {
  return (
    <>
      <SitewideHeader borderBottom={false} />

      <div className="bg-white">
        <SecurityHeader />
        <SecurityLists />
        <SoftwareSecurityLists />
      </div>
      <SitewideFooter />
    </>
  );
};

export default Security;

export const Head = () => (
  <>
    <SEO
      title={`Keep Important Records Safe and Secure`}
      description="Secure reports and client information with privacy, that meet HIPAA and FERPA compliance requirements."
    />
  </>
);

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
