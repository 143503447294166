import React from 'react';
import { Button, Headline } from '..';
import RequestAccessForm from './CenteredHero/RequestAccessForm';
import SimpleCenteredHeading from './SimpleCenteredHeading';

const LandingCTA = () => (
  <div className="bg-blue-50">
    <div className="max-w-7xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-12 lg:px-8">
      <SimpleCenteredHeading
        headline="Start Using Evalubox Today."
        lead="Save time that could be better spent on more important tasks"
      />
      {/* <div className="pb-3">
        <Headline el="h2">
          <span className="block">Start Using Evalubox Today.</span>
        </Headline>
      </div> */}

      <div className=" mx-auto max-w-2xl">
        {/* <RequestAccessForm from={"home-page"} showTC={true} /> */}
        <div className="inline-flex">
          <Button
            link={true}
            to="/pricing/?utm_source=evalubox-home&utm_medium=internal&utm_campaign=february-2024&utm_term=landing-cta&utm_content=cta-get-started"
            color="primary"
            text="Get Started"
            size="large"
          />
        </div>

        {/* <div className="ml-3 inline-flex">
          <Button link={true} to="/request-demo/" color="inset" text="Request Demo" />
        </div> */}
      </div>
    </div>
  </div>
);

export default LandingCTA;
