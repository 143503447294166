import React from 'react';

import SnykLogo from '../../../content/assets/home/customer-logos/snyk-monochrome.webp';
import SnykLogoPng from '../../../content/assets/home/customer-logos/snyk-monochrome.png';
import StreamElementsLogo from '../../../content/assets/home/customer-logos/streamelements-monochrome.webp';
import StreamElementsLogoPng from '../../../content/assets/home/customer-logos/streamelements-monochrome.png';
import TrackunitLogo from '../../../content/assets/home/customer-logos/trackunit-monochrome.webp';
import TrackunitLogoPng from '../../../content/assets/home/customer-logos/trackunit-monochrome.png';
import ContentfulLogo from '../../../content/assets/home/customer-logos/contentful-monochrome.webp';
import ContentfulLogoPng from '../../../content/assets/home/customer-logos/contentful-monochrome.png';
import NcsaLogo from '../../../content/assets/home/customer-logos/ncsa-monochrome.webp';
import NcsaLogoPng from '../../../content/assets/home/customer-logos/ncsa-monochrome.png';
import HopperLogo from '../../../content/assets/home/customer-logos/hopper-monochrome.webp';
import HopperLogoPng from '../../../content/assets/home/customer-logos/hopper-monochrome.png';
import MyFitnessPalLogo from '../../../content/assets/home/customer-logos/myfitnesspal-monochrome.webp';
import MyFitnessPalLogoPng from '../../../content/assets/home/customer-logos/myfitnesspal-monochrome.png';
import YotpoLogo from '../../../content/assets/home/customer-logos/yotpo-monochrome.webp';
import YotpoLogoPng from '../../../content/assets/home/customer-logos/yotpo-monochrome.png';
import NetlifyLogo from '../../../content/assets/home/customer-logos/netlify-monochrome.webp';
import NetlifyLogoPng from '../../../content/assets/home/customer-logos/netlify-monochrome.png';

import HopperWhiteLogo from '../../../content/assets/home/customer-logos-white/hopper.png';
import ContentfulWhiteLogo from '../../../content/assets/home/customer-logos-white/contentful.svg';
import SnykWhiteLogo from '../../../content/assets/home/customer-logos-white/snyk.png';
import NetlifyWhiteLogo from '../../../content/assets/home/customer-logos-white/netlify.png';

export const LOGOS = [
  {
    src: {
      webp: SnykLogo,
      png: SnykLogoPng,
      white: SnykWhiteLogo,
    },
    alt: 'Snyk logo',
    title: '~150 engineers',
  },
  {
    src: {
      webp: NetlifyLogo,
      png: NetlifyLogoPng,
      white: NetlifyWhiteLogo,
    },
    alt: 'Netlify logo',
    title: '~100 engineers',
  },
  {
    src: {
      webp: ContentfulLogo,
      png: ContentfulLogoPng,
      white: ContentfulWhiteLogo,
    },
    alt: 'Contentful logo',
    title: '~150 engineers',
  },
  {
    src: {
      webp: HopperLogo,
      png: HopperLogoPng,
      white: HopperWhiteLogo,
    },
    alt: 'Hopper logo',
    title: '~200 engineers',
  },
  {
    src: {
      webp: MyFitnessPalLogo,
      png: MyFitnessPalLogoPng,
    },
    alt: 'MyFitnessPal logo',
    title: '~100 engineers',
  },
  {
    src: {
      webp: TrackunitLogo,
      png: TrackunitLogoPng,
    },
    alt: 'Trackunit logo',
    title: '~70 engineers',
  },
  {
    src: {
      webp: NcsaLogo,
      png: NcsaLogoPng,
    },
    alt: 'NCSA logo',
    title: '~50 engineers',
  },
  {
    src: {
      webp: StreamElementsLogo,
      png: StreamElementsLogoPng,
    },
    alt: 'StreamElements logo',
    title: '~50 engineers',
  },
  {
    src: {
      webp: YotpoLogo,
      png: YotpoLogoPng,
    },
    alt: 'Yotpo logo',
    title: '~250 engineers',
  },
];

/* eslint-disable jsx-a11y/alt-text */
export const LogoItem = ({ src, ...rest }: any) => (
  <picture>
    <source srcSet={src.webp} type="image/webp" />
    <source srcSet={src.png} type="image/png" />
    <img src={src.png} {...rest} />
  </picture>
);
/* eslint-enable jsx-a11y/alt-text */

const MLAReferences = ({ logos = LOGOS }: any) => (
  <div className="bg-white">
    <div className="max-w-7xl mx-auto py-12 lg:py-32 px-4 sm:px-6 lg:px-8">
      <div className="text-left mb-8 sm:mb-12 text-gray-600">
        <p>References</p>
        <ol className="list-decimal px-4 text-sm">
          <li id="reference-1">
            Squires, Katie. "Addressing the Shortage of Speech-Language Pathologists in School
            Settings." Journal of the American Academy of Special Education Professionals 131
            (2013): 137.
          </li>
          <li id="reference-2">
            Gill, Cindy, et al. "Addressing the shortage of school-based SLPs: An innovative
            approach in Texas." The ASHA Leader 16.9 (2011): online-only.
          </li>
          <li id="reference-3">
            Ewen, Claire, et al. "Well-being, job satisfaction, stress and burnout in
            speech-language pathologists: A review." International journal of speech-language
            pathology 23.2 (2021): 180-190.
          </li>
          <li id="reference-4">
            Beck, Ann R., and Heidi Verticchio. "Facilitating speech-language pathology graduate
            students' ability to manage stress: A pilot study." Contemporary Issues in Communication
            Science and Disorders 41.Spring (2014): 24-38.
          </li>
          <li id="reference-5">
            Pownall, Samantha C. "Education Delayed Is Education Denied." NYL Sch. L. Rev. 63
            (2018): 95.
          </li>
          <li id="reference-6">
            DeMatthews, David E., and David S. Knight. "The Texas special education cap: Exploration
            into the statewide delay and denial of support to students with disabilities." education
            policy analysis archives 27 (2019): 2-2.
          </li>
        </ol>
      </div>
    </div>
  </div>
);

export default MLAReferences;
