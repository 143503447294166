import React from 'react';
import { Headline, Lead } from '../../components';

const TITLE = 'Unlocking potential for people with disabilities.';
const LEAD =
  'Our mission is to empower the special education ecosystem with unified technology that enables service providers, parents, educators, and administrators to deliver the best education to students with disabilities, allowing them to realize their potential, in their way.';

const VALUES = [
  {
    title: 'Focused on collaboration',
    detail:
      'We choose to work on problems together rather than apart. We take the time to ensure everyone has the context required to contribute.',
  },
  {
    title: 'Driven by customer compassion',
    detail: `We take the time to deeply understand our community's problems. We don't build things simply because we want to. We are focused on creating an excellent experience for  the end user.`,
  },
  {
    title: 'Optimized for Speech-Language Pathologists',
    detail: `Walking in our customers' shoes and learning from each interaction to better serve speech-language pathologists is what drives us at Evalubox. We are always open to new ideas.`,
  },
  {
    title: 'Conscious of community',
    detail:
      'We consider our impact on the wider ecosystem and do our best for diversity, inclusion and equity. Our product was born from a community of bilingual speech-language pathologists, so we consider ourselves part of a broad community serving diverse students and clients.',
  },
];

const Values = ({ title = TITLE, lead = LEAD, values = VALUES }: any) => (
  <div className="bg-white">
    <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-24">
      <div className="max-w-2xl lg:mx-auto lg:text-center">
        <div className="mt-1">
          <Headline>{title}</Headline>
        </div>

        <div className="mt-5">
          <Lead>{lead}</Lead>
        </div>
      </div>

      <div className="mt-20">
        <dl className="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-10">
          {values.map((value) => (
            <div key={value.title}>
              <dt className="font-semibold text-gray-900">{value.title}</dt>
              <dd className="mt-3 text-gray-500">{value.detail}</dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  </div>
);

export default Values;
