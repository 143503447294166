import React from 'react';
import { useState } from 'react';
import TeacherInput from './TeacherInput';
import ParentInput from './ParentInput';
import ParentNoSchoolInput from './ParentNoSchoolInput';
import InstructionsVideo from './InstructionsVideo';
import Headline from '../Headline';
import { Link } from 'gatsby';
import LanguageExposureHeader from './LanguageExposureHeader';
import ResourcesQuestionaireForms from './DownloadableForms';
import CombinedParentTeacherSummary from './CombinedParentTeacherSummary';

const LanguageCalculator = () => {
  const [selectedOption, setSelectedOption] = useState('teacherParentInput'); // Default option
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const [parentResults, setParentResults] = useState();
  const [teacherResults, setTeacherResults] = useState();

  const teacherInputSampleReportUrl =
    'https://cdn.app.evalubox.com/marketing-site/language-exposure/Language+Exposure+and+Use+Report+-+Teacher+Input+Only.pdf';
  const parentInputSampleReportUrl =
    'https://cdn.app.evalubox.com/marketing-site/language-exposure/Language+Exposure+and+Use+Report+-+Parent+School+Age+Input+Only.pdf';
  const parentNoSchoolInputSampleReportUrl =
    'https://cdn.app.evalubox.com/marketing-site/language-exposure/Language+Exposure+and+Use+Report+-+Parent+Input+Not+In+School.pdf';
  const combinedInputSampleReportUrl =
    'https://cdn.app.evalubox.com/marketing-site/language-exposure/Language+Exposure+and+Use+Report+-+Combine+Parent-Teacher+Input.pdf';

  return (
    <>
      {/* <div className="mx-auto max-w-7xl px-4 ">
        <Link to="/resources/tools/" className="font-bold text-blueroadie">
          <span className="text-orange-500">←</span> Tools
        </Link>
      </div> */}
      <LanguageExposureHeader
        headline="Language Exposure & Use Calculator"
        lead="Quickly gather information about the language use of your bilingual kids with this language exposure and use calculator."
        attribution=""
      />
      <div className="container mx-auto px-4  sm:px-6 lg:px-8 ">
        {/* <Headline className="mb-4">Language Exposure and Use Calculator</Headline> */}
        {/* <h1 className="text-2xl font-bold mb-4"></h1> */}

        <div className="relative  mx-auto ">
          <InstructionsVideo
            videoSrcURL={
              'https://cdn.app.evalubox.com/marketing-site/language-exposure/LanguageExposure-UseTopPageVideo.mp4'
            }
            thumbnailSrc={
              'https://cdn.app.evalubox.com/marketing-site/language-exposure/instructional_video_thumbnail.png'
            }
            videoTitle={
              'Instructional video on how to quickly gather information about the language use of our bilingual kids with language exposure and use calculator.'
            }
          />
          <ResourcesQuestionaireForms />
          <h2 className="text-xl font-semibold mb-2">Select Option:</h2>
          <select
            value={selectedOption}
            onChange={handleOptionChange}
            className="border border-gray-300 rounded p-2 w-full mb-4"
          >
            <option value="teacherParentInput">Teacher and Parent Input</option>
            <option value="teacherInput">Teacher Input Only</option>
            <option value="parentInputAttendsSchool">Parent Input Only - Attends School</option>
            <option value="parentInputDoesNotAttendSchool">
              Parent Input Only - Does Not Attend School
            </option>
          </select>
          {selectedOption === 'teacherParentInput' && (
            <div>
              {/* Teacher input section */}
              <TeacherInput
                showcta={false}
                setTeacherResults={setTeacherResults}
                showSummary={false}
              />
              {/* Parent input section */}
              <hr className="mt-10 py-4" />
              <ParentInput
                showcta={false}
                setParentResults={setParentResults}
                showSummary={false}
              />

              <hr className="mt-10 py-4" />
              {parentResults && teacherResults && (
                <CombinedParentTeacherSummary
                  parentResults={parentResults}
                  teacherResults={teacherResults}
                  sampleReportUrl={combinedInputSampleReportUrl}
                />
              )}
            </div>
          )}
          {selectedOption === 'teacherInput' && (
            <div>
              {/* Teacher input section */}
              <TeacherInput
                showcta={true}
                setTeacherResults={undefined}
                sampleReportUrl={teacherInputSampleReportUrl}
              />
            </div>
          )}
          {selectedOption === 'parentInputAttendsSchool' && (
            <div>
              {/* Teacher input section */}
              <ParentInput
                showcta={true}
                setParentResults={undefined}
                sampleReportUrl={parentInputSampleReportUrl}
              />
            </div>
          )}
          {selectedOption === 'parentInputDoesNotAttendSchool' && (
            <div>
              {/* Teacher input section */}
              <ParentNoSchoolInput sampleReportUrl={parentNoSchoolInputSampleReportUrl} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default LanguageCalculator;
