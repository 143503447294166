import React from 'react';
import { Button, Headline } from '../../components';
import RequestAccessForm from './CenteredHero/RequestAccessForm';
import SimpleCenteredHeading from './SimpleCenteredHeading';

const FooterCTA = () => (
  <div className="bg-white">
    <div className="max-w-7xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-32 lg:px-8">
      <div className="pb-3">
        <Headline el="h2">
          <span className="block">Start Using Evalubox Today.</span>
        </Headline>
      </div>

      <div className="mt-8 mx-auto max-w-2xl">
        {/* <RequestAccessForm from={"home-page"} showTC={true} /> */}
        <div className="inline-flex">
          <Button link={true} to="/pricing/" color="primary" text="Get Started" size="large" />
        </div>

        <div className="ml-3 inline-flex">
          <Button link={true} to="/request-demo/" color="inset" text="Request Demo" size="large" />
        </div>
      </div>
    </div>
  </div>
);

export default FooterCTA;

export const HomeFooterCTA = () => (
  <div className="bg-white">
    <div className="max-w-7xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-32 lg:px-8">
      <div className="pb-3">
        <Headline el="h2">
          <span className="block">Start Using Evalubox Today.</span>
        </Headline>
      </div>

      <div className="mt-8 mx-auto max-w-2xl">
        {/* <RequestAccessForm from={"home-page"} showTC={true} /> */}
        <div className="inline-flex">
          <Button
            link={true}
            to="/pricing/?utm_source=evalubox-home&utm_medium=internal&utm_campaign=february-2024&utm_term=footer-cta&utm_content=cta-get-started"
            color="primary"
            text="Get Started"
            size="large"
          />
        </div>

        <div className="ml-3 inline-flex">
          <Button
            link={true}
            to="/request-demo/?utm_source=evalubox-home&utm_medium=internal&utm_campaign=february-2024&utm_term=footer-cta&utm_content=cta-request-demo"
            color="inset"
            text="Request Demo"
            size="large"
          />
        </div>
      </div>
    </div>
  </div>
);

export const ToolsFooterCTA = () => (
  <div className="bg-white">
    <div className="max-w-7xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-32 lg:px-8">
      {/* <div className="pb-3">
        <Headline el="h2">
          <span className="block">Start Using Evalubox Today.</span>
        </Headline>
        <Lead>
        When you're crunched for time, analyzing and writing a comprehensive report can seem overwhelming! 
        </Lead>
      </div> */}

      <SimpleCenteredHeading
        headline="Start Using Evalubox Today."
        lead="Write Comprehensive Speech Language Reports in a Fraction of the Time!"
      />

      <div className="mt-8 mx-auto max-w-2xl">
        {/* <RequestAccessForm from={"home-page"} showTC={true} /> */}
        <div className="inline-flex">
          <Button
            link={true}
            to="/pricing/?utm_source=evalubox-home&utm_medium=internal&utm_campaign=february-2024&utm_term=footer-cta&utm_content=cta-get-started"
            color="primary"
            text="Get Started"
            size="large"
          />
        </div>

        <div className="ml-3 inline-flex">
          <Button
            link={true}
            to="/request-demo/?utm_source=evalubox-home&utm_medium=internal&utm_campaign=february-2024&utm_term=footer-cta&utm_content=cta-request-demo"
            color="inset"
            text="Request Demo"
            size="large"
          />
        </div>
      </div>
    </div>
  </div>
);
