import * as React from 'react';

export default function SoftwareSecurityLists(props: any) {
  return (
    <section>
      <div className="px-4 py-12 mx-auto max-w-7xl sm:px-6 md:px-12 lg:px-4 lg:pb-12">
        <div className="flex flex-wrap items-center mx-auto max-w-7xl">
          <div className="flex flex-col w-full mb-12 text-center">
            <h3 className="max-w-5xl text-xl font-bold leading-none tracking-tighter text-stai-1 md:text-4xl lg:text-4xl lg:max-w-7xl">
              Software security
            </h3>
          </div>
          <div className="flex flex-col items-start mb-16 text-left lg:flex-grow lg:w-full lg:pl-6 xl:pl-24 md:mb-0 xl:mt-0">
            <dl className="grid grid-cols-1 gap-8 md:grid-cols-3">
              <div>
                <dt className="inline-flex items-center justify-center flex-shrink-0 w-12 h-12 mb-5 text-indigo-600 rounded-full bg-blue-50">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M5.25 14.25h13.5m-13.5 0a3 3 0 01-3-3m3 3a3 3 0 100 6h13.5a3 3 0 100-6m-16.5-3a3 3 0 013-3h13.5a3 3 0 013 3m-19.5 0a4.5 4.5 0 01.9-2.7L5.737 5.1a3.375 3.375 0 012.7-1.35h7.126c1.062 0 2.062.5 2.7 1.35l2.587 3.45a4.5 4.5 0 01.9 2.7m0 0a3 3 0 01-3 3m0 3h.008v.008h-.008v-.008zm0-6h.008v.008h-.008v-.008zm-3 6h.008v.008h-.008v-.008zm0-6h.008v.008h-.008v-.008z"
                    />
                  </svg>
                </dt>
                <dd className="flex-grow">
                  <h2 className="mb-3 text-2xl font-bold tracking-tighter text-stai-1">
                    Servers and networking
                  </h2>
                  <p className="text-base leading-relaxed text-gray-700">
                    All servers that run Evalubox software in production are recent, hosted only
                    HIPAA approved cloud services from AWS. Services that we utilize such as S3,
                    Cloudfront, and others, are compreshensively hardened
                  </p>
                </dd>
              </div>
              <div>
                <dt className="inline-flex items-center justify-center flex-shrink-0 w-12 h-12 mb-5 text-indigo-600 rounded-full bg-blue-50">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125"
                    />
                  </svg>
                </dt>
                <dd className="flex-grow">
                  <h2 className="mb-3 text-2xl font-bold tracking-tighter text-stai-1">Storage</h2>
                  <p className="text-base leading-relaxed text-gray-700">
                    Evalubox stores evaluation data such as intake information, test scores, report
                    documents, and customer's data in a different locations while also compiling and
                    generating documents when requested. All data in each location is encryted at
                    rest and in transit with AES-256 and sophisticated encryption key management.
                  </p>
                </dd>
              </div>
              <div>
                <dt className="inline-flex items-center justify-center flex-shrink-0 w-12 h-12 mb-5 text-indigo-600 rounded-full bg-blue-50">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M17.25 6.75L22.5 12l-5.25 5.25m-10.5 0L1.5 12l5.25-5.25m7.5-3l-4.5 16.5"
                    />
                  </svg>
                </dt>
                <dd className="flex-grow">
                  <h2 className="mb-3 text-2xl font-bold tracking-tighter text-stai-1">
                    Coding and testing practices
                  </h2>
                  <p className="text-base leading-relaxed text-gray-700">
                    Evalubox leverages industry standard programming techniques such as having a
                    documented development and quality assurance processes, and also followign
                    guidelines such as the OWASP report, to ensure that the application meet
                    security standards.
                  </p>
                </dd>
              </div>
              <div>
                <dt className="inline-flex items-center justify-center flex-shrink-0 w-12 h-12 mb-5 text-indigo-600 rounded-full bg-blue-50">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15.75 5.25a3 3 0 013 3m3 0a6 6 0 01-7.029 5.912c-.563-.097-1.159.026-1.563.43L10.5 17.25H8.25v2.25H6v2.25H2.25v-2.818c0-.597.237-1.17.659-1.591l6.499-6.499c.404-.404.527-1 .43-1.563A6 6 0 1121.75 8.25z"
                    />
                  </svg>
                </dt>
                <dd className="flex-grow">
                  <h2 className="mb-3 text-2xl font-bold tracking-tighter text-stai-1">
                    Employee access
                  </h2>
                  <p className="text-base leading-relaxed text-gray-700">
                    We follow the principle of least privilege in how we write software, as well as
                    the level of access employees, are instructed to use in diagnosign and resolving
                    problems in our software and responding to customer support requests.
                  </p>
                </dd>
              </div>
              <div>
                <dt className="inline-flex items-center justify-center flex-shrink-0 w-12 h-12 mb-5 text-indigo-600 rounded-full bg-blue-50">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3.75 6A2.25 2.25 0 016 3.75h2.25A2.25 2.25 0 0110.5 6v2.25a2.25 2.25 0 01-2.25 2.25H6a2.25 2.25 0 01-2.25-2.25V6zM3.75 15.75A2.25 2.25 0 016 13.5h2.25a2.25 2.25 0 012.25 2.25V18a2.25 2.25 0 01-2.25 2.25H6A2.25 2.25 0 013.75 18v-2.25zM13.5 6a2.25 2.25 0 012.25-2.25H18A2.25 2.25 0 0120.25 6v2.25A2.25 2.25 0 0118 10.5h-2.25a2.25 2.25 0 01-2.25-2.25V6zM13.5 15.75a2.25 2.25 0 012.25-2.25H18a2.25 2.25 0 012.25 2.25V18A2.25 2.25 0 0118 20.25h-2.25A2.25 2.25 0 0113.5 18v-2.25z"
                    />
                  </svg>
                </dt>
                <dd className="flex-grow">
                  <h2 className="mb-3 text-2xl font-bold tracking-tighter text-stai-1">
                    Isolated environments
                  </h2>
                  <p className="text-base leading-relaxed text-gray-700">
                    The production network segments are logically isolaed from other Corporate, QA,
                    and Development segments.
                  </p>
                </dd>
              </div>
              <div>
                <dt className="inline-flex items-center justify-center flex-shrink-0 w-12 h-12 mb-5 text-indigo-600 rounded-full bg-blue-50">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z"
                    />
                  </svg>
                </dt>
                <dd className="flex-grow">
                  <h2 className="mb-3 text-2xl font-bold tracking-tighter text-stai-1">
                    Customer payment information
                  </h2>
                  <p className="text-base leading-relaxed text-gray-700">
                    Evalubox uses external secure third party payment processing and does not
                    process, store, or transmit any payment card data.
                  </p>
                </dd>
              </div>
              <div>
                <dt className="inline-flex items-center justify-center flex-shrink-0 w-12 h-12 mb-5 text-indigo-600 rounded-full bg-blue-50">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9.348 14.651a3.75 3.75 0 010-5.303m5.304 0a3.75 3.75 0 010 5.303m-7.425 2.122a6.75 6.75 0 010-9.546m9.546 0a6.75 6.75 0 010 9.546M5.106 18.894c-3.808-3.808-3.808-9.98 0-13.789m13.788 0c3.808 3.808 3.808 9.981 0 13.79M12 12h.008v.007H12V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
                    />
                  </svg>
                </dt>
                <dd className="flex-grow">
                  <h2 className="mb-3 text-2xl font-bold tracking-tighter text-stai-1">
                    System monitoring and alerting
                  </h2>
                  <p className="text-base leading-relaxed text-gray-700">
                    At Evalubox, the product application and underlying infrastructure components
                    are monitored 24/7/365 days a year, by dedicated monitoring systems. Critical
                    alerts are sent to 24/7/365 on call DevOps team members and escalated
                    appropriately to operations management.
                  </p>
                </dd>
              </div>
              <div>
                <dt className="inline-flex items-center justify-center flex-shrink-0 w-12 h-12 mb-5 text-indigo-600 rounded-full bg-blue-50">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M11.42 15.17L17.25 21A2.652 2.652 0 0021 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 11-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 004.486-6.336l-3.276 3.277a3.004 3.004 0 01-2.25-2.25l3.276-3.276a4.5 4.5 0 00-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437l1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008z"
                    />
                  </svg>
                </dt>
                <dd className="flex-grow">
                  <h2 className="mb-3 text-2xl font-bold tracking-tighter text-stai-1">
                    Service levels and backups
                  </h2>
                  <p className="text-base leading-relaxed text-gray-700">
                    Evalubox infrastructure utilizes many layered techniques for increasingly
                    reliable uptime, including the use of auto-scaling, load balancing, taks queses,
                    and rolling deployments. We do full automated backups of our databases. All
                    backups are encrypted.
                  </p>
                </dd>
              </div>
              <div>
                <dt className="inline-flex items-center justify-center flex-shrink-0 w-12 h-12 mb-5 text-indigo-600 rounded-full bg-blue-50">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6.75 7.5l3 2.25-3 2.25m4.5 0h3m-9 8.25h13.5A2.25 2.25 0 0021 18V6a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 6v12a2.25 2.25 0 002.25 2.25z"
                    />
                  </svg>
                </dt>
                <dd className="flex-grow">
                  <h2 className="mb-3 text-2xl font-bold tracking-tighter text-stai-1">
                    Vulnerability testing
                  </h2>
                  <p className="text-base leading-relaxed text-gray-700">
                    Web application security is evaluated by the development team in sync with the
                    applicaiton realease cycle. Vulnerability testing includes the use of commonly
                    known web applicaiton security toolkits and scanners to identify applicaiton
                    vulnerabilities before they are released into production.
                  </p>
                </dd>
              </div>
              <div>
                <dt className="inline-flex items-center justify-center flex-shrink-0 w-12 h-12 mb-5 text-indigo-600 rounded-full bg-blue-50">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className=" w-6 h-6 feather feather-layers"
                  >
                    <polygon points="12 2 2 7 12 12 22 7 12 2"></polygon>
                    <polyline points="2 17 12 22 22 17"></polyline>
                    <polyline points="2 12 12 17 22 12"></polyline>
                  </svg>{' '}
                </dt>
                <dd className="flex-grow">
                  <h2 className="mb-3 text-2xl font-bold tracking-tighter text-stai-1">
                    Application architecture
                  </h2>
                  <p className="text-base leading-relaxed text-gray-700">
                    The Evalubox web application is multi-tiered into logical segments (front-end,
                    api-tier, database), each independently separated from each other in a separate
                    account. This guarantees maximum protection and independence between layers.
                  </p>
                </dd>
              </div>
              <div>
                <dt className="inline-flex items-center justify-center flex-shrink-0 w-12 h-12 mb-5 text-indigo-600 rounded-full bg-blue-50">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
                    />
                  </svg>
                </dt>
                <dd className="flex-grow">
                  <h2 className="mb-3 text-2xl font-bold tracking-tighter text-stai-1">
                    Log out after 30 mins of in-activity
                  </h2>
                  <p className="text-base leading-relaxed text-gray-700">
                    Evalubox web application automatically logs you out of the application after 30
                    minutes of inactivity within the application.
                  </p>
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </section>
  );
}
