import React from 'react';
import { Button } from '../../components';
import { ExternalLinkIcon } from '@heroicons/react/outline';

import GrowthTier from './GrowthTier';

const PurchaseGrowthTier = () => (
  <GrowthTier
    ctaComponent={
      <Button
        text="Talk to sales"
        id="growth-plan-cta"
        link={true}
        color="primary"
        postfixIcon={<ExternalLinkIcon />}
        to="/contact-us/"
        fullWidth
      />
    }
  />
);

export default PurchaseGrowthTier;
