import React from 'react';

import theme from '../../theme';

const EvaluboxIcon = ({ fill = theme.COLORS_PRIMARY_600, ...rest }) => (
  // <svg
  //   width="27"
  //   height="31"
  //   viewBox="0 0 27 31"
  //   fill="none"
  //   xmlns="http://www.w3.org/2000/svg"
  //   {...rest}
  // >
  //   <path d="M13.2866 26.835V25.6408L26.5937 17.9772L23.1749 16.0061L13.2866 21.6986V20.5086L26.516 12.8858L23.1013 10.9147L13.2866 16.5664V15.3722L26.6346 7.68407L13.2866 0L0 7.68407V23.0604L13.2866 30.7445L26.6346 23.0604L23.2403 21.1016L13.2866 26.835Z" fill={fill} />
  // </svg>

  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="27"
    height="31"
    viewBox="0 0 331.000000 328.000000"
    {...rest}
    //  fill="none"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,328.000000) scale(0.100000,-0.100000)"
      fill={fill}
      stroke="none"
    >
      <path
        d="M100 3048 c0 -79 -3 -632 -7 -1229 l-6 -1087 441 -7 442 -7 0 -324 0
-324 1130 0 1130 0 0 1125 0 1125 -325 0 -325 0 0 435 0 435 -1240 0 -1240 0
0 -142z m2165 -177 c3 -6 2 -134 -1 -285 l-7 -276 -644 0 -643 0 0 -635 0
-635 -285 0 -285 0 0 753 c0 415 3 829 7 920 l6 167 923 0 c569 0 925 -4 929
-9z m-5 -1351 l0 -480 -490 0 -490 0 0 480 0 480 490 0 490 0 0 -480z m660
-330 l0 -810 -820 0 -820 0 0 170 0 170 650 0 650 0 0 640 0 640 170 0 170 0
0 -810z"
      />
    </g>
  </svg>
);

export default EvaluboxIcon;
