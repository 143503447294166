import pick from 'lodash/pick';
import get from 'lodash/get';

const mapWordpressBlogPostToMarkdownRemarkBlogPost = ({ node }) => ({
  node: {
    frontmatter: {
      ...pick(node, ['title', 'lastValidated']),
      tags: get(node, 'tags.nodes.name', ''),
      date: get(node, 'date', ''),
      description: get(node, 'excerpt', ''),

      author: {
        name: node.author.node.name,
        avatar: {
          childImageSharp: {
            gatsbyImageData: get(node, 'author.node.avatar.url'),
          },
        },
      },
    },

    timeToRead: 1, //node.fields.readingTime,
    html: get(node, 'content'),

    fields: {
      slug: `/${node.slug}`,
    },

    coverImage: node.featuredImage.node,
  },
});

export default mapWordpressBlogPostToMarkdownRemarkBlogPost;
