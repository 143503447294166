import React, { useEffect, useState } from 'react';
import EmailReportCTA from './EmailReportCTA';

const TeacherInput = ({
  showcta = false,
  setTeacherResults,
  showSummary = true,
  sampleReportUrl = '',
}) => {
  const [teacherInput, setTeacherInput] = useState({
    speaking: {
      primaryTeacher: 50,
      student: 50,
      otherPersonnel: 50,
    },
    listening: {
      primaryTeacher: 50,
      student: 50,
      otherPersonnel: 50,
    },
  });

  const handleTeacherInputChange = (event, type, category) => {
    setTeacherInput({
      ...teacherInput,
      [type]: {
        ...teacherInput[type],
        [category]: parseInt(event.target.value),
      },
    });
  };

  const renderTeacherLabel = (value, type) => {
    const speaks =
      teacherInput[type][value] === 0
        ? 'Speaks only in Home Language at School'
        : teacherInput[type][value] === 100
        ? 'Speaks only in English at School'
        : 'Speaks English at School';
    const listens =
      teacherInput[type][value] === 0
        ? 'Listens only in Home Language at School'
        : teacherInput[type][value] === 100
        ? 'Listens only in English at School'
        : 'Listens to English at School';
    return type === 'speaking' ? speaks : listens;
  };

  const calculateTotalHours = (type) => {
    const hoursWithPrimaryTeacher = (15 * teacherInput[type].primaryTeacher) / 100;
    const hoursWithStudents = (15 * teacherInput[type].student) / 100;
    const hoursWithOtherPersonnel = (10 * teacherInput[type].otherPersonnel) / 100;

    const totalHoursInEnglish =
      hoursWithPrimaryTeacher + hoursWithStudents + hoursWithOtherPersonnel;
    const totalPercentageInEnglish = (totalHoursInEnglish / 40) * 100; // Total school hours is 40

    const totalHoursByArea = {
      withPrimaryTeacher: hoursWithPrimaryTeacher.toFixed(2),
      withStudents: hoursWithStudents.toFixed(2),
      withOtherPersonnel: hoursWithOtherPersonnel.toFixed(2),
      overall: totalHoursInEnglish.toFixed(2),
      percentageInEnglish: totalPercentageInEnglish.toFixed(0),
      percentageInHomeLanguage: (100 - totalPercentageInEnglish).toFixed(0),
    };

    return totalHoursByArea;
  };

  const totalSpeakingHours = calculateTotalHours('speaking');
  const totalListeningHours = calculateTotalHours('listening');

  // Calculating averages
  const averageInputOutputEnglish = (
    (parseFloat(totalSpeakingHours.percentageInEnglish) +
      parseFloat(totalListeningHours.percentageInEnglish)) /
    2
  ).toFixed(0);
  const averageInputOutputHomeLanguage = (100 - parseFloat(averageInputOutputEnglish)).toFixed(0);

  const results = {
    input: teacherInput,
    totalSpeakingHours: totalSpeakingHours,
    totalListeningHours: totalListeningHours,
    averageInputOutputEnglish: averageInputOutputEnglish,
    averageInputOutputHomeLanguage: averageInputOutputHomeLanguage,
  };

  useEffect(() => {
    if (!showcta) {
      setTeacherResults(results);
    }
  }, [teacherInput]);

  return (
    <div>
      <h2 className="text-xl font-semibold mb-2">Teacher Input</h2>
      <div className="md:grid md:grid-cols-2 md:gap-x-10 ">
        <div>
          <h3 className="text-lg font-semibold mb-2">Speaking at School</h3>
          <div>
            <label className="block mb-2">With Primary Teacher(s)</label>
            <input
              type="range"
              min="0"
              max="100"
              value={teacherInput.speaking.primaryTeacher}
              onChange={(e) => handleTeacherInputChange(e, 'speaking', 'primaryTeacher')}
              className="w-full"
            />
            <p className="text-center">
              {teacherInput.speaking.primaryTeacher}% -{' '}
              {renderTeacherLabel('primaryTeacher', 'speaking')}
            </p>
          </div>
          <div>
            <label className="block mb-2">With Students</label>
            <input
              type="range"
              min="0"
              max="100"
              value={teacherInput.speaking.student}
              onChange={(e) => handleTeacherInputChange(e, 'speaking', 'student')}
              className="w-full"
            />
            <p className="text-center">
              {teacherInput.speaking.student}% - {renderTeacherLabel('student', 'speaking')}
            </p>
          </div>
          <div>
            <label className="block mb-2">With Other School Personnel</label>
            <input
              type="range"
              min="0"
              max="100"
              value={teacherInput.speaking.otherPersonnel}
              onChange={(e) => handleTeacherInputChange(e, 'speaking', 'otherPersonnel')}
              className="w-full"
            />
            <p className="text-center">
              {teacherInput.speaking.otherPersonnel}% -{' '}
              {renderTeacherLabel('otherPersonnel', 'speaking')}
            </p>
          </div>

          <table className="w-full mb-4 border border-collapse border-gray-800 hidden">
            <thead>
              <tr className="bg-gray-200">
                <th className="border border-gray-800 px-4 py-2">Area</th>
                <th className="border border-gray-800 px-4 py-2">Total Hours in English</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border border-gray-800 px-4 py-2">With Primary Teacher(s)</td>
                <td className="border border-gray-800 px-4 py-2">
                  {totalSpeakingHours.withPrimaryTeacher} hours
                </td>
              </tr>
              <tr>
                <td className="border border-gray-800 px-4 py-2">With Students</td>
                <td className="border border-gray-800 px-4 py-2">
                  {totalSpeakingHours.withStudents} hours
                </td>
              </tr>
              <tr>
                <td className="border border-gray-800 px-4 py-2">With Other School Personnel</td>
                <td className="border border-gray-800 px-4 py-2">
                  {totalSpeakingHours.withOtherPersonnel} hours
                </td>
              </tr>
              <tr>
                <td className="border border-gray-800 px-4 py-2">Total Overall Hours in English</td>
                <td className="border border-gray-800 px-4 py-2">
                  {totalSpeakingHours.overall} hours
                </td>
              </tr>
              <tr>
                <td className="border border-gray-800 px-4 py-2">
                  Percentage of Time Speaking in English at School
                </td>
                <td className="border border-gray-800 px-4 py-2">
                  {totalSpeakingHours.percentageInEnglish}%
                </td>
              </tr>
              <tr>
                <td className="border border-gray-800 px-4 py-2">
                  Percentage of Time Speaking in Home Language at School
                </td>
                <td className="border border-gray-800 px-4 py-2">
                  {totalSpeakingHours.percentageInHomeLanguage}%
                </td>
              </tr>
              <tr>
                <td className="border border-gray-800 px-4 py-2" colSpan={2}>
                  Assumptions: 168 hours in a week, 8 hours per day sleeping, 40 hours at school,
                  and 72 hours outside of school. We assume students spend 3 hours per day with
                  students and with teachers for 5 days a week, bringing a total of 15 hours per
                  week. We assume students spend 2 hours a day with other school personnel for 5
                  days a week, bringing the total to 10 hours per week with other school personnel.
                  In total, the weekly hours combined to be 40 hours.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <h3 className="text-lg font-semibold mb-2">Listening at School</h3>
          <div>
            <label className="block mb-2">With Primary Teacher(s)</label>
            <input
              type="range"
              min="0"
              max="100"
              value={teacherInput.listening.primaryTeacher}
              onChange={(e) => handleTeacherInputChange(e, 'listening', 'primaryTeacher')}
              className="w-full"
            />
            <p className="text-center">
              {teacherInput.listening.primaryTeacher}% -{' '}
              {renderTeacherLabel('primaryTeacher', 'listening')}
            </p>
          </div>
          <div>
            <label className="block mb-2">With Students</label>
            <input
              type="range"
              min="0"
              max="100"
              value={teacherInput.listening.student}
              onChange={(e) => handleTeacherInputChange(e, 'listening', 'student')}
              className="w-full"
            />
            <p className="text-center">
              {teacherInput.listening.student}% - {renderTeacherLabel('student', 'listening')}
            </p>
          </div>
          <div>
            <label className="block mb-2">With Other School Personnel</label>
            <input
              type="range"
              min="0"
              max="100"
              value={teacherInput.listening.otherPersonnel}
              onChange={(e) => handleTeacherInputChange(e, 'listening', 'otherPersonnel')}
              className="w-full"
            />
            <p className="text-center">
              {teacherInput.listening.otherPersonnel}% -{' '}
              {renderTeacherLabel('otherPersonnel', 'listening')}
            </p>
          </div>

          <table className="w-full mb-4 border border-collapse border-gray-800 hidden">
            <thead>
              <tr className="bg-gray-200">
                <th className="border border-gray-800 px-4 py-2">Area</th>
                <th className="border border-gray-800 px-4 py-2">Total Hours in English</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border border-gray-800 px-4 py-2">With Primary Teacher(s)</td>
                <td className="border border-gray-800 px-4 py-2">
                  {totalListeningHours.withPrimaryTeacher} hours
                </td>
              </tr>
              <tr>
                <td className="border border-gray-800 px-4 py-2">With Students</td>
                <td className="border border-gray-800 px-4 py-2">
                  {totalListeningHours.withStudents} hours
                </td>
              </tr>
              <tr>
                <td className="border border-gray-800 px-4 py-2">With Other School Personnel</td>
                <td className="border border-gray-800 px-4 py-2">
                  {totalListeningHours.withOtherPersonnel} hours
                </td>
              </tr>
              <tr>
                <td className="border border-gray-800 px-4 py-2">Total Overall Hours in English</td>
                <td className="border border-gray-800 px-4 py-2">
                  {totalListeningHours.overall} hours
                </td>
              </tr>
              <tr>
                <td className="border border-gray-800 px-4 py-2">
                  Percentage of Time Listening in English at School
                </td>
                <td className="border border-gray-800 px-4 py-2">
                  {totalListeningHours.percentageInEnglish}%
                </td>
              </tr>
              <tr>
                <td className="border border-gray-800 px-4 py-2">
                  Percentage of Time Listening in Home Language at School
                </td>
                <td className="border border-gray-800 px-4 py-2">
                  {totalListeningHours.percentageInHomeLanguage}%
                </td>
              </tr>
              <tr>
                <td className="border border-gray-800 px-4 py-2" colSpan={2}>
                  Assumptions: 168 hours in a week, 8 hours per day sleeping, 40 hours at school,
                  and 72 hours outside of school. We assume students spend 3 hours per day with
                  students and with teachers for 5 days a week, bringing a total of 15 hours per
                  week. We assume students spend 2 hours a day with other school personnel for 5
                  days a week, bringing the total to 10 hours per week with other school personnel.
                  In total, the weekly hours combined to be 40 hours.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/* Summary table */}
      {showSummary && (
        <div className="mt-10">
          <h2 className="text-xl font-semibold mb-2">Summary</h2>
          <table className="w-full mb-4 border border-collapse border-gray-800">
            <caption className="caption-top ">
              The teacher survey indicated that, during the child's time at school, which represents
              approximately one-third of their waking hours, their overall input/output in English
              is {averageInputOutputEnglish}% and their overall input/output in (home language) is{' '}
              {averageInputOutputHomeLanguage}%.
            </caption>

            <thead>
              <tr className="bg-gray-200">
                <th className="border border-gray-800 px-4 py-2">Category</th>
                <th className="border border-gray-800 px-4 py-2">Average Percentage</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border border-gray-800 px-4 py-2">
                  Overall Input/Output in English at School
                </td>
                <td className="border border-gray-800 px-4 py-2">{averageInputOutputEnglish}%</td>
              </tr>
              <tr>
                <td className="border border-gray-800 px-4 py-2">
                  Overall Input/Output in Home Language at School
                </td>
                <td className="border border-gray-800 px-4 py-2">
                  {averageInputOutputHomeLanguage}%
                </td>
              </tr>
              <tr>
                <td className="border border-gray-800 px-4 py-2" colSpan={2}>
                  The teacher survey indicated that, during the child's time at school, which
                  represents approximately one-third of their waking hours, their overall
                  input/output in English is {averageInputOutputEnglish}% and their overall
                  input/output in (home language) is {averageInputOutputHomeLanguage}%.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}

      {/* Capture Email CTA */}
      {showcta && (
        <EmailReportCTA
          results={results}
          selectedOption={'teacherInput'}
          sampleUrl={sampleReportUrl}
        />
      )}
    </div>
  );
};

export default TeacherInput;
