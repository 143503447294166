import React from 'react';
import { FaLanguage, FaCalculator } from 'react-icons/fa';
import { IconContext } from 'react-icons';

const CalculatorIcon = ({ showIconText, ...rest }) => (
  <IconContext.Provider value={{ size: '70' }}>
    <div
      {...rest}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '140px',
        height: '140px',
      }}
    >
      <FaCalculator />
      {showIconText && (
        <div
          style={{ marginTop: '10px', fontSize: '12px', fontWeight: 'bold', textAlign: 'center' }}
        >
          Calculator
        </div>
      )}
    </div>
  </IconContext.Provider>
);

export default CalculatorIcon;
