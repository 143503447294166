import React from 'react';
import { CheckIcon } from '@heroicons/react/solid';

const TierBullet = ({ feature, hasIcon = true }: any) => (
  <li className="flex space-x-3">
    {hasIcon && (
      <span className="pt-1">
        <CheckIcon className="flex-shrink-0 h-5 w-5 text-primary-600" aria-hidden="true" />
      </span>
    )}
    <span className="prose prose-primary max-w-none" title={feature.sub && feature.sub}>
      {feature.disclaimer && (
        <small className=" block prose prose-primary max-w-none text-xs ">
          {feature.disclaimer}
        </small>
      )}
      {feature.title && feature.title}
      {feature.subtitle && (
        <small className=" block prose prose-primary max-w-none text-xs">{feature.subtitle}</small>
      )}
    </span>
  </li>
);

const TierBulletsSection = ({
  heading,
  bullets,
  hasIcon = true,
  disclaimer = false,
  checkout,
}: any) => (
  <div className={disclaimer ? ' py-2 px-6 ' : `pt-6 pb-8 ${checkout ? '' : 'px-6'}`}>
    {heading && heading !== '' && (
      <h3 className="text-xs font-medium text-gray-900 tracking-wide uppercase">{heading}</h3>
    )}
    <ul className={disclaimer ? '' : 'mt-6 space-y-3'}>
      {bullets.map((feature) => (
        <TierBullet feature={feature} key={`${heading}-${feature.title}`} hasIcon={hasIcon} />
      ))}
    </ul>
  </div>
);

export default TierBulletsSection;
