import classNames from 'classnames';
import React from 'react';

interface TitleProps {
  text?: string;
  children?: any;
  id?: any;
  el?: string;
  className?: any;
}

const Title = ({ text, children, id, el = 'h2', className }: TitleProps) => {
  const inner = text ? text : children;
  return React.createElement(el, { className: 'mb-0 text-3xl font-bold', id }, inner);
};

export default Title;
