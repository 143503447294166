import React from 'react';
import EvaluboxIcon from './EvaluboxIcon';

export default function EvaluboxBrandLogo(props) {
  return (
    <div className="flex" {...props} style={{ color: '#5469D4' }}>
      <div className="w-full relative inline-flex align-middle justify-center items-center text-3xl font-bold tracking-tighter transition duration-500 ease-in-out transform tracking-relaxed lg:pr-8">
        <span>
          <EvaluboxIcon className="" />
        </span>
        <span>Evalubox</span>
      </div>
    </div>
  );
}
