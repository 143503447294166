import React from 'react';
import { Title, TextLink as Link } from '../../components';
import { FormSubmissionModal } from '../../components/FormSubmissionModal';

import CallToAction from './NetlifyFormCallToAction';
import { FORM_NAMES } from '../../contactFormConstants';

export const SubscribeToNewsletterSuccessModal = (props) => (
  <FormSubmissionModal
    titleText="You're subscribed!"
    bodyText={
      <>
        <p>We publish most Mondays so you&apos;ll receive your first edition soon.</p>
        <p>In the meantime, we&apos;d love to show you our flexible Evalubox platform...</p>
      </>
    }
    followOn="GET_TRIAL"
    {...props}
  />
);

export const SubscribeToNewsletterCTA = (props) => (
  <div className="text-center">
    <div className="pb-3">
      <Title text="Become a Evalubox expert" />
    </div>

    <p className="prose prose-primary mb-3 max-w-62 mx-auto">
      To get the latest news, deep dives into Evalubox features, and a roundup of recent updates,
      sign up for Evalubox&apos;s Weekly.{' '}
      <Link color="primary" to="/blog/">
        See recent editions.
      </Link>
    </p>

    <CallToAction
      buttonText="Join 1,500+ Evalubox enthusiasts"
      netlifyFormName={FORM_NAMES.subscribeToNewsletter}
      {...props}
    />
  </div>
);
