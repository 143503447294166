import React from 'react';
import { graphql } from 'gatsby';

import { SEO, Page } from '../components';
import { PostSummary, ListHeader, HeadRssLink } from '../components/article';
import mapWordpressBlogPostToMarkdownRemarkBlogPost from '../mapWordpressBlogPostToMarkdownRemarkBlogPost';

const BlogIndex = ({ data }) => {
  const siteTitle = data.site.siteMetadata.title;

  const posts = data.allWpPost.edges.map(mapWordpressBlogPostToMarkdownRemarkBlogPost);

  return (
    <>
      <HeadRssLink />
      <Page>
        <ListHeader title="Blog" />

        <div className="mt-12 grid gap-16 pt-12 lg:grid-cols-3 lg:gap-x-5 lg:gap-y-12">
          {posts.map(({ node }) => (
            <PostSummary key={node.fields.slug} post={node} />
          ))}
          {/* {data.allWpPost.nodes.map(node => (
                <PostSummary key={node.slug} post={node} />
                // <div>
                //     <p>{node.title}</p>
                //     <div dangerouslySetInnerHTML={{ __html: node.excerpt }} />
                // </div>
            ))} */}
        </div>
      </Page>
    </>
  );
};

export default BlogIndex;

export const Head = () => (
  <>
    <SEO
      title={`Essays and Resources for Speech Therapy Report Writing`}
      description={`Templates, tips, and tricks for speech pathology experts to be able to write accurate reports quickly.`}
    />
  </>
);

//highlight-start
export const pageQuery = graphql`
  {
    allWpPost(sort: { date: ASC }) {
      edges {
        node {
          slug
          excerpt
          date
          author {
            node {
              name
              avatar {
                url
              }
            }
          }
          content
          tags {
            nodes {
              name
            }
          }
          title
          featuredImage {
            node {
              gatsbyImage(height: 500, width: 500)
              title
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;
//highlight-end

// export const pageQuery = graphql`
//   query BlogIndex {
//     allContentfulBlogPost(
//       sort: {fields: date, order: DESC}
//       filter: {tags: {ne: "newsletter"}}
//     ) {
//       edges {
//         node {
//           description {
//             childMarkdownRemark {
//               rawMarkdownBody
//             }
//           }
//           date
//           author {
//             name
//             avatar {
//               gatsbyImageData(layout: FIXED, width: 40)
//             }
//           }
//           slug
//           tags
//           title
//           lastValidated
//           body {
//             childMarkdownRemark {
//               timeToRead
//             }
//           }

//           coverImage {
//             gatsbyImageData(height: 192)
//             title
//           }
//         }
//       }
//     }

//     site {
//       siteMetadata {
//         title
//       }
//     }
//   }
// `;
