import React from 'react';

const TierContainer = ({ children, highlight = false }: any) => (
  <div
    className={`${
      highlight ? 'border-primary-600 border-4' : 'border-gray-200'
    } border rounded-lg shadow-sm divide-y divide-gray-200`}
  >
    {children}
  </div>
);

export default TierContainer;
