import React from 'react';
import { Headline, Lead } from '..';

const TITLE = 'Unlocking potential for people with disabilities.';
const LEAD =
  'Our mission is to empower the special education ecosystem with unified technology that enables service providers, parents, educators, and administrators to deliver the best education to students with disabilities, allowing them to realize their potential, in their way.';

const Mission = ({ title = TITLE, lead = LEAD }: any) => (
  <div className="bg-white">
    <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-24">
      <div className="max-w-2xl lg:mx-auto lg:text-center">
        <div className="mt-1">
          <Headline>{title}</Headline>
        </div>

        <div className="mt-5">
          <Lead>{lead}</Lead>
        </div>
      </div>
    </div>
  </div>
);

export default Mission;
