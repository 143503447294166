import React from 'react';
import { Link, Title } from '../';

import Logo from './Logo';
import AgeCalculatorIcon from './AgeCalculatorIcon';
import LanguageExposureIcon from './LanguageUseIcon';
import CalculatorIcon from './CalculatorIcon';

const ListItemHeader = ({ slug, logoImage, humanName, heading }: any) => {
  const sharpImage =
    logoImage === 'age-calculator' ? (
      <AgeCalculatorIcon className="mx-auto" />
    ) : logoImage === 'language-use' ? (
      <LanguageExposureIcon className="mx-auto" />
    ) : (
      <CalculatorIcon className="mx-auto" />
    );

  return (
    <Link to={slug} className="underline-none capitalize">
      {/* <Logo sharpImage={sharpImage} alt={`${humanName} logo`} /> */}
      {sharpImage}
      <div className="pb-4">
        <Title>{humanName}</Title>
        {/* <small>{heading}</small> */}
      </div>
    </Link>
  );
};

export default ListItemHeader;
